// duck.js
import { createActions, createReducer } from 'reduxsauce';

// Action Types and Creators
export const { Types, Creators } = createActions({
  getBusinessFollowers: ['params'],
  getBusinessFollowersRequest: [],
  getBusinessFollowersSuccess: ['data'],
  getBusinessFollowersFailure: ['error'],
}, { prefix: 'cb/businesses/follow/' });

// Initial State
const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: [],
};

// Reducers
const request = state => ({
  ...state,
  fetching: true,
  error: null,
});

const success = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  data: action.data,
});

const failure = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});

export const HANDLERS = {
  [Types.GET_BUSINESS_FOLLOWERS_REQUEST]: request,
  [Types.GET_BUSINESS_FOLLOWERS_SUCCESS]: success,
  [Types.GET_BUSINESS_FOLLOWERS_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
export { Types as types, Creators as actions };
