import { OK } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getPropFromNormalized } from '../../utils/redux';
import { extractParamsFromUrl } from '../../utils/saga';

import * as api from './api';
import * as duck from './duck';
import { actions, types } from './duck';

export function* getBusinessCategories() {
  try {
    yield put(actions.getBusinessCategoriesRequest());

    const response = yield api.getBusinessCategories();

    if (response.status === OK) {
      yield put(actions.getBusinessCategoriesSuccess(response.data));
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    yield put(actions.getBusinessCategoriesFailure(error.message));
  }
}

export function* getBusinessCategoriesNextPage() {
  try {
    const nextPageUrl = yield select(state =>
      getPropFromNormalized(
        state.locations,
        duck.businessCategoriesEntity.key,
        'next',
      ),
    );

    if (!nextPageUrl) {
      return;
    }

    const params = extractParamsFromUrl(nextPageUrl);

    yield put(actions.getBusinessCategoriesNextPageRequest());

    const response = yield api.getBusinessCategories(params);

    if (response.status === OK) {
      yield put(actions.getBusinessCategoriesNextPage(response.data));
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    yield put(actions.getBusinessCategoriesNextPageFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_BUSINESS_CATEGORIES, getBusinessCategories);
  yield takeLatest(
    types.GET_BUSINESS_CATEGORIES_NEXT_PAGE,
    getBusinessCategoriesNextPage,
  );
}
