import './Header.module.scss';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { AccountMenu, AccountMenuItem, Logo } from 'components';
import { actions } from 'coupon-common/src/modules/auth';

import messages from './messages';
import { ReactComponent as SignOutIcon } from './SignOutIcon.svg';

export const HEADING_PLACEHOLDER_STRING = '...';
export const POINTS_PLACEHOLDER_STRING = '∞';

export const Header = ({
  mbAuto,
  authorized,
  businessInfo,
  pointsBalance,
  dispatch,
}) => {
  const logout = () => {
    dispatch(actions.logout());
  };

  const isInitBIFetch = businessInfo.fetching && !businessInfo.fetched;
  const isInitPBFetch = pointsBalance.fetching && !pointsBalance.fetched;

  const businessNameString = isInitBIFetch
    ? HEADING_PLACEHOLDER_STRING
    : businessInfo.name;
  const contactNameString = isInitBIFetch
    ? HEADING_PLACEHOLDER_STRING
    : businessInfo.contactName;
  const pointsToDisplay = isInitPBFetch
    ? POINTS_PLACEHOLDER_STRING
    : pointsBalance.points;

  return (
    <nav className={cx('navbar navbar-dark', { 'mb-auto': mbAuto })}>
      <div className="container">
        <div className="row">
          <div className="logo-container col-xl-2 col-lg-3">
            <Link to="/" className="navbar-brand">
              <Logo forHeader />
            </Link>
          </div>
          {authorized && (
            <div className="navbar-content col-xl-10 col-lg-9 d-flex justify-content-between align-items-center">
              <span className="heading">{businessNameString}</span>
              <div className="d-flex align-items-center">
                <div className="points-block">
                  <strong>{pointsToDisplay}</strong>
                  <span>
                    <FormattedMessage
                      {...messages.pointsBlockPointsText}
                      values={{ points: pointsToDisplay }}
                    />
                  </span>
                </div>
                <AccountMenu
                  logo={businessInfo.logo}
                  userName={contactNameString}
                >
                  <AccountMenuItem onClick={logout}>
                    <SignOutIcon />
                    <FormattedMessage
                      {...messages.accountMenuSignOutItemText}
                    />
                  </AccountMenuItem>
                </AccountMenu>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  mbAuto: PropTypes.bool,
  authorized: PropTypes.bool,
  businessInfo: PropTypes.object,
  pointsBalance: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  authorized: state.auth.authorized,
  businessInfo: {
    fetching: state.businessInfo.fetching,
    fetched: state.businessInfo.fetched,
    name: state.businessInfo.data.name,
    contactName: state.businessInfo.data.contact_name,
    logo: state.businessInfo.data.logo,
  },
  pointsBalance: {
    fetching: state.pointsBalance.fetching,
    fetched: state.pointsBalance.fetched,
    points: state.pointsBalance.data.points,
  },
});

export default connect(mapStateToProps)(Header);
