import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import styles from './Feedback.module.scss';

const Feedback = ({
  show,
  className,
  fullWidth,
  content,
  valid,
  warn,
  invalid,
  textCenter,
}) => {
  const displayFeedback = show && content;
  return (
    displayFeedback && (
      <div
        styleName={cx('feedback', {
          'full-width': fullWidth,
          invalid,
          valid,
          warn,
        })}
        className={cx(className, { 'text-center': textCenter })}
      >
        {content}
      </div>
    )
  );
};

Feedback.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valid: PropTypes.bool,
  warn: PropTypes.bool,
  invalid: PropTypes.bool,
  textCenter: PropTypes.bool,
};

Feedback.defaultProps = {
  fullWidth: true,
};

export default CSSModule(Feedback, styles, { allowMultiple: true });
