import { defineMessages } from 'react-intl';

export const scope = 'cb.components.GroupProtected';

export default defineMessages({
  loadingAccessRightsPageHeading: {
    id: `${scope}.loadingAccessRightsPageHeading`,
    defaultMessage: 'Obtaining page access rights',
  },
  needToUpgradePlanText: {
    id: `${scope}.needToUpgradePlanText`,
    defaultMessage:
      'You need to upgrade the plan to have access to the dashboard. {learnMoreLink}',
  },
});
