import React from 'react';

import { FormattedMessage } from 'react-intl';

import { DocumentWrapper } from 'components';
import { formatterContextValues } from 'utils/documentFormatting';

import messages from './messages';

const PrivacyPolicy = () => {
  return (
    <DocumentWrapper>
      <FormattedMessage
        {...messages.main}
        values={{
          ...formatterContextValues,
        }}
      />
    </DocumentWrapper>
  );
};

export default PrivacyPolicy;
