import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponFieldGroup';

export default defineMessages({
  createCouponTitle: {
    id: `${scope}.createCouponTitle`,
    defaultMessage: 'Create coupon',
  },
  titleFieldLabel: {
    id: `${scope}.titleFieldLabel`,
    defaultMessage: 'Coupon title',
  },
  descriptionFieldLabel: {
    id: `${scope}.descriptionFieldLabel`,
    defaultMessage: 'Coupon description',
  },
  paymentByPointsFieldLabel: {
    id: `${scope}.paymentByPointsFieldLabel`,
    defaultMessage: 'Allow payment by points?',
  },
  autoAcceptFieldLabel: {
    id: `${scope}.autoAcceptFieldLabel`,
    defaultMessage: 'Auto Accept coupon',
  },
  expiryTypeAmountLimitValueLabel: {
    id: `${scope}.expiryTypeAmountLimitValueLabel`,
    defaultMessage: 'Amount limit',
  },
  expiryTypeDateValueLabel: {
    id: `${scope}.expiryTypeDateValueLabel`,
    defaultMessage: 'Date',
  },
  amountOffTypeFieldLabel: {
    id: `${scope}.amountOffTypeFieldLabel`,
    defaultMessage: 'Amount off',
  },
  pointsBackTypeFieldLabel: {
    id: `${scope}.pointsBackTypeFieldLabel`,
    defaultMessage: 'Points back',
  },
  percentageOffFieldLabel: {
    id: `${scope}.percentageOffFieldLabel`,
    defaultMessage: 'Specify percentage off',
  },
  percentageOffTypeFieldLabel: {
    id: `${scope}.percentageOffTypeFieldLabel`,
    defaultMessage: 'Percentage off',
  },
  expirationQuantityFieldLabel: {
    id: `${scope}.expirationQuantityFieldLabel`,
    defaultMessage: 'Specify quantity of coupons',
  },
  expirationDateFieldLabel: {
    id: `${scope}.expirationDateFieldLabel`,
    defaultMessage: 'Specify expiration date',
  },
  amountOffFieldLabel: {
    id: `${scope}.amountOffFieldLabel`,
    defaultMessage: 'Specify amount off',
  },
  pointsBackFieldLabel: {
    id: `${scope}.pointsBackFieldLabel`,
    defaultMessage: 'Specify points back',
  },
  priceFieldLabel: {
    id: `${scope}.priceFieldLabel`,
    defaultMessage: 'Price',
  },
  reservedHoursFieldLabel: {
    id: `${scope}.reservedHoursFieldLabel`,
    defaultMessage: 'Reserved Hours',
  },
  showPriceFieldLabel: {
    id: `${scope}.showPriceFieldLabel`,
    defaultMessage: 'Show price on coupon',
  },
  pstFieldLabel: {
    id: `${scope}.pstFieldLabel`,
    defaultMessage: 'pst',
  },
  gstFieldLabel: {
    id: `${scope}.gstFieldLabel`,
    defaultMessage: 'gst',
  },
  hstFieldLabel: {
    id: `${scope}.hstFieldLabel`,
    defaultMessage: 'hst',
  },
  qstFieldLabel: {
    id: `${scope}.qstFieldLabel`,
    defaultMessage: 'qst',
  },
  rstFieldLabel: {
    id: `${scope}.rstFieldLabel`,
    defaultMessage: 'rst',
  },
  couponTypeFieldsGroupLabel: {
    id: `${scope}.couponTypeFieldsGroupLabel`,
    defaultMessage: 'Coupon type',
  },
  couponTitleAndDescriptionFieldsGroupLabel: {
    id: `${scope}.couponTitleAndDescriptionFieldsGroupLabel`,
    defaultMessage: 'Coupon title and description',
  },
  expiryTypeFieldsGroupLabel: {
    id: `${scope}.expiryTypeFieldsGroupLabel`,
    defaultMessage: 'Expiry type',
  },
  categoryFieldsGroupLabel: {
    id: `${scope}.categoryFieldsGroupLabel`,
    defaultMessage: 'Category',
  },
  reservedHoursFieldsGroupLabel: {
    id: `${scope}.reservedHoursFieldsGroupLabel`,
    defaultMessage: 'Reserved Hours',
  },
  priceFieldsGroupLabel: {
    id: `${scope}.priceFieldsGroupLabel`,
    defaultMessage: 'Price',
  },
  taxRatesFieldsGroupDescription: {
    id: `${scope}.taxRatesFieldsGroupDescription`,
    defaultMessage: 'Enter tax rates valid for your area',
  },
  couponTandCFieldsGroupLabel: {
    id: `${scope}.couponTandCFieldsGroupLabel`,
    defaultMessage: 'Terms and Conditions',
  },
  couponTandCFieldLabel: {
    id: `${scope}.couponTandCFieldLabel`,
    defaultMessage: 'Terms and Conditions',
  },
  userLimitFieldsGroupLabel: {
    id: `${scope}.userLimitFieldsGroupLabel`,
    defaultMessage: 'User limit',
  },
  userLimitFieldLabel: {
    id: `${scope}.userLimitFieldLabel`,
    defaultMessage: 'User limit',
  },
  isExclusivePaid: {
    id: `${scope}.isExclusivePaid`,
    defaultMessage: 'Paid Members',
  },
  isExclusiveFollowers: {
    id: `${scope}.isExclusiveFollowers`,
    defaultMessage: 'Followers',
  },
  exclusiveTitle: {
    id: `${scope}.exclusiveTitle`,
    defaultMessage: 'Exclusive Coupon',
  },
  exclusiveDescriptionLabel: {
    id: `${scope}.exclusiveDescriptionLabel`,
    defaultMessage: 'Make this coupon exclusive to just your followers and/or paid members',
  },
});
