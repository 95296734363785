import { defineMessages } from 'react-intl';

export const scope = 'cb.components.CouponTemplate';

export default defineMessages({
  blockHeadingText: {
    id: `${scope}.blockHeadingText`,
    defaultMessage: 'Coupon template',
  },
});
