import { defineMessages } from 'react-intl';

export const scope = 'cb.components.CouponTemplate';

export default defineMessages({
  viewsText: {
    id: `${scope}.viewsText`,
    defaultMessage: 'views',
  },
  amountLimitText: {
    id: `${scope}.amountLimitText`,
    defaultMessage: 'Amount limit',
  },
  amountText: {
    id: `${scope}.amountText`,
    defaultMessage: 'Amount',
  },
  dateText: {
    id: `${scope}.dateText`,
    defaultMessage: 'Date',
  },
  businessLogoAlt: {
    id: `${scope}.businessLogoAlt`,
    defaultMessage: 'Business logo',
  },
  expiryTypeUntilText: {
    id: `${scope}.expiryTypeUntilText`,
    defaultMessage: 'Until',
  },
  paymentByPointsText: {
    id: `${scope}.paymentByPointsText`,
    defaultMessage: 'Payment by points',
  },
  amountOffText: {
    id: `${scope}.amountOffText`,
    defaultMessage: 'Deal: ${amount_off} off',
  },
  percentageOffText: {
    id: `${scope}.percentageOffText`,
    defaultMessage: 'Deal: {percentage_off}% off',
  },
  pointsBackText: {
    id: `${scope}.pointsBackText`,
    defaultMessage:
      'Deal: {points_back, plural, one {{points_back} Point back} other {{points_back} Points back}}',
  },
  publishedDateDefaultText: {
    id: `${scope}.publishedDateDefaultText`,
    defaultMessage: 'Published date',
  },
  couponTitleDefaultText: {
    id: `${scope}.couponTitleDefaultText`,
    defaultMessage: 'Coupon Title',
  },
  couponDescriptionDefaultText: {
    id: `${scope}.couponDescriptionDefaultText`,
    defaultMessage: 'Short description',
  },
  clockImgAltText: {
    id: `${scope}.clockImgAltText`,
    defaultMessage: 'Clock',
  },
  sponsoredText: {
    id: `${scope}.sponsoredText`,
    defaultMessage: 'Sponsored',
  },
  publishedDateLabel: {
    id: `${scope}.publishedDateLabel`,
    defaultMessage: 'Published on',
  },
  expirationDateLabel: {
    id: `${scope}.expirationDateLabel`,
    defaultMessage: 'Expired on',
  },
  draftCouponLabel: {
    id: `${scope}.draftCouponLabel`,
    defaultMessage: 'Draft',
  },
  couponExpiredText: {
    id: `${scope}.couponExpiredText`,
    defaultMessage: 'Expired',
  },
  couponStateAlt: {
    id: `${scope}.couponStateAlt`,
    defaultMessage: 'Coupon state',
  },
  blockedIconTooltip: {
    id: `${scope}.blockedIconTooltip`,
    defaultMessage: 'This coupon is blocked',
  },
  publishedIconTooltip: {
    id: `${scope}.publishedIconTooltip`,
    defaultMessage: 'This coupon is published',
  },
  draftIconTooltip: {
    id: `${scope}.draftIconTooltip`,
    defaultMessage: 'This coupon is draft',
  },
  expiredIconTooltip: {
    id: `${scope}.expiredIconTooltip`,
    defaultMessage: 'This coupon is expired',
  },
});
