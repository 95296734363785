import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { Feedback, FieldLabel } from 'components';

import styles from './FormFieldWrapper.module.scss';

const FormFieldWrapper = ({ children, invalid, label, error }) => {
  return (
    <div styleName="form-field-container" className="form-group">
      <FieldLabel label={label} invalid={invalid} />
      {React.Children.only(children)}
      <Feedback show={invalid} content={error} invalid />
    </div>
  );
};

FormFieldWrapper.propTypes = {
  children: PropTypes.object,
  label: PropTypes.string,
  invalid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CSSModule(FormFieldWrapper, styles);
