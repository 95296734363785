import { OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import * as api from './api';
import { actions, types } from './duck';

export function* getData({ range }) {
  try {
    yield put(actions.getDataRequest());

    const response = yield api.getDashboardData({ range });

    if (response.status === OK) {
      yield put(actions.getDataSuccess(response.data));
    } else {
      if (Array.isArray(response.data)) {
        yield put(actions.getDataFailure(response.data[0]));
      } else {
        yield put(actions.getDataFailure(response.data));
      }
    }
  } catch (error) {
    yield put(actions.getDataFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_DATA, getData);
}
