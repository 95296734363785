import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    id: `cb.components.DealerAgreement.heading`,
    defaultMessage: 'MY COUPON BOOK INC. BUSINESS DEALER AGREEMENT',
  },
  betweenLabel: {
    id: `cb.components.DealerAgreement.betweenLabel`,
    defaultMessage: 'BETWEEN:',
  },
  andLabel: {
    id: `cb.components.DealerAgreement.andLabel`,
    defaultMessage: 'AND:',
  },
  dealerText: {
    id: `cb.components.DealerAgreement.dealerText`,
    defaultMessage: '(“Dealer”)',
  },
  couponBookCompanyData: {
    id: `cb.components.DealerAgreement.couponBookCompanyName`,
    defaultMessage: `
<bold>MY COUPON BOOK INC.</bold>
<text>301-1665 Ellis Street</text>
<text>Kelowna, BC V1Y 2B3</text>
<spacedText>(“<bold>My Coupon Book</bold>”)</spacedText>
`,
  },
  main: {
    id: `cb.components.DealerAgreement.main`,
    defaultMessage: `
<paragraph>WHEREAS My Coupon Book owns certain widgets, emails, services and any other software applications. (collectively deemed to be the “<bold>Services</bold>”) associated with the promotion and sale of businesses, dealers and merchants offerings (such as goods, commodities, experiences, coupons, gift cards, vouchers, promotions, giveaways or offers) in the form of coupons which can be redeemed by consumers using the Services (“<bold>Registered Users</bold>”) and as further described in this Agreement;</paragraph>
<paragraph>AND WHEREAS the Dealer wishes to register as a member of the Services in accordance with the terms of this Agreement to promote it’s offerings;</paragraph>
<paragraph>In consideration of the covenants and agreements contained in this Agreement, the parties agree as follows:</paragraph>
<heading>
    <spaced>1.</spaced>
    <underlined>DEALER AGREEMENT AND TERMS OF SERVICE</underlined>
</heading>
<paragraph>
    <spaced>1.1</spaced>This Dealer Agreement and My Coupon Book’s Terms of Service for Business Dealer’s {businessTermsLink} (the “<bold>Terms</bold>”) constitute the entire agreement between the parties with respect to the matters described herein (collectively referred to as the “<bold>Agreement</bold>”), and the parties do not rely upon any representation or agreement whatsoever which is not incorporated in this Agreement. All terms not defined in this Dealer Agreement shall have the same meaning as defined in the Terms. Certain terms regarding the party’s relationship are or may in future be governed by the Terms, or My Coupon Book may make modifications or revisions to the Terms (deemed to be “<bold>Additional Terms</bold>”). The Dealer will be given an opportunity to review Additional Terms by receiving notice of such Additional Terms from My Coupon Book, which shall be deemed to have been given by My Coupon Book upon posting on or through the Services, or may be given by My Coupon Book as set out in Section 13.4. By using such aforesaid Services governed by Additional Terms, the Dealer agrees to and will be obligated to comply with all such Additional Terms as part of this Agreement.</paragraph>
<heading>
    <spaced>2.</spaced>
    <underlined>OFFERINGS</underlined>
</heading>
<paragraph>
    <spaced>2.1</spaced>The Dealer shall offer different goods, commodities, experiences, coupons, gift cards, vouchers, promotions, giveaways or offers (each individual good, commodity, experience, coupon, gift card, voucher, promotion, giveaway or offer is deemed to be an “<bold>Offering</bold>”) which shall be promoted through My Coupon Book’s Services in the form of a redeemable coupon (each coupon representing an Offering is deemed to be a “<bold>Coupon</bold>”). The Dealer authorizes My Coupon Book to offer, sell and distribute the Coupons through the Services.</paragraph>
<paragraph>
    <spaced>2.2</spaced> The number of Offerings that the Dealer shall be permitted to offer through the Services at any one time will be in accordance with the membership plan selected by the Dealer pursuant to section 3 (the “<bold>Membership Plan</bold>”).
</paragraph>
<paragraph>
    <spaced>2.3</spaced> Each Offering that is to be offered by the Dealer through the Services will be submitted by the Dealer electronically in the form attached as <bold>Schedule “A”</bold>. The Dealer has the ability to add or remove Offerings in the Dealer’s discretion, subject to the approval of My Coupon Book. The Dealer acknowledges that My Coupon Book reserves the continuing right, but shall not be obligated, to reject, revise, or discontinue publishing any Coupon and to require the Dealer to edit or modify the same for any reason, including, without limitation, to conform the Coupon or specified Offering to My Coupon Book’s specifications or applicable federal, provincial or local laws.</paragraph>
<paragraph>
    <spaced>2.4</spaced> For clarification, a Registered User that accesses the Services may obtain a Coupon by redeeming My Coupon Book points (“<bold>Coupon Points</bold>”) that the Registered User has earned through the Registered User’s use of the Services. Upon a Registered User selecting and redeeming Coupon Points for a Coupon in accordance with My Coupon Book’s Terms of Service for Registered Users {customerTermsLink}, My Coupon Book will electronically provide the Registered User with the Coupon which may be used by the Registered User for the Dealer to provide the Offering. The Dealer acknowledges and agrees that each Coupon (and the Offering provided by the Coupon) will be subject to no restrictions other than as stated in this Agreement and as described in the Coupon and that in providing the Offering that is the subject of the Coupon, it will not impose any extra or additional fees or charges that contradict the terms set forth on the face of the Coupon. Once a Coupon is redeemed by a Registered User, the Dealer shall be solely responsible for all service in connection with the Coupon and for supplying the Offering specified in the Coupon.</paragraph>
<heading>
    <spaced>3.</spaced>
    <underlined>MEMBERSHIP PLAN</underlined>
</heading>
<paragraph>
    <spaced>3.1</spaced>The Dealer hereby selects the following Membership Plan offered by My Coupon Book [Dealer to select one plan below]:</paragraph>
<paragraph>
    <bold>Standard Plan</bold> – The Standard Plan contains the following rights and obligations:</paragraph>
<list>
    <item>The Dealer shall pay My Coupon Book $10.00 per month in the form of a monthly membership fee;</item>
    <item>The Dealer shall be entitled to provide up to three (3) Offerings through the Services at any one time;</item>
    <item>The Dealer may redeem Coupon Points earned through the sale of the Coupons at a 4% exchange rate; and</item>
    <item>The Dealer shall be limited to a maximum of 100 redeemed Coupons per month.</item>
</list>
<paragraph>
    <bold>Deluxe Plan</bold> – The Deluxe Plan contains the following rights and obligations:</paragraph>
<list>
    <item>The Dealer shall pay My Coupon Book $25.00 per month in the form of a monthly membership fee;</item>
    <item>The Dealer shall be entitled to provide up to ten (10) Offerings through the Services at any one time;</item>
    <item>The Dealer may redeem Coupon Points earned through the sale of the Coupons at a 3% exchange rate;</item>
    <item>My Coupon Book will provide the Dealer with three (3) sponsored Coupon slots;</item>
    <item>The Dealer shall be limited to a maximum of three-hundred (300) redeemed Coupons per month; and</item>
    <item>My Coupon Book shall provide analytical information to the Dealer regarding Offerings and Coupons as used through the Services.</item>
</list>
<paragraph>
    <bold>Premium Plan</bold> – The Premium Plan contains the following rights and obligations:</paragraph>
<list>
    <item>The Dealer shall pay My Coupon Book $100.00 per month in the form of a monthly membership fee;</item>
    <item>The Dealer shall be entitled to provide up to forty (40) Offerings through the Services at any one time;</item>
    <item>The Dealer may redeem Coupon Points earned through the sale of the Coupons at a 1.5% exchange rate;</item>
    <item>My Coupon Book will provide the Dealer with fifteen (15) sponsored Coupon slots;</item>
    <item>The Dealer shall be limited to a maximum of one thousand (1000) redeemed Coupons per month; and</item>
    <item>My Coupon Book shall provide analytical information to the Dealer regarding Offerings and Coupons as used through the Services.</item>
</list>
<paragraph>With reference to the above, a sponsored Coupon is one which receives priority promotion by My Coupon Book on the Services. The Dealer will be able to select which Coupons are sponsored at any one time in accordance with the Membership Plan selected above.</paragraph>
<heading>
    <spaced>4.</spaced>
    <underlined>PAYMENT</underlined>
</heading>
<paragraph>
    <spaced>4.1</spaced>In accordance with the Membership Plan selected by the Dealer under section 3, the Dealer shall pay My Coupon Book the monthly membership fee (the “<bold>Membership Fee</bold>”). The Membership Fee shall be payable by the Dealer each month on the monthly anniversary of the Effective Date in accordance with the payment provisions of the Terms.</paragraph>
<paragraph>
    <spaced>4.2</spaced>For each Coupon that is used by a Registered User, the Dealer shall receive the associated Coupon Points stated in the Coupon and  which are approved by My Coupon Book.</paragraph>
<paragraph>
    <spaced>4.3</spaced>The Dealer may use Coupon Points as follows:</paragraph>
<list>
    <item>For purchase of other Offerings promoted through the Services by other businesses; or</item>
    <item>Redeem Coupon Points for Canadian dollars in accordance with the exchange rate for the associated Membership Plan selected by the Dealer under section 3.</item>
</list>
<heading>
    <spaced>5.</spaced>
    <underlined>REFERRALS AND RETURNS</underlined>
</heading>
<paragraph>
    <spaced>5.1</spaced>The Dealer may refer customers of the Dealer to use the Services. For every customer that is referred by the Dealer to My Coupon Book, the Dealer shall earn six (6) Coupon Points. To make a referral, a referred customer of the Dealer must register for the Services and specify that they were referred by the Dealer.</paragraph>
<paragraph>
    <spaced>5.2</spaced>Any Offering which is purchased by a Registered User through the Services by way of a Coupon and then subsequently returned by the Registered User (in accordance with My Coupon Book’s return policy and any return policies of the Dealer specified in the Coupon) must be refunded by the Dealer to the Registered User in dollars or store credit. The Dealer shall not offer Coupon Points in exchange for a refunded Offering.</paragraph>
<heading>
    <spaced>6.</spaced>
    <underlined>INDEPENDENT DEALER</underlined>
</heading>
<paragraph>
    <spaced>6.1</spaced> The Dealer is an independent contractor. The Dealer has no authority to assume or create any obligation whatsoever, expressed or implied, in the name of My Coupon Book, nor to bind My Coupon Book in any manner whatsoever. The Dealer does not in any way or for any purpose become a partner, a joint venturer or a member of joint enterprise with My Coupon Book.  No provision of this Agreement is intended to create a relationship between the parties other than that of independent contractors.</paragraph>
<heading>
    <spaced>7.</spaced>
    <underlined>LIMITATION OF LIABILITY AND INDEMNITY</underlined>
</heading>
<paragraph>
    <spaced>7.1</spaced> The Dealer shall be responsible and liable for all Offerings, and for the Registered User’s use or reliance on any of the foregoing that is offered through a Coupon. In creating and providing an Offering, the Dealer shall not offer to sell any goods or services on terms or in a manner that is prohibited by any federal, provincial or local laws from being offered or advertised. The Dealer shall, at the Dealer’s sole cost and expense, defend, indemnify and hold My Coupon Book, its affiliated and related entities, and any of their respective officers, directors, agents, independent representatives and employees, harmless from and against any third party claims, lawsuits, actions, proceedings or investigations and against any fees, costs, sanctions, penalties, damages, losses or expenses (including but not limited to solicitor’s fees and costs) arising out of or relating to any of the following:</paragraph>
<list>
    <item>the Dealer’s or any third party’s fraud, willful misconduct, or gross negligence or breach or alleged breach of the Agreement;</item>
    <item>the goods and/or services provided by the Dealer through the Offering, including but not limited to, any claims for false advertising, injuries, illnesses, damages, or death;</item>
    <item>any provision of incomplete or inaccurate information applicable to such Coupon or Offering; or any infringement, misappropriation, or other violation, of any patent, trademark, copyright, publicity, privacy, trade secret, or other right of any third party by you, or the use thereof.</item>
</list>
<paragraph>
    <spaced>7.2</spaced>The Dealer acknowledges and agrees that My Coupon Book is not a party to any transaction that the Dealer and a Registered User may enter into as a result of a Registered User’s purchase of a Coupon. My Coupon Book is not responsible for the redemption, errors, omissions, availability or expiration of any Offering or Coupons. In no event shall My Coupon Book be liable, either directly or indirectly, for the legality of any Offerings, the ability of the Dealer to complete the sale or transaction in accordance with the Offerings, or direct, indirect, incidental, special, consequential or punitive damages arising out of any inability to access or use the Offerings.</paragraph>
<paragraph>
    <spaced>7.3</spaced>My Coupon Book shall not be liable for any loss or damage suffered by the Dealer resulting from the inability of My Coupon Book to sell, deliver or supply any of the Coupons and in no event shall My Coupon Book be liable for any consequential damages incurred by the Dealer.</paragraph>
<paragraph>
    <spaced>7.4</spaced>My Coupon Book will not provide to any Registered User or prospective Registered User any warranties or guarantees of any nature whatsoever which may involve the responsibility or liability of My Coupon Book.</paragraph>
<heading>
    <spaced>8.</spaced>
    <underlined>CUSTOMER DATA</underlined>
</heading>
<paragraph>
    <spaced>8.1</spaced>The Dealer acknowledges that My Coupon Book owns all data collected by, or on behalf of, My Coupon Book pursuant to this Agreement and through the Services, including all information and data of Registered Users who may or do purchase Coupons (“<bold>Customer Data</bold>”). Subject to applicable laws and in accordance with My Coupon Book’s policies and procedures (including but not limited to My Coupon Book’s Privacy Policy which can be found here: {privacyPolicyLink}) (collectively, “<bold>My Coupon Book’s Policies</bold>”), My Coupon Book shall not provide the Dealer with access to Customer Data, except:</paragraph>
<list>
    <item>to the extent such specific data is necessary for the Dealer to redeem and/or verify the validity of the Coupons, and,</item>
    <item>to the extent that any Registered User is made aware by statements contained in the disclosure of the Offering on the Services, that providing or sharing certain information is required to redeem the Coupon, if such sharing would not be implicit in the type of good or service being offered.</item>
</list>
<paragraph>Except to the extent required by federal, provincial and local laws or otherwise authorized in writing by My Coupon Book, the Dealer may not use Customer Data for any purpose other than to redeem the Coupons and service the Offerings. The Dealer shall, and shall ensure that any third-parties contracted by the Dealer or otherwise which the Dealer may work with, take commercially reasonable efforts to protect the security of Customer Data and comply with all federal, provincial and local laws (and My Coupon Book’s Policies). In the event the Dealer becomes aware of, or suspect, any unauthorized access to or use of Customer Data, the Dealer shall immediately notify My Coupon Book, and shall cooperate with My Coupon Book in the investigation of such breach and the mitigation of any damages. The Dealer shall bear all resulting costs and expenses incurred by My Coupon Book to comply with applicable federal, provincial or local laws. Upon termination or expiration of this Agreement, the Dealer shall, as directed by My Coupon Book, destroy or return to My Coupon Book all the Customer Data in the Dealer’s or affiliate of the Dealer’s possession.</paragraph>
<heading>
    <spaced>9.</spaced>
    <underlined>TRADEMARKS</underlined>
</heading>
<paragraph>
    <spaced>9.1</spaced>The Dealer grants to My Coupon Book a non-exclusive worldwide license and right to use, reproduce, license, display, distribute and transmit the Dealer’s name, logo and any trademarks (the "<bold>Dealer Marks</bold>") and any photographs, graphics, artwork, text and other content provided or specified by the Dealer (the "<bold>Content</bold>") in connection with the marketing, promotion, sale or distribution of the Coupons in any and all media or formats in which such Coupons are marketed, promoted, transmitted, sold, or distributed, including but not limited to, on the Services</paragraph>
<paragraph>
    <spaced>9.2</spaced>The Dealer agrees and acknowledges that My Coupon Book owns all right, title, and interest in the Services, My Coupon Book trademarks, and any software, technology or tools used by My Coupon Book to promote, market, sell, generate, or distribute the Coupons (collectively the "My Coupon Book Intellectual Property"). The Dealer shall not rent, lease, sublicense, distribute, transfer, copy, reproduce, download, display, or modify the My Coupon Book Intellectual Property or any portion thereof, or use such My Coupon Book Intellectual Property as a component of or a base for products or services prepared for commercial sale, sublicense, lease, access or distribution.</paragraph>
<heading>
    <spaced>10.</spaced>
    <underlined>REPRESENTATIONS AND WARRANTIES</underlined>
</heading>
<paragraph>
    <spaced>10.1</spaced>The Dealer Represents and warrants to My Coupon Book as follows:</paragraph>
<list>
    <item>the Dealer has the right, power and authority to enter into this Agreement;</item>
    <item>the Coupon, upon being activated and delivered by My Coupon Book shall be available immediately for redemption by any Registered User;</item>
    <item>the terms and conditions of the Coupon, including any discounts or goods and services offered thereunder, comply with all, and do not and will not violate any, local, provincial or federal law, statute, rule, regulation, or order, including but not limited to, any law and/or regulation governing the use, sale, and/or distribution of alcohol, governing vouchers, gift cards, coupons, and/or gift certificates;</item>
    <item>any Coupon, upon being activated and delivered by My Coupon Book through the Services shall be immediately available for redemption by a Registered User;</item>
    <item>the Dealer owns all right, title and interest in the Marks and has the right to grant the licenses in the Marks stated in this Agreement;</item>
    <item>the Coupon and any advertising or promotion of the Dealer’s products and services relating thereto will not constitute false, deceptive or unfair advertising or disparagement under any applicable local, provincial or federal law, statute, rule, regulation, or order; and</item>
    <item>the Marks do not and will not violate any copyright, trademark, or other intellectual property right or right of privacy or publicity of any third party or any local, provincial or federal law, statute, rule, regulation, or order</item>
</list>
<heading>
    <spaced>11.</spaced>
    <underlined>TERMINATION</underlined>
</heading>
<paragraph>
    <spaced>11.1</spaced> This Agreement shall continue in perpetuity unless terminated in accordance with the terms of this Agreement.</paragraph>
<paragraph>
    <spaced>11.2</spaced> My Coupon Book or the Dealer may terminate this Agreement at any time for any reason by giving the Dealer written notice of such termination.</paragraph>
<paragraph>
    <spaced>11.3</spaced> Any termination of this Agreement shall not relieve either party from any obligation or liability to the other party that has occurred pursuant to this Agreement prior to the time or termination. Upon termination, the Dealer may exchange any Coupon Points for Canadian dollars in accordance with the terms of this Agreement.</paragraph>
<heading>
    <spaced>12.</spaced>
    <underlined>FORCE MAJEURE</underlined>
</heading>
<paragraph>
    <spaced>12.1</spaced> In the event of an inability or failure by My Coupon Book to promote, offer, sell, redeem the Coupon by reason of any fire, explosion, war, riot, strike, walk‑out, labour controversy, flood, shortage of water, power, labour, transportation facilities or necessary materials or supplies, default or failure of carriers, breakdown in or the loss of production or anticipated production from plant or equipment, act of God or public enemy, any law, act or order of any court, board, government or other authority of competent jurisdiction, or any other direct cause (whether or not of the same character as the foregoing) beyond the reasonable control of My Coupon Book, then My Coupon Book will not be liable to the Dealer during the period and to the extent of such inability or failure.</paragraph>
<heading>
    <spaced>13.</spaced>
    <underlined>GENERAL TERMS</underlined>
</heading>
<paragraph>
    <spaced>13.1</spaced>
    <bold>Confidential Information.</bold> The Dealer agrees that the terms of this Agreement are confidential and should not be disclosed to any other party.</paragraph>
<paragraph>
    <spaced>13.2</spaced>
    <bold>Governing Law and Attornment.</bold>  This Agreement is governed by, and construed in accordance with, the laws of the Province of British Columbia and the laws of Canada applicable therein. Each of the parties agree to bring any action arising out of or related to this agreement in a court of British Columbia and the parties hereby submit to the exclusive jurisdiction of the courts of British Columbia and any courts competent to hear appeals therefrom.</paragraph>
<paragraph>
    <spaced>13.3</spaced>
    <bold>Time of the Essence.</bold>  Time is of the essence of this agreement and of each and every part hereof.</paragraph>
<paragraph>
    <spaced>13.4</spaced>
    <bold>Notice.</bold>  Any notice required or permitted under this agreement must be in writing and may be given by personal delivery, overnight courier, mail, facsimile transmission or other electronic transmission to the party at the address set out on the first page of this agreement.  Notices given by personal delivery will be deemed to have been received on the date of the delivery.  Notices given by overnight courier, facsimile transmission or other electronic transmission will be deemed to have been received on the day following the date of delivery.  Notices delivered by mail will be deemed to have been received on the third day following the date of mailing.</paragraph>
<paragraph>
    <spaced>13.5</spaced>
    <bold>Amendment.</bold>  No amendment of this Agreement is valid unless it is in writing and signed by each party.</paragraph>
<paragraph>
    <spaced>13.6</spaced>
    <bold>Assignment.</bold>  No amendment of this Agreement is valid unless it is in writing and signed by each party.</paragraph>
<paragraph>
    <spaced>13.7</spaced>
    <bold>No Waiver.</bold>  A party does not waive or release any of its rights under this Agreement, unless that waiver or release is in writing and signed by the party releasing or waiving its rights.  The failure of a party to assert a right under this agreement or to insist upon compliance with any term does not constitute a waiver of that right by that party or excuse a similar subsequent failure to perform by the other party.</paragraph>
<paragraph>
    <spaced>13.8</spaced>
    <bold>Number and Gender.</bold>  This Agreement is to be read with all changes in gender or number as required by context.</paragraph>
<paragraph>
    <spaced>13.9</spaced>
    <bold>Headings.</bold>  The headings in this Agreement do not affect its interpretation.</paragraph>
<paragraph>
    <spaced>13.10</spaced>
    <bold>Unenforceability of Provisions.</bold>  It is agreed that should any clause, condition or term, or any part thereof, contained in this agreement be unenforceable or prohibited by law or by any present or future provincial or federal legislation, then such clause, condition, term or part thereof, will be amended, and is hereby amended, so as to be in compliance with the said legislation or law but, if such clause, condition or term, or part thereof cannot be amended so as to be in compliance with any such legislation or law then such clause, condition, term or part thereof is severable from this agreement, and all the rest of the clauses, terms and conditions or parts thereof contained in this agreement will remain unimpaired.</paragraph>
<paragraph>
    <spaced>13.11</spaced>
    <bold>Enurement.</bold>  This Agreement is binding on and enures to the benefit of the parties hereto and their respective successors and permitted assigns.</paragraph>
<paragraph>
    <spaced>13.12</spaced>
    <bold>Independent Legal Advice.</bold>  Each party acknowledges having obtained independent legal advice from its own solicitor with respect to the terms of this agreement prior to its execution or has been given the opportunity to obtain independent legal advice from its own solicitor and has declined to do so.  Each party further acknowledges that it understands the terms and its rights and obligations under this Agreement.</paragraph>
<paragraph>
    <spaced>13.13</spaced>
    <bold>Counterparts/Electronic Transmission.</bold>  This Agreement may be executed in counterparts and delivered by electronic transmission.</paragraph>
`,
  },
});
