/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 */

import { de, enUS } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/de');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/de');
}

registerLocale('en', enUS);
registerLocale('de', de);

const enTranslationMessages = require('./translations/en.json');
const deTranslationMessages = require('./translations/de.json');

const enCommonTranslationMessages = require('coupon-common/src/translations/en.json');
const deCommonTranslationMessages = require('coupon-common/src/translations/de.json');

const enMessages = {
  ...enTranslationMessages,
  ...enCommonTranslationMessages,
};

const deMessages = {
  ...deTranslationMessages,
  ...deCommonTranslationMessages,
};

export const APP_LOCALES = ['en', 'de'];
/* Hardcoded as initialized in redux store -> language */
export const DEFAULT_LOCALE = 'en';

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enMessages),
  de: formatTranslationMessages('de', deMessages),
};
