import { defineMessages } from 'react-intl';

export const scope = 'cb.components.ForgotPasswordForm';

export default defineMessages({
  backToLoginLinkText: {
    id: `${scope}.backToLoginLinkText`,
    defaultMessage: 'Back to Login',
  },
  sendResetPasswordButtonText: {
    id: `${scope}.sendResetPasswordButtonText`,
    defaultMessage: 'Send',
  },
  emailFieldLabelText: {
    id: `${scope}.emailFieldLabelText`,
    defaultMessage: 'Email',
  },
});
