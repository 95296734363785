import { defineMessages } from 'react-intl';

export const scope = 'cb.components.ResetPasswordForm';

export default defineMessages({
  backToLoginLinkText: {
    id: `${scope}.backToLoginLinkText`,
    defaultMessage: 'Back to Login',
  },
  changePasswordButtonText: {
    id: `${scope}.changePasswordButtonText`,
    defaultMessage: 'Change password',
  },
  newPasswordFieldLabelText: {
    id: `${scope}.newPasswordFieldLabelText`,
    defaultMessage: 'New password',
  },
  repeatPasswordFieldLabel: {
    id: `${scope}.repeatPasswordFieldLabel`,
    defaultMessage: 'Repeat password',
  },
  passwordNotMatchMessage: {
    id: `${scope}.passwordNotMatchMessage`,
    defaultMessage: 'The password does not match!',
  },
});
