import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponPriceFieldGroup';

export default defineMessages({
  priceFieldLabel: {
    id: `${scope}.priceFieldLabel`,
    defaultMessage: 'Enter the total price without discount',
  },
  dealText: {
    id: `${scope}.dealText`,
    defaultMessage: 'DEAL',
  },
  plusTaxText: {
    id: `${scope}.plusTaxText`,
    defaultMessage: 'Plus tax',
  },
  adjustTaxIfNeededText: {
    id: `${scope}.adjustTaxIfNeededText`,
    defaultMessage: 'ADJUST THE TAX AMOUNT IF NEEDED',
  },
  gstFieldLabel: {
    id: `${scope}.gstFieldLabel`,
    defaultMessage: 'GST',
  },
  hstFieldLabel: {
    id: `${scope}.hstFieldLabel`,
    defaultMessage: 'HST',
  },
  pstFieldLabel: {
    id: `${scope}.pstFieldLabel`,
    defaultMessage: 'PST',
  },
  qstFieldLabel: {
    id: `${scope}.qstFieldLabel`,
    defaultMessage: 'QST',
  },
  rstFieldLabel: {
    id: `${scope}.rstFieldLabel`,
    defaultMessage: 'RST',
  },
  totalPriceText: {
    id: `${scope}.totalPriceText`,
    defaultMessage: 'Total',
  },
  pointsBackText: {
    id: `${scope}.pointsBackText`,
    defaultMessage: 'Points Back',
  },
  pointsBackInfoText: {
    id: `${scope}.pointsBackInfoText`,
    defaultMessage:
      '{points, plural, one {# point} other {# points}} will be deducted from your account and given to the customer',
  },
});
