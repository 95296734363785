import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Feedback, Input } from 'components';
import { actions as referralCodesActions } from 'coupon-common/src/modules/referralCodes';
import { actions as signupActions } from 'coupon-common/src/modules/signup';
import { dispatchAsAsync, required } from 'coupon-common/src/utils';

import messages from './messages';

const DELAY_BEFORE_AUTOSUBMIT_TIME_MS = 200;

const getReferralCodeSubmittingValidator = (dispatch, form) => async value => {
  const requiredFail = required(value);
  if (requiredFail) {
    return requiredFail;
  }

  const validationErrors = await dispatchAsAsync(
    referralCodesActions.validateBusinessCode(value),
    dispatch,
  );

  if (!validationErrors) {
    setTimeout(form.submit, DELAY_BEFORE_AUTOSUBMIT_TIME_MS);
  }

  return validationErrors;
};

const ReferralCodeForm = ({ onSubmitSuccess, dispatch }) => {
  useEffect(() => {
    dispatch(referralCodesActions.clearBusinessCode());
    dispatch(signupActions.reset());
  }, [dispatch]);

  const onSubmit = values => {
    dispatch(referralCodesActions.setBusinessCode(values.code));

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  return (
    <>
      <h4 className="mb-4 text-center">
        <FormattedMessage {...messages.formHeading} />
      </h4>
      <Form
        onSubmit={onSubmit}
        render={({ form, handleSubmit, submitting, validating }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="code"
              validate={getReferralCodeSubmittingValidator(dispatch, form)}
            >
              {({ input, meta }) => (
                <div className="form-group">
                  <Input
                    {...input}
                    className="text-center"
                    valid={meta.valid && !meta.validating}
                    invalid={meta.error && !meta.pristine}
                    loading={meta.validating}
                    error={meta.error}
                  />
                  <Feedback
                    show={meta.error && !meta.pristine}
                    content={meta.error}
                    invalid
                  />
                </div>
              )}
            </Field>
            <div className="mt-4 d-flex justify-content-end">
              <Button type="submit" disabled={submitting || validating}>
                <FormattedMessage {...messages.continueButtonText} />
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

ReferralCodeForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ReferralCodeForm);
