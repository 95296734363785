import { defineMessages } from 'react-intl';

export const scope = 'cb.components.FormSelect';

export default defineMessages({
  loadingText: {
    id: `${scope}.loadingText`,
    defaultMessage: 'Loading...',
  },
  loadMoreText: {
    id: `${scope}.loadMoreText`,
    defaultMessage: 'Load More',
  },
  noOptionsText: {
    id: `${scope}.noOptionsText`,
    defaultMessage: 'No options.',
  },
});
