import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import styles from './Spinner.module.scss';

const Spinner = ({ show, containerClassName, color, size, justify }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={cx(
        containerClassName,
        'd-flex',
        'align-items-center',
        `justify-content-${justify}`,
      )}
    >
      <div
        styleName={cx(`spinner-border-${size}`)}
        className={cx('spinner-border', `text-${color}`)}
        role="status"
      >
        <span className="sr-only">
          <FormattedMessage {...messages.loadingText} />
        </span>
      </div>
    </div>
  );
};

Spinner.defaultProps = {
  color: 'secondary',
  size: 'md',
  justify: 'center',
};

Spinner.propTypes = {
  show: PropTypes.bool,
  containerClassName: PropTypes.string,
  /**
   * Component color variations.
   * @type {('primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark')}
   */
  color: PropTypes.string,
  /**
   * Component size variations.
   * @type {('sm'|'md'|'lg')}
   */
  size: PropTypes.string,
  /**
   * Component position variations.
   * @type {('left'|'center'|'end')}
   */
  justify: PropTypes.string,
};

export default CSSModule(Spinner, styles, { allowMultiple: true });
