import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const { Types, Creators } = createActions(
  {
    reset: [],
    getTaxRates: ['price'],
    getTaxRatesRequest: [],
    getTaxRatesSuccess: ['data'],
    getTaxRatesFailure: ['error'],
  },
  { prefix: 'cb/taxRates/' },
);

export { Types as types, Creators as actions };

export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_TAX_RATES_REQUEST]: request,
  [Types.GET_TAX_RATES_SUCCESS]: success,
  [Types.GET_TAX_RATES_FAILURE]: failure,
};

export default resettableReducer(Types.RESET)(
  createReducer(INITIAL_STATE, HANDLERS),
);
