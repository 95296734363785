import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

const { Types, Creators } = createActions(
  {
    getMembershipPlans: [],
    getMembershipPlansRequest: [],
    getMembershipPlansSuccess: ['data'],
    getMembershipPlansFailure: ['error'],
  },
  { prefix: 'cb/memberships/plans/' },
);

export { Types as types, Creators as actions };

export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_MEMBERSHIP_PLANS_REQUEST]: request,
  [Types.GET_MEMBERSHIP_PLANS_SUCCESS]: success,
  [Types.GET_MEMBERSHIP_PLANS_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
