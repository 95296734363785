import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './UsedCoupons.module.scss';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import { AppWrapper, Block, Feedback } from 'components';
import { AuthProtected, ProcessCoupon } from 'containers';
import {
  COUPON_LIST_DATE_FORMAT_PATTERN,
  USED_COUPON_STATES,
} from 'coupon-common';
import { actions as couponsActivityActions } from 'coupon-common/src/modules/usedCoupons/couponsActivity';
import {
  actions as listActions,
  duck,
} from 'coupon-common/src/modules/usedCoupons/list';
import { getNormalizedEntity } from 'coupon-common/src/utils';
import { useNextPageHandler } from 'hooks';

import messages from './messages';

const usedCouponsEntityKey = duck.usedCouponsEntity.key;

const TABLE_LOADING_SKELETON_HEIGHT = 44;

const UsedCouponsStateToMessagesMap = {
  [USED_COUPON_STATES.WAITING]: messages.waitingStateText,
  [USED_COUPON_STATES.ACCEPTED]: messages.acceptedStateText,
  [USED_COUPON_STATES.REJECTED]: messages.rejectedStateText,
  [USED_COUPON_STATES.FAILED]: messages.failedStateText,
  [USED_COUPON_STATES.CANCELED]: messages.canceledStateText,
};

export const UsedCoupons = ({ intl, dispatch, usedCouponsListState }) => {
  const [usedCouponData, setUsedCouponData] = useState({});

  useEffect(() => {
    dispatch(listActions.getUsedCoupons());
  }, [dispatch]);

  const handleCouponClick = usedCoupon => {
    setUsedCouponData(usedCoupon);
    dispatch(couponsActivityActions.openModal());
  };

  const {
    entityList: coupons,
    isFetching: isCouponsFetching,
    error: couponsFetchingError,
    isNextPageExist: couponsNextPageExist,
  } = getNormalizedEntity(
    usedCouponsListState,
    usedCouponsEntityKey,
    intl,
    messages.usedCouponsEntity,
  );

  useNextPageHandler(
    listActions.getUsedCouponsNextPage,
    couponsNextPageExist,
    isCouponsFetching,
  );

  const columns = [
    {
      dataField: 'coupon_name',
      text: intl.formatMessage(messages.couponTitleColumnText),
      classes: 'used-coupon-title',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleCouponClick(row);
        },
      },
      sort: true,
    },
    {
      dataField: 'customer_name',
      text: intl.formatMessage(messages.customerNameColumnText),
      sort: true,
    },
    {
      dataField: 'created_at',
      text: intl.formatMessage(messages.dateAndTimeColumnText),
      formatter: value =>
        intl.formatDate(value, COUPON_LIST_DATE_FORMAT_PATTERN),
      sort: true,
    },
    {
      dataField: 'pay_by_points',
      text: intl.formatMessage(messages.payByColumnText),
      formatter: value =>
        intl.formatMessage(
          value ? messages.payByPointsText : messages.payByDollarsText,
        ),
      sort: true,
    },
    {
      dataField: 'state',
      text: intl.formatMessage(messages.stateColumnText),
      formatter: value => {
        const stateMessage = UsedCouponsStateToMessagesMap[value];
        return stateMessage ? intl.formatMessage(stateMessage) : value;
      },
      sort: true,
    },
  ];

  return (
    <AppWrapper
      showHeader
      showSideNav
      showHeadingRow
      headingText={intl.formatMessage(messages.pageHeadingText)}
    >
      <AuthProtected />
      <div className="col-md-10">
        <Block>
          <div className="overflow-auto">
            <BootstrapTable
              classes="table-borderless mb-0 overflow-auto custom-react-bootstrap-table"
              striped
              bordered={false}
              bootstrap4
              keyField="id"
              data={coupons}
              columns={columns}
            />
          </div>
          {isCouponsFetching && !couponsFetchingError && (
            <Skeleton className="mt-3" height={TABLE_LOADING_SKELETON_HEIGHT} />
          )}
          <Feedback
            show={!!couponsFetchingError}
            invalid
            content={couponsFetchingError}
          />
        </Block>
      </div>
      <ProcessCoupon usedCouponData={usedCouponData} />
    </AppWrapper>
  );
};

UsedCoupons.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  usedCouponsListState: PropTypes.object,
};

const mapStateToProps = state => ({
  usedCouponsListState: state.usedCoupons.list,
});

export default connect(mapStateToProps)(injectIntl(UsedCoupons));
