import { call, put, takeLatest } from 'redux-saga/effects';
import { Types, Creators } from './duck';
import { sendNotification } from './api';

// Worker Saga
function* sendNotificationSaga({data}) {
  try {
    console.log('sendNotificationSaga called with data:', data); // Add logging here
    yield put(Creators.sendNotificationRequest());
    const response = yield call(sendNotification, data);
    console.log('sendNotificationSaga success with response:', response); // Add logging here
    yield put(Creators.sendNotificationSuccess(response));
  } catch (error) {
    console.log('sendNotificationSaga failed with error:', error); // Add logging here
    yield put(Creators.sendNotificationFailure(error.message));
  }
}

// Watcher Saga
export default function* notificationSaga() {
  yield takeLatest(Types.SEND_NOTIFICATION, sendNotificationSaga);
}