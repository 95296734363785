import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const { Types, Creators } = createActions(
  {
    reset: [],
    getPointsBalance: [],
    getPointsBalanceRequest: [],
    getPointsBalanceSuccess: ['data'],
    getPointsBalanceFailure: ['error'],
  },
  { prefix: 'cb/pointsBalance/' },
);

export { Types as types, Creators as actions };

export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (_, action) => {
  const { error } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_POINTS_BALANCE_REQUEST]: request,
  [Types.GET_POINTS_BALANCE_SUCCESS]: success,
  [Types.GET_POINTS_BALANCE_FAILURE]: failure,
};

export default resettableReducer(Types.RESET)(
  createReducer(INITIAL_STATE, HANDLERS),
);
