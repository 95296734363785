import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.ChangePassword';

export default defineMessages({
  changePasswordModalTitle: {
    id: `${scope}.changePasswordModalTitle`,
    defaultMessage: 'Change Password',
  },
});
