import { combineReducers } from 'redux';

import { reducer as couponsActivityReducer } from './couponsActivity';
import { reducer as listReducer } from './list';
import { reducer as newUsedCouponsReducer } from './newUsedCoupons';

export default combineReducers({
  couponsActivity: couponsActivityReducer,
  list: listReducer,
  newUsedCoupons: newUsedCouponsReducer,
});
