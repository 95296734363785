import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { CouponActionModalContainer } from 'components';
import { actions } from 'coupon-common/src/modules/coupons';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import messages from './messages';

export const CouponDelete = ({
  intl,
  dispatch,
  isOpen,
  deleteCouponData,
  closeModalHandler,
}) => {
  const handleDeleteCoupon = async () => {
    closeModalHandler();
    const { success } = await dispatchAsAsync(
      actions.deleteCoupon(deleteCouponData.id),
      dispatch,
    );
    if (success) {
      toast(
        <FormattedMessage
          {...messages.deleteCouponSuccessText}
          values={{
            couponTitle: <b>{deleteCouponData.title}</b>,
          }}
        />,
      );
    }
  };

  const formattedPageHeadingText = intl.formatMessage(messages.pageHeadingText);
  const formattedDeleteCouponText = (
    <FormattedMessage
      {...messages.deleteCouponText}
      values={{
        couponTitle: deleteCouponData.title,
        b: chunks => <b key={chunks}>{chunks}</b>,
      }}
    />
  );
  const formattedCancelBtnText = intl.formatMessage(messages.cancelBtnText);
  const formattedConfirmBtnText = intl.formatMessage(messages.confirmBtnText);

  return (
    <CouponActionModalContainer
      isOpen={isOpen}
      modalHeadingText={formattedPageHeadingText}
      actionDescriptionText={formattedDeleteCouponText}
      cancelBtnText={formattedCancelBtnText}
      confirmBtnText={formattedConfirmBtnText}
      onModalClose={closeModalHandler}
      onModalConfirm={handleDeleteCoupon}
    />
  );
};

CouponDelete.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
  deleteCouponData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  closeModalHandler: PropTypes.func,
};

export default connect()(injectIntl(CouponDelete));
