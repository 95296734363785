import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import styles from './LandingBlock.module.scss';

const LandingBlock = ({ src, alt, headingText, children }) => {
  return (
    <div styleName="landing-block" className="container mb-5">
      <div className="row flex-column align-items-center">
        {src && <img src={src} alt={alt} className="img-fluid" />}
        {headingText && <h2>{headingText}</h2>}
        {children}
      </div>
    </div>
  );
};

LandingBlock.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  headingText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default CSSModule(LandingBlock, styles);
