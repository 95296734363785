import React from 'react';

import { APP_LOCALES } from 'i18n';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { actions } from 'coupon-common/src/modules/language';

import messages from './messages';

/* This component in draft state */
const LocaleToggle = ({ locale, changeLocale, intl }) => {
  const options = APP_LOCALES.map(value => (
    <option key={value} value={value}>
      {intl.formatMessage(messages[value])}
    </option>
  ));

  return (
    <div className="form-group">
      <select className="form-control" value={locale} onChange={changeLocale}>
        {options}
      </select>
    </div>
  );
};

LocaleToggle.propTypes = {
  onLocaleToggle: PropTypes.func,
  locale: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  locale: state.language.locale,
});

const mapDispatchToProps = dispatch => {
  return {
    changeLocale: e => dispatch(actions.changeLocale(e.target.value)),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LocaleToggle));
