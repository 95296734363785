import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.ChangePasswordForm';

export default defineMessages({
  currentPasswordFieldLabelText: {
    id: `${scope}.currentPassowordFieldLabelText`,
    defaultMessage: 'Current Password',
  },
  newPasswordFieldLabelText: {
    id: `${scope}.newPasswordFieldLabelText`,
    defaultMessage: 'New Password',
  },
  comfirmNewPasswordFieldLabelText: {
    id: `${scope}.comfirmNewPasswordFieldLabelText`,
    defaultMessage: 'Confirm New Password',
  },
  incorrectPasswordMessage: {
    id: `${scope}.incorrectPasswordMessage`,
    defaultMessage: 'The password you entered is incorrect.',
  },
  passwordNotMatchMessage: {
    id: `${scope}.passwordNotMatchMessage`,
    defaultMessage: 'The password does not match!',
  },
  cancelButtonText: {
    id: `${scope}.cancelButtonText`,
    defaultMessage: 'Cancel',
  },
  saveButtonText: {
    id: `${scope}.saveButtonText`,
    defaultMessage: 'Save',
  },
});
