import { all } from 'redux-saga/effects';

import * as info from './info';
import * as plans from './plans';
import * as plansActivity from './plansActivity';
import * as referralInfo from './referralInfo';

export default function* saga() {
  yield all([
    info.saga(),
    plans.saga(),
    plansActivity.saga(),
    referralInfo.saga(),
  ]);
}
