import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { getBusinessFollowers } from './api';
import { OK } from 'http-status-codes';

function* getBusinessFollowersSaga(params) {
  try {
    console.log("HERE");
    yield put(actions.getBusinessFollowersRequest());
    console.log("HERE2");
    params = "";
    const response = yield getBusinessFollowers();
    console.log("HERE AMAZINGLY");
    console.log("response: " + JSON.stringify(response));
    if (response.status === OK) {
      yield put(actions.getBusinessFollowersSuccess(response.data));
    } else {
      yield put(actions.getBusinessFollowersFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.getBusinessFollowersFailure(error.message));
  }
}

export default function* watcherSaga() {
  yield takeLatest(types.GET_BUSINESS_FOLLOWERS, getBusinessFollowersSaga);
}
