import { createActions, createReducer } from 'reduxsauce';

export const AVAILABLE_THEMES = {
  MAIN_THEME: 'main',
  DARK_THEME: 'dark',
};

export const INITIAL_STATE = {
  selected: AVAILABLE_THEMES.MAIN_THEME,
  available: Object.values(AVAILABLE_THEMES),
};

const { Types, Creators } = createActions(
  {
    changeTheme: ['theme'],
  },
  { prefix: 'cb/theme/' },
);

export { Types as types, Creators as actions };

export const setTheme = (state = INITIAL_STATE, action) => {
  const { theme } = action;
  return { ...state, selected: theme };
};

export const HANDLERS = {
  [Types.CHANGE_THEME]: setTheme,
};

export default createReducer(INITIAL_STATE, HANDLERS);
