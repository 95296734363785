import { createActions, createReducer } from 'reduxsauce';

const BUSINESS_CODE_KEY = 'businessCode';
const CUSTOMER_CODE_KEY = 'customerCode';

export const INITIAL_STATE = {
  [BUSINESS_CODE_KEY]: null,
  [CUSTOMER_CODE_KEY]: null,
  codeGenerator: {
    opened: false,
    fetching: false,
    fetched: false,
    code: null,
    error: null,
  },
};

const { Types, Creators } = createActions(
  {
    validateBusinessCode: ['code', 'resolve'],
    setBusinessCode: ['code'],
    clearBusinessCode: [],

    validateCustomerCode: ['code', 'resolve'],
    setCustomerCode: ['code'],
    clearCustomerCode: [],

    generateCode: [],
    generateCodeRequest: [],
    generateCodeSuccess: ['code'],
    generateCodeFailure: ['error'],

    openCodeGenerator: [],
    closeCodeGenerator: [],
    resetCodeGenerator: [],
  },
  { prefix: 'cb/referralCodes/' },
);

export { Types as types, Creators as actions };

export const setCodeByKey = key => (state = INITIAL_STATE, action) => {
  const { code } = action;
  return { ...state, [key]: code };
};

export const clearCodeByKey = key => state =>
  setCodeByKey(key)(state, { code: null });

export const generateCodeRequests = (state = INITIAL_STATE) => {
  return {
    ...state,
    codeGenerator: {
      ...state.codeGenerator,
      fetching: true,
      fetched: false,
      error: null,
    },
  };
};

export const generateCodeSuccess = (state = INITIAL_STATE, action) => {
  const { code } = action;
  return {
    ...state,
    codeGenerator: {
      ...state.codeGenerator,
      fetching: false,
      fetched: true,
      code,
      error: null,
    },
  };
};

export const generateCodeFailure = (state = INITIAL_STATE, action) => {
  const { error } = action;
  return {
    ...state,
    codeGenerator: {
      ...state.codeGenerator,
      fetching: false,
      fetched: true,
      code: null,
      error,
    },
  };
};

export const switchOpenStateRefCodeGen = (state, action) => {
  const { opened } = action;
  return {
    ...state,
    codeGenerator: {
      ...state.codeGenerator,
      opened,
    },
  };
};

export const openCodeGenerator = (state = INITIAL_STATE) => {
  return switchOpenStateRefCodeGen(state, { opened: true });
};

export const closeCodeGenerator = (state = INITIAL_STATE) => {
  return switchOpenStateRefCodeGen(state, { opened: false });
};

export const resetCodeGenerator = state => {
  return {
    ...state,
    codeGenerator: INITIAL_STATE.codeGenerator,
  };
};

export const HANDLERS = {
  [Types.SET_BUSINESS_CODE]: setCodeByKey(BUSINESS_CODE_KEY),
  [Types.CLEAR_BUSINESS_CODE]: clearCodeByKey(BUSINESS_CODE_KEY),
  [Types.SET_CUSTOMER_CODE]: setCodeByKey(CUSTOMER_CODE_KEY),
  [Types.CLEAR_CUSTOMER_CODE]: clearCodeByKey(CUSTOMER_CODE_KEY),

  [Types.GENERATE_CODE_REQUEST]: generateCodeRequests,
  [Types.GENERATE_CODE_SUCCESS]: generateCodeSuccess,
  [Types.GENERATE_CODE_FAILURE]: generateCodeFailure,
  [Types.OPEN_CODE_GENERATOR]: openCodeGenerator,
  [Types.CLOSE_CODE_GENERATOR]: closeCodeGenerator,
  [Types.RESET_CODE_GENERATOR]: resetCodeGenerator,
};

export default createReducer(INITIAL_STATE, HANDLERS);
