import { defineMessages } from 'react-intl';

export const validatorScope = 'cb.validators';

export default defineMessages({
  requiredMessage: {
    id: `${validatorScope}.requiredMessage`,
    defaultMessage: 'This field is mandatory.',
  },
  minLengthMessage: {
    id: `${validatorScope}.minLengthMessage`,
    defaultMessage:
      'Minimum length is {minLength} {minLength, plural, one {character} other {characters}}!',
  },
  maxLengthMessage: {
    id: `${validatorScope}.maxLengthMessage`,
    defaultMessage:
      'Maximum length is {maxLength} {maxLength, plural, one {character} other {characters}}!',
  },
  invalidEmailMessage: {
    id: `${validatorScope}.invalidEmailMessage`,
    defaultMessage: 'Invalid e-mail.',
  },
  invalidPhoneMessage: {
    id: `${validatorScope}.invalidPhoneMessage`,
    defaultMessage: 'Please enter correct phone. Mask: {mask}',
  },
  invalidPassowrdMessage: {
    id: `${validatorScope}.invalidPassowrdMessage`,
    defaultMessage:
      'The password must be at least {minLengthVal} and at most {maxLengthVal} {maxLengthVal, plural, one {character} other {characters}} long, must contain at least one uppercase character and at least one digit',
  },
  invalidImageMinSizeMessage: {
    id: `${validatorScope}.invalidImageMinSizeMessage`,
    defaultMessage: 'Minimal file size is {minSizeMB} MB',
  },
  invalidImageMaxSizeMessage: {
    id: `${validatorScope}.invalidImageMaxSizeMessage`,
    defaultMessage: 'Maximal file size is {maxSizeMB} MB',
  },
  invalidImageFormatMessage: {
    id: `${validatorScope}.invalidImageFormatMessage`,
    defaultMessage:
      'Allowed {acceptedFormatsLength, plural, one {format is} other {formats are}} {acceptedFormatsString}',
  },
  loadingErrorText: {
    id: `${validatorScope}.loadingErrorText`,
    defaultMessage: 'Could not load {entity} from server: {error}',
  },
  numericValidationText: {
    id: `${validatorScope}.numericValidationText`,
    defaultMessage: 'Please enter a numeric value.',
  },
  positiveValidationText: {
    id: `${validatorScope}.positiveValidationText`,
    defaultMessage: 'Please enter a positive value.',
  },
  nonNegativeValidationText: {
    id: `${validatorScope}.nonNegativeValidationText`,
    defaultMessage: 'Please enter a non-negative value.',
  },
  maxNumericValueValidationText: {
    id: `${validatorScope}.maxNumericValueValidationText`,
    defaultMessage: 'Please enter value upto {limit}.',
  },
});
