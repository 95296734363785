import jwtDecode from 'jwt-decode';
import { createActions, createReducer } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions(
  {
    setTokens: ['tokens'],
    clearTokens: [],
  },
  {
    prefix: 'cb/auth/tokens/',
  },
);

const INITIAL_STATE = {
  refresh: null,
  access: null,
  refreshDecoded: null,
  accessDecoded: null,
};

export default createReducer(INITIAL_STATE, {
  [types.SET_TOKENS]: (_, { tokens }) => ({
    ...tokens,
    refreshDecoded: jwtDecode(tokens.refresh),
    accessDecoded: jwtDecode(tokens.access),
  }),
  [types.CLEAR_TOKENS]: () => INITIAL_STATE,
});
