import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import styles from './NewUsedCouponsBadge.module.scss';

export const NewUsedCouponsBadge = ({
  newUsedCouponsCount,
  newUsedCouponsFetched,
}) => {
  const [props, set] = useSpring(() => ({
    opacity: newUsedCouponsFetched ? 1 : 0,
  }));
  useEffect(() => {
    set({ opacity: newUsedCouponsCount ? 1 : 0 });
  }, [newUsedCouponsCount, set]);

  return newUsedCouponsCount ? (
    <animated.span
      style={props}
      styleName="custom-notification-badge"
      className="badge ml-2"
    >
      {newUsedCouponsCount}
    </animated.span>
  ) : null;
};

NewUsedCouponsBadge.propTypes = {
  newUsedCouponsCount: PropTypes.number,
  newUsedCouponsFetched: PropTypes.bool,
};

const mapStateToProps = state => ({
  newUsedCouponsCount:
    state.usedCoupons.newUsedCoupons.data.used_coupons_wait_for_action,
  newUsedCouponsFetched: state.usedCoupons.newUsedCoupons.fetched,
});

export default connect(mapStateToProps)(CSSModule(NewUsedCouponsBadge, styles));
