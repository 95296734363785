import { schema } from 'normalizr';
import { createActions, createReducer } from 'reduxsauce';

import {
  entityFetching,
  entityFetchingFailure,
  entityFetchingSuccess,
} from '../../../utils/redux';

export const usedCouponsEntity = new schema.Entity('usedCoupons');

export const INITIAL_STATE = {
  entities: {
    [usedCouponsEntity.key]: {},
  },
  result: {
    [usedCouponsEntity.key]: {
      fetching: false,
      fetched: false,
      results: [],
      error: null,
    },
  },
};

const { Types, Creators } = createActions(
  {
    getUsedCoupons: [],
    getUsedCouponsRequest: [],
    getUsedCouponsSuccess: ['data'],
    getUsedCouponsFailure: ['error'],

    getUsedCouponsNextPage: [],
    getUsedCouponsNextPageRequest: [],
    getUsedCouponsNextPageSuccess: ['data'],
    getUsedCouponsNextPageFailure: ['error'],

    updateEntityItem: ['data'],
  },
  { prefix: 'cb/usedCoupons/list/' },
);

export { Types as types, Creators as actions };

export const updateEntityItem = (state, action) => {
  const { data } = action;
  return {
    ...state,
    entities: {
      ...state.entities,
      [usedCouponsEntity.key]: {
        ...state.entities[usedCouponsEntity.key],
        [data.id]: data,
      },
    },
    result: {
      ...state.result,
      [usedCouponsEntity.key]: {
        ...state.result[usedCouponsEntity.key],
        results: [
          ...new Set(
            state.result[usedCouponsEntity.key].results.concat(data.id),
          ),
        ],
      },
    },
  };
};

export const HANDLERS = {
  [Types.GET_USED_COUPONS_REQUEST]: entityFetching(usedCouponsEntity),
  [Types.GET_USED_COUPONS_SUCCESS]: entityFetchingSuccess(usedCouponsEntity),
  [Types.GET_USED_COUPONS_FAILURE]: entityFetchingFailure(usedCouponsEntity),

  [Types.GET_USED_COUPONS_NEXT_PAGE_REQUEST]: entityFetching(usedCouponsEntity),
  [Types.GET_USED_COUPONS_NEXT_PAGE_SUCCESS]: entityFetchingSuccess(
    usedCouponsEntity,
    { attach: true },
  ),
  [Types.GET_USED_COUPONS_NEXT_PAGE_FAILURE]: entityFetchingFailure(
    usedCouponsEntity,
  ),

  [Types.UPDATE_ENTITY_ITEM]: updateEntityItem,
};

export default createReducer(INITIAL_STATE, HANDLERS);
