import { createActions, createReducer } from 'reduxsauce';
import { combineReducers } from 'redux';

// Action Types and Creators
export const { Types, Creators } = createActions({
  sendNotification: ['data'],
  sendNotificationRequest: [],
  sendNotificationSuccess: ['response'],
  sendNotificationFailure: ['error'],
}, { prefix: 'cb/sendNotification/' });

// Initial State
const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
};

export { Types as types, Creators as actions };

// Reducers
export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (state, action) => {
  const { response } = action;
  return {
    ...state,
    fetching: false,
    fetched: true,
    data: response,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    fetched: true,
    error,
  };
};

export const HANDLERS =  {
  [Types.SEND_NOTIFICATION_REQUEST]: request,
  [Types.SEND_NOTIFICATION_SUCCESS]: success,
  [Types.SEND_NOTIFICATION_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);