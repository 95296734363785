import React, { useMemo, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import { Field, FormSpy } from 'react-final-form';
import { OnBlur } from 'react-final-form-listeners';

import {
  composeSyncValidators,
  isDecimal,
  isNonNegative,
  isPositive,
  normalizeDecimal,
  required,
  sumValues,
} from 'coupon-common/src/utils';
import {
  CANADIAN_DOLLAR_CODE,
  DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
  DEFAULT_NUMERAL_FORMATTING_MASK,
  DOLLAR_SIGN,
} from 'coupon-common';
import { Block, FormField } from 'components';
import styles from './CouponPriceFiledGroup.module.scss';
import messages from './messages';
import { actions as taxRatesActions } from 'coupon-common/src/modules/taxRates';

const CouponPriceFiledGroup = ({
  intl,
  taxRatesState,
  couponData,
  couponActionComplete,
  dispatch,
  data,
  form,
}) => {
  const readOnly = taxRatesState?.fetching || couponActionComplete || false;

  const finalPriceWithoutTax = useMemo(() => {
    const price = +data?.price || 0;
    if (couponData?.type === 'amount_off') {
      return price - couponData?.amount_off;
    } else if (couponData?.type === 'percentage_off') {
      return price - Math.ceil((price * couponData?.percentage_off) / 100);
    }
    return price;
  }, [data, couponData]);

  const discount = useMemo(() => {
    if (couponData?.type === 'amount_off') {
      return `\$${couponData?.amount_off} off`;
    } else if (couponData?.type === 'percentage_off') {
      return `${couponData?.percentage_off}% off`;
    } else return null;
  }, [couponData?.type]);

  const totalPrice = useMemo(() => {
    return (
      finalPriceWithoutTax +
      sumValues([data?.gst, data?.hst, data?.pst, data?.qst, data?.rst])
    );
  }, [finalPriceWithoutTax, data]);

  useEffect(() => {
    if (!couponActionComplete) {
      form.change('pst', taxRatesState?.data?.pst || null);
      form.change('gst', taxRatesState?.data?.gst || null);
      form.change('hst', taxRatesState?.data?.hst || null);
      form.change('qst', taxRatesState?.data?.qst || null);
      form.change('rst', taxRatesState?.data?.rst || null);
    }
  }, [taxRatesState?.data, couponActionComplete]);

  const handlePriceBlur = values => {
    let price = +(values.price || 0);
    if (price <= 0) {
      form.reset();
    }
    if (finalPriceWithoutTax > 0)
      dispatch(taxRatesActions.getTaxRates({ price: finalPriceWithoutTax }));
  };

  return (
    <>
      <Block
        className="row text-left"
        styleName="coupon-price-details-container"
      >
        <div className="col-md-12">
          <Field
            name="price"
            label={intl.formatMessage(messages.priceFieldLabel)}
            sideLabel={`${CANADIAN_DOLLAR_CODE} ${DOLLAR_SIGN}`}
            validate={composeSyncValidators(isPositive, isDecimal)}
            component={FormField}
            format={normalizeDecimal}
            formatOnBlur
            readOnly={readOnly}
            styleName={'coupon-price-input'}
          />
          <FormSpy
            render={({ values }) => (
              <>
                <OnBlur name="price">{() => handlePriceBlur(values)}</OnBlur>
              </>
            )}
          />

          {couponData?.type === 'points_back' ? null : (
            <p styleName="price-info-text">
              <FormattedMessage {...messages.dealText} />
              <span styleName="price-off-text">{discount}</span>
              <span styleName="final-price-info-text">
                {intl.formatNumber(
                  finalPriceWithoutTax,
                  DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
                )}
              </span>
              <span styleName="tax-info-text">
                <FormattedMessage {...messages.plusTaxText} />
              </span>
            </p>
          )}

          <p styleName="tax-rates-form-heading">
            <FormattedMessage {...messages.adjustTaxIfNeededText} />
          </p>
          <div
            styleName="tax-rates-grid-container"
            className="d-flex flex-wrap "
          >
            <div styleName="left-grid-item">
              <Field
                name="gst"
                label={intl.formatMessage(messages.gstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
                styleName={'coupon-price-input'}
              />
            </div>
            <div styleName="left-grid-item">
              <Field
                name="hst"
                label={intl.formatMessage(messages.hstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
                styleName={'coupon-price-input'}
              />
            </div>
            <div styleName="left-grid-item">
              <Field
                name="pst"
                label={intl.formatMessage(messages.pstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
                styleName={'coupon-price-input'}
              />
            </div>

            <div styleName="left-grid-item">
              <Field
                name="qst"
                label={intl.formatMessage(messages.qstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
                styleName={'coupon-price-input'}
              />
            </div>
            <div styleName="left-grid-item">
              <Field
                name="rst"
                label={intl.formatMessage(messages.rstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
                styleName={'coupon-price-input'}
              />
            </div>
          </div>
        </div>
      </Block>
      <div
        className="d-inline-block"
        styleName="coupon-price-filed-groupd-details-container"
      >
        <hr className="mt-2" />
        <p className="mb-1 mr-3">
          <FormattedMessage {...messages.totalPriceText} /> &nbsp; &nbsp;
          <span styleName="price">
            {intl.formatNumber(totalPrice, DEFAULT_INTL_FORMAT_NUMBER_CONFIG)}
          </span>
        </p>
        {couponData?.type === 'points_back' ? (
          <p className="mb-1 mr-3">
            <FormattedMessage {...messages.pointsBackText} /> &nbsp; &nbsp;
            <span styleName="price">
              {intl.formatNumber(
                couponData?.points_back || 0,
                DEFAULT_NUMERAL_FORMATTING_MASK,
              )}
            </span>
          </p>
        ) : null}
      </div>
      {couponData?.type === 'points_back' ? (
        <p styleName="coupon-price-field-group-points-back-text">
          <FormattedMessage
            {...messages.pointsBackInfoText}
            values={{ points: couponData?.points_back || 0 }}
          />
        </p>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  taxRatesState: state.taxRates,
});

export default connect(mapStateToProps)(
  injectIntl(CSSModules(CouponPriceFiledGroup, styles)),
);
