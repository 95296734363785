import { authApiCall } from '../auth/saga';
import { apiCall } from '../../utils';

export const getBusinessFollowers = () => {
  console.log("Here in API");
  return authApiCall({
    url: '/users/list-firebase-follows/',
    method: 'get',
  });
};
