import { combineReducers } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions(
  {
    refreshAuth: [],
    refreshAuthRequest: [],
    refreshAuthSuccess: ['tokens'],
    refreshAuthFailure: ['error'],
  },
  { prefix: 'cb/auth/refresh/' },
);

export default combineReducers({
  fetching: createReducer(false, {
    [types.REFRESH_AUTH_REQUEST]: () => true,
    [types.REFRESH_AUTH_SUCCESS]: () => false,
    [types.REFRESH_AUTH_FAILURE]: () => false,
  }),
  tokens: createReducer(null, {
    [types.REFRESH_AUTH_REQUEST]: () => null,
    [types.REFRESH_AUTH_SUCCESS]: (_, { tokens }) => tokens,
    [types.REFRESH_AUTH_FAILURE]: () => null,
  }),
  error: createReducer(null, {
    [types.REFRESH_AUTH_REQUEST]: () => null,
    [types.REFRESH_AUTH_SUCCESS]: () => null,
    [types.REFRESH_AUTH_FAILURE]: (_, { error }) => error,
  }),
});
