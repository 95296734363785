import { FORM_ERROR } from 'final-form';
import { OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import { formatApiErrors, safeResolveAsync } from '../../utils';

import * as api from './api';
import { actions, types } from './duck';

export function* changePassword({ oldPass, newPass, resolve }) {
  try {
    yield put(actions.changePasswordRequest());

    const response = yield api.changePassword({
      old_password: oldPass,
      new_password: newPass,
    });

    if (response.status === OK) {
      yield put(actions.changePasswordSuccess(response.data));
      yield safeResolveAsync(resolve);
    } else {
      yield put(actions.changePasswordFailure(response.data));
      yield safeResolveAsync(resolve, formatApiErrors(response.data));
    }
  } catch (error) {
    yield put(actions.changePasswordFailure(error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export function* recoverPassword({ email, resolve }) {
  try {
    yield put(actions.recoverPasswordRequest());

    const response = yield api.recoverPassword(email);

    if (response.status === OK) {
      yield put(actions.recoverPasswordSuccess(response.data));
      yield safeResolveAsync(resolve);
    } else {
      yield put(actions.recoverPasswordFailure(response.data));
      yield safeResolveAsync(resolve, formatApiErrors(response.data));
    }
  } catch (error) {
    yield put(actions.recoverPasswordFailure(error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

/**
 * We use "resolve advanced api" here.
 */
export function* resetPassword({ uid, token, newPassword, resolve }) {
  try {
    yield put(actions.resetPasswordRequest());

    const response = yield api.resetPassword(uid, token, newPassword);

    if (response.status === OK) {
      yield put(actions.resetPasswordSuccess(response.data));
      yield safeResolveAsync(resolve, {
        error: false,
        payload: { isCustomer: !!response.data.is_customer },
      });
    } else {
      yield put(actions.resetPasswordFailure(response.data));
      yield safeResolveAsync(resolve, {
        error: true,
        payload: formatApiErrors(response.data),
      });
    }
  } catch (error) {
    yield put(actions.resetPasswordFailure(error.message));
    yield safeResolveAsync(resolve, {
      error: true,
      payload: { [FORM_ERROR]: error.message },
    });
  }
}

export default function* saga() {
  yield takeLatest(types.CHANGE_PASSWORD, changePassword);
  yield takeLatest(types.RECOVER_PASSWORD, recoverPassword);
  yield takeLatest(types.RESET_PASSWORD, resetPassword);
}
