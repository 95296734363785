import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CSSModule from 'react-css-modules';
import styles from './ImageSlider.module.scss';

const ImageSlider = ({ images, showNavs, showBullets }) => {
  const imageCount = (images && images?.length) || 0;

  const [index, setIndex] = useState(0);

  const showNext = useCallback(() => {
    if (imageCount <= 1) return;
    setIndex(prev_index => prev_index + 1);
  }, [imageCount]);

  const showPrev = useCallback(() => {
    if (imageCount <= 1) return;
    setIndex(prev_index => prev_index - 1);
  }, [imageCount]);

  const translation = (100 / imageCount) * index;

  if (imageCount <= 0) {
    return null;
  }

  return (
    <div styleName="slides-container" onClick={showNavs ? null : showNext}>
      <div styleName="transparent-bg"></div>
      <div
        styleName="slideshow-container"
        style={{
          width: `${imageCount * 100}%`,
          transform: `translateX(-${translation}%)`,
        }}
      >
        {images.map((image, i) => (
          <div
            styleName="slide"
            style={{ width: `${100 / imageCount}%` }}
            key={i}
          >
            <img src={image} alt={'loading'} style={{ zIndex: -1 }} />
          </div>
        ))}
      </div>
      {showNavs && (
        <>
          <a
            styleName="prev"
            onClick={showPrev}
            style={{ display: index === 0 ? 'none' : 'block' }}
          >
            &#10094;
          </a>
          <a
            styleName="next"
            onClick={showNext}
            style={{
              display: index + 1 === imageCount ? 'none' : 'block',
            }}
          >
            &#10095;
          </a>
        </>
      )}

      {showBullets && (
        <div styleName="dot-container">
          {images?.map((_, i) => (
            <span
              styleName={cx('dot', {
                active: index === i,
              })}
              key={i}
            ></span>
          ))}
        </div>
      )}
    </div>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
  showBullets: PropTypes.bool,
  showNavs: PropTypes.bool,
};

ImageSlider.defaultProps = {
  images: [],
  showBullets: false,
  showNavs: false,
};

export default memo(CSSModule(ImageSlider, styles, { allowMultiple: true }));
