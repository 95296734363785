import { schema } from 'normalizr';
import { createActions, createReducer } from 'reduxsauce';

import {
  entityFetching,
  entityFetchingFailure,
  entityFetchingSuccess,
} from '../../utils/redux';

export const couponsEntity = new schema.Entity('coupons');

export const INITIAL_STATE = {
  entities: {
    [couponsEntity.key]: {},
  },
  result: {
    [couponsEntity.key]: {
      fetching: false,
      fetched: false,
      results: [],
      error: null,
    },
  },
  couponsActions: {
    fetching: false,
    fetched: false,
    data: {},
    error: null,
    couponId: null,
  },
};

const { Types, Creators } = createActions(
  {
    getBusinessCoupons: [],
    getBusinessCouponsRequest: [],
    getBusinessCouponsSuccess: ['data'],
    getBusinessCouponsFailure: ['error'],

    getBusinessCouponsNextPage: [],
    getBusinessCouponsNextPageRequest: [],
    getBusinessCouponsNextPageSuccess: ['data'],
    getBusinessCouponsNextPageFailure: ['error'],
    getBusinessCouponsNextPageNotFound: [],

    publishCoupon: ['id', 'resolve'],
    publishCouponRequest: ['id'],
    publishCouponSuccess: ['data'],
    publishCouponFailure: ['error'],

    deleteCoupon: ['id', 'resolve'],
    deleteCouponRequest: ['id'],
    deleteCouponSuccess: ['id'],
    deleteCouponFailure: ['error'],

    sponsorCoupon: ['id', 'resolve'],
    sponsorCouponRequest: ['id'],
    sponsorCouponSuccess: ['data'],
    sponsorCouponFailure: ['error'],

    republishCoupon: ['data', 'resolve'],
    republishCouponRequest: ['id'],
    republishCouponSuccess: ['data'],
    republishCouponFailure: ['error'],
  },
  { prefix: 'cb/coupons/' },
);

export { Types as types, Creators as actions };

export const request = (state, action) => {
  const { id } = action;
  return {
    ...state,
    couponsActions: {
      ...state.couponsActions,
      fetching: true,
      couponId: id,
    },
  };
};

const updateEntityItemSuccess = (state, action) => {
  const { data } = action;
  const stateToUpdate = {
    ...state,
    couponsActions: {
      ...state.couponsActions,
      fetched: true,
      fetching: false,
      error: null,
      data,
    },
  };
  stateToUpdate.entities[couponsEntity.key][data.id] = data;
  return stateToUpdate;
};

export const republishSuccess = (state, action) => {
  const { data } = action;

  const updatedState = updateEntityItemSuccess(state, action);
  const updatedResults = updatedState.result[couponsEntity.key].results;

  updatedResults.unshift(data.id);

  const prevCouponIndex = updatedResults.indexOf(state.couponsActions.couponId);

  if (~prevCouponIndex) {
    updatedResults.splice(prevCouponIndex, 1);
  }

  return updatedState;
};

export const deleteSuccess = (state, action) => {
  const entitiesToUpdate = { ...state.entities[couponsEntity.key] };
  delete entitiesToUpdate[action.id];
  const updatedResults = state.result[couponsEntity.key].results.filter(
    value => value !== action.id,
  );
  return {
    ...state,
    entities: {
      ...state.entities,
      [couponsEntity.key]: entitiesToUpdate,
    },
    result: {
      ...state.result,
      [couponsEntity.key]: {
        ...state.result[couponsEntity.key],
        results: updatedResults,
      },
    },
    couponsActions: {
      ...state.couponsActions,
      fetched: true,
      fetching: false,
      error: null,
      data: {},
    },
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    couponsActions: {
      ...state.couponsActions,
      fetching: false,
      error,
    },
  };
};

export const couponNextPageNotFound = state => {
  return {
    ...state,
    result: {
      ...state.result,
      [couponsEntity.key]: {
        ...state.result[couponsEntity.key],
        next: null,
        fetching: false,
        fetched: true,
        error: null,
      },
    },
  };
};

export const HANDLERS = {
  [Types.GET_BUSINESS_COUPONS_REQUEST]: entityFetching(couponsEntity),
  [Types.GET_BUSINESS_COUPONS_SUCCESS]: entityFetchingSuccess(couponsEntity),
  [Types.GET_BUSINESS_COUPONS_FAILURE]: entityFetchingFailure(couponsEntity),

  [Types.GET_BUSINESS_COUPONS_NEXT_PAGE_REQUEST]: entityFetching(couponsEntity),
  [Types.GET_BUSINESS_COUPONS_NEXT_PAGE_SUCCESS]: entityFetchingSuccess(
    couponsEntity,
    { attach: true },
  ),
  [Types.GET_BUSINESS_COUPONS_NEXT_PAGE_NOT_FOUND]: couponNextPageNotFound,
  [Types.GET_BUSINESS_COUPONS_NEXT_PAGE_FAILURE]: entityFetchingFailure(
    couponsEntity,
  ),

  [Types.PUBLISH_COUPON_REQUEST]: request,
  [Types.PUBLISH_COUPON_SUCCESS]: updateEntityItemSuccess,
  [Types.PUBLISH_COUPON_FAILURE]: failure,

  [Types.DELETE_COUPON_REQUEST]: request,
  [Types.DELETE_COUPON_SUCCESS]: deleteSuccess,
  [Types.DELETE_COUPON_FAILURE]: failure,

  [Types.SPONSOR_COUPON_REQUEST]: request,
  [Types.SPONSOR_COUPON_SUCCESS]: updateEntityItemSuccess,
  [Types.SPONSOR_COUPON_FAILURE]: failure,

  [Types.REPUBLISH_COUPON_REQUEST]: request,
  [Types.REPUBLISH_COUPON_SUCCESS]: republishSuccess,
  [Types.REPUBLISH_COUPON_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
