import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponBuilder';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'Coupon Builder',
  },
  previewButtonText: {
    id: `${scope}.previewButtonText`,
    defaultMessage: 'Preview coupon',
  },
  priceLessThanAmountOffText: {
    id: `${scope}.priceLessThanAmountOffText`,
    defaultMessage: 'Cannot be less than amount off',
  },
  priceLessThanPointsBackText: {
    id: `${scope}.priceLessThanPointsBackText`,
    defaultMessage: 'Cannot be less than points back',
  },
  userLimitGreaterThanTotalQuantity: {
    id: `${scope}.userLimitLessThanTotalQuantity`,
    defaultMessage: 'Cannot be greater than quantity limit',
  },
  amountOffText: {
    id: `${scope}.amountOffText`,
    defaultMessage: '${amount_off} off',
  },
  percentageOffText: {
    id: `${scope}.percentageOffText`,
    defaultMessage: '{percentage_off}% off',
  },
  pointsBackText: {
    id: `${scope}.pointsBackText`,
    defaultMessage:
      '{points_back, plural, one {{points_back} Point back} other {{points_back} Points back}}',
  },
});
