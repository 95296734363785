import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import {
  AppWrapper,
  ForgotPasswordForm,
  OnlyFormPageWrapper,
  ResetPasswordForm,
} from 'components';
import { AuthorizedRedirect } from 'containers';
import { actions } from 'coupon-common/src/modules/passwords';
import { dispatchAsAsync } from 'coupon-common/src/utils/redux';

export const ForgotPassword = ({ dispatch, data, location, history }) => {
  const query = new URLSearchParams(location.search);
  const uid = query.get('uid');
  const token = query.get('token');

  const goToLogin = () => {
    history.push('/login');
  };

  const goToHome = () => {
    history.push('/');
  };

  const onForgotPasswordFormSubmit = async values => {
    const { email } = values;
    return await dispatchAsAsync(actions.recoverPassword(email), dispatch);
  };

  const onResetPasswordFormSubmit = async values => {
    const { new_password } = values;
    const result = await dispatchAsAsync(
      actions.resetPassword(uid, token, new_password),
      dispatch,
    );

    if (result.error) {
      return result.payload;
    }

    if (result.payload.isCustomer) {
      goToHome();
    } else {
      goToLogin();
    }
  };

  const renderRecoverStepForm = () => {
    if (uid && token) {
      return (
        <Form
          onSubmit={onResetPasswordFormSubmit}
          component={ResetPasswordForm}
        />
      );
    }

    return (
      <Form
        onSubmit={onForgotPasswordFormSubmit}
        component={ForgotPasswordForm}
        data={data}
      />
    );
  };

  return (
    <AppWrapper showHeader={false} bgLight contentCentered>
      <OnlyFormPageWrapper forLogin>
        <AuthorizedRedirect />
        {renderRecoverStepForm()}
      </OnlyFormPageWrapper>
    </AppWrapper>
  );
};

ForgotPassword.propTypes = {
  dispatch: PropTypes.func,
  fetching: PropTypes.bool,
  data: PropTypes.object,
};

const mapStateToProps = state => ({
  data: state.passwords.data,
});

export default connect(mapStateToProps)(ForgotPassword);
