import { FORM_ERROR } from 'final-form';
import { CREATED, OK } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import { formatApiErrors, getObjectsDiff, safeResolveAsync } from '../../utils';

import * as api from './api';
import { actions, types } from './duck';
import { actions as couponImageActions } from '../couponImages/duck';

export function* createCoupon({ id, data, resolve }) {
  try {
    yield put(actions.createCouponRequest(id));

    const response = yield api.createCoupon(data);

    if (response.status === CREATED) {
      yield put(actions.createCouponSuccess(id, response.data));
      yield put(couponImageActions.clearCouponImagesState());
      yield safeResolveAsync(resolve);
    } else {
      if (Array.isArray(response.data)) {
        yield put(actions.createCouponFailure(id, response.data[0]));
      } else {
        yield put(actions.createCouponFailure(id, response.data.detail));
      }
      yield safeResolveAsync(resolve, formatApiErrors(response.data));
    }
  } catch (error) {
    yield put(actions.createCouponFailure(id, error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export function* getCoupon({ id }) {
  try {
    yield put(actions.getCouponRequest(id));

    const response = yield api.getCoupon(id);

    if (response.status === OK) {
      yield put(actions.getCouponSuccess(id, response.data));
    } else {
      if (Array.isArray(response.data)) {
        yield put(actions.getCouponFailure(id, response.data[0]));
      } else {
        yield put(actions.getCouponFailure(id, response.data.detail));
      }
    }
  } catch (error) {
    yield put(actions.getCouponFailure(id, error.message));
  }
}

export function* editCoupon({ id, data, resolve }) {
  try {
    const originalData = yield select(state => state.couponBuilder[id].data);
    const updatedData = getObjectsDiff(originalData, data);

    yield put(actions.editCouponRequest(id));

    const response = yield api.editCoupon(updatedData, data.id);

    if (response.status === OK) {
      yield put(actions.editCouponSuccess(id, response.data));
      yield put(couponImageActions.clearCouponImagesState());
      yield safeResolveAsync(resolve);
    } else {
      if (Array.isArray(response.data)) {
        yield put(actions.editCouponFailure(id, response.data[0]));
      } else {
        yield put(actions.editCouponFailure(id, response.data.detail));
      }
      yield safeResolveAsync(resolve, formatApiErrors(response.data));
    }
  } catch (error) {
    yield put(actions.editCouponFailure(id, error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export default function* saga() {
  yield takeLatest(types.CREATE_COUPON, createCoupon);
  yield takeLatest(types.GET_COUPON, getCoupon);
  yield takeLatest(types.EDIT_COUPON, editCoupon);
}
