import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.LocationFieldGroup';

export default defineMessages({
  blockHeading: {
    id: `${scope}.blockHeading`,
    defaultMessage: 'Location',
  },
  entityCities: {
    id: `${scope}.entityCities`,
    defaultMessage: 'cities',
  },
  entityProvinces: {
    id: `${scope}.entityProvinces`,
    defaultMessage: 'provinces',
  },
  provinceLabelText: {
    id: `${scope}.provinceLabelText`,
    defaultMessage: 'Province',
  },
  provincePlaceholderText: {
    id: `${scope}.provincePlaceholderText`,
    defaultMessage: 'Select your province',
  },
  cityLabelText: {
    id: `${scope}.cityLabelText`,
    defaultMessage: 'City',
  },
  cityPlaceholderText: {
    id: `${scope}.cityPlaceholderText`,
    defaultMessage: 'Select your city',
  },
  streetLabelText: {
    id: `${scope}.streetLabelText`,
    defaultMessage: 'Street',
  },
  geolocationRequiredMessage: {
    id: `${scope}.geolocationRequiredMessage`,
    defaultMessage: 'Please indicate the location of your business.',
  },
  locationFieldKeyword: {
    id: `${scope}.locationKeyword`,
    defaultMessage: 'location',
  },
  pinCodeFieldKeyword: {
    id: `${scope}.pinCodeFieldKeyword`,
    defaultMessage: 'Postal Code',
  },
});
