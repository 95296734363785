import { all } from 'redux-saga/effects';

import {
  auth,
  businessCategories,
  businessInfo,
  businessReferralProgram,
  couponBuilder,
  coupons,
  dashboards,
  locations,
  memberships,
  passwords,
  pointsBalance,
  referralCodes,
  signup,
  taxRates,
  usedCoupons,
  couponImages,
  sendNotification,
  followers,
} from './modules';

export default function* saga() {
  yield all([
    referralCodes.saga(),
    auth.saga(),
    signup.saga(),
    locations.saga(),
    businessCategories.saga(),
    businessReferralProgram.saga(),
    couponBuilder.saga(),
    businessInfo.saga(),
    passwords.saga(),
    pointsBalance.saga(),
    dashboards.saga(),
    coupons.saga(),
    memberships.saga(),
    taxRates.saga(),
    usedCoupons.saga(),
    couponImages.saga(),
    sendNotification.saga(),
    followers.saga(),
  ]);
}
