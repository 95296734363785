import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.BusinessInfoFieldGroup';

export default defineMessages({
  blockHeading: {
    id: `${scope}.blockHeading`,
    defaultMessage: 'Business Information',
  },
  businessNameLabelText: {
    id: `${scope}.businessNameLabelText`,
    defaultMessage: 'Business Name',
  },
  contactNameLabelText: {
    id: `${scope}.contactNameLabelText`,
    defaultMessage: 'Contact Name',
  },
  phoneLabelText: {
    id: `${scope}.phoneLabelText`,
    defaultMessage: 'Phone',
  },
  emailLabelText: {
    id: `${scope}.emailLabelText`,
    defaultMessage: 'Email',
  },
  passwordLabelText: {
    id: `${scope}.passwordLabelText`,
    defaultMessage: 'Password',
  },
  changePasswordButtonText: {
    id: `${scope}.changePasswordButtonText`,
    defaultMessage: 'Change password',
  },
  tAndCLabelText: {
    id: `${scope}.tAndCLabelText`,
    defaultMessage: 'Terms and conditions',
  },
});
