import { OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import * as api from './api';
import { actions, types } from './duck';

export function* getMembershipInfo() {
  try {
    yield put(actions.getMembershipInfoRequest());

    const response = yield api.getMembershipInfo();

    if (response.status === OK) {
      yield put(actions.getMembershipInfoSuccess(response.data));
    } else {
      yield put(actions.getMembershipInfoFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getMembershipInfoFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_MEMBERSHIP_INFO, getMembershipInfo);
}
