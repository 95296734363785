import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const { Types, Creators } = createActions(
  {
    reset: [],
    getInfo: [],
    getInfoRequest: [],
    getInfoSuccess: ['data'],
    getInfoFailure: ['error'],
    updateInfo: ['formValues'],
    updateInfoRequest: [],
    updateInfoSuccess: ['data'],
    updateInfoFailure: ['error'],
  },
  { prefix: 'cb/businessInfo/' },
);

export { Types as types, Creators as actions };

export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;

  if (
    data.location_detail &&
    data.location_detail.type &&
    data.location_detail.type === 'Point'
  )
    data.location = data.location_detail; //Used to manage profile edit page in order to support backend

  return {
    fetching: false,
    fetched: true,
    data,
    error: INITIAL_STATE.error,
  };
};

export const getFailure =
  (options = { reset: false }) =>
  (state, action) => {
    const { error } = action;
    return {
      fetching: false,
      fetched: options.reset ? INITIAL_STATE.fetched : state.fetched,
      data: options.reset ? INITIAL_STATE.data : state.data,
      error,
    };
  };

export const HANDLERS = {
  [Types.GET_INFO_REQUEST]: request,
  [Types.GET_INFO_SUCCESS]: success,
  [Types.GET_INFO_FAILURE]: getFailure(true),
  [Types.UPDATE_INFO_REQUEST]: request,
  [Types.UPDATE_INFO_SUCCESS]: success,
  [Types.UPDATE_INFO_FAILURE]: getFailure(),
};

export default resettableReducer(Types.RESET)(
  createReducer(INITIAL_STATE, HANDLERS),
);
