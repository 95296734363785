import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.Dashboard';

export default defineMessages({
  totalPointsGivenBackBlockHeading: {
    id: `${scope}.totalPointsGivenBackBlockHeading`,
    defaultMessage: 'Total points given back',
  },
  totalDollarsDiscountedBlockHeading: {
    id: `${scope}.totalDollarsDiscountedBlockHeading`,
    defaultMessage: 'Total dollars discounted',
  },
  totalFollowersBlockHeading: {
    id: `${scope}.totalFollowersBlockHeading`,
    defaultMessage: 'Your followers',
  },
  couponTypeUsedBlockHeading: {
    id: `${scope}.couponTypeUsedBlockHeading`,
    defaultMessage: 'Coupon type used by customers',
  },
  pointsText: {
    id: `${scope}.pointsText`,
    defaultMessage: '{ points, plural, one { point } other { points } }',
  },
  dollarsText: {
    id: `${scope}.dollarsText`,
    defaultMessage: '{ dollars, plural, one { dollar } other { dollars } }',
  },
  followersText: {
    id: `${scope}.followersText`,
    defaultMessage: '{ followers, plural, one { follower } other { followers } }',
  },
  amountOffText: {
    id: `${scope}.amountOffText`,
    defaultMessage: 'Amount off',
  },
  pointsBackText: {
    id: `${scope}.pointsBackText`,
    defaultMessage: 'Points back',
  },
  totalPeriodText: {
    id: `${scope}.totalPeriodText`,
    defaultMessage: 'Total',
  },
  lastYearPeriodText: {
    id: `${scope}.lastYearPeriodText`,
    defaultMessage: 'Last year',
  },
  lastThirtyDaysPeriodText: {
    id: `${scope}.lastThirtyDaysPeriodText`,
    defaultMessage: 'Last thirty days',
  },
  lastSevenDaysPeriodText: {
    id: `${scope}.lastSevenDaysPeriodText`,
    defaultMessage: 'Last seven days',
  },
});
