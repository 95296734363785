import { OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import { actions, api, types } from '.';

export function* getTaxRates({ price }) {
  try {
    yield put(actions.getTaxRatesRequest());

    const response = yield api.getTaxRates(price);

    if (response.status === OK) {
      yield put(actions.getTaxRatesSuccess(response.data));
    } else {
      if (Array.isArray(response.data)) {
        yield put(actions.getTaxRatesFailure(response.data[0]));
      } else {
        yield put(actions.getTaxRatesFailure(response.data));
      }
    }
  } catch (error) {
    yield put(actions.getTaxRatesFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_TAX_RATES, getTaxRates);
}
