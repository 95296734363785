import numeral from 'numeral';

import {
  DEFAULT_DECIMAL_ROUNDING_PRECISION,
  DEFAULT_NUMERAL_FORMATTING_MASK,
  INTEGER_FIELD_REGEX,
  MAX_SYMBOLS_BEFORE_DECIMAL_POINT,
} from '../constants';

export const normalizeInteger = value => {
  if (!value) return value;
  const onlyNums = value.replace(INTEGER_FIELD_REGEX, '');
  return Number(onlyNums);
};

export const normalizeDecimal = value => {
  if (!value) return value;
  let [partBeforeDecimalPoint, partAfterDecimalPoint] = value.split('.');
  if (partBeforeDecimalPoint.length > MAX_SYMBOLS_BEFORE_DECIMAL_POINT) {
    partBeforeDecimalPoint = partBeforeDecimalPoint.slice(
      0,
      MAX_SYMBOLS_BEFORE_DECIMAL_POINT,
    );
  }
  if (
    partAfterDecimalPoint &&
    partAfterDecimalPoint.length > DEFAULT_DECIMAL_ROUNDING_PRECISION
  ) {
    partAfterDecimalPoint = partAfterDecimalPoint.slice(
      0,
      DEFAULT_DECIMAL_ROUNDING_PRECISION,
    );
  }
  return numeral(partBeforeDecimalPoint + '.' + partAfterDecimalPoint).format(
    DEFAULT_NUMERAL_FORMATTING_MASK,
  );
};
