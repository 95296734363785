import { defineMessages } from 'react-intl';

export const scope = 'cb.components.ShareReferralCode';

export default defineMessages({
  shareThisCodeBlockHeading: {
    id: `${scope}.shareThisCode`,
    defaultMessage: 'Share this code',
  },
  shareCodeHelpText: {
    id: `${scope}.shareCodeHelpText`,
    defaultMessage: 'Share this code with your customers/referrals',
  },
  shareLinkHelpText: {
    id: `${scope}.shareLinkHelpText`,
    defaultMessage: 'You can send this link by email or social media',
  },
  codeWasCopiedFeedbackText: {
    id: `${scope}.codeWasCopiedFeedbackText`,
    defaultMessage: 'Code was copied to the clipboard',
  },
  shareReferralCodeAlt: {
    id: `${scope}.shareReferralCodeAlt`,
    defaultMessage: 'Share your code',
  },
});
