import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import sharedMessages from 'sharedMessages';

import { AppWrapperHeading, Footer } from 'components';
import { Header, NewUsedCouponsBadge } from 'containers';

import styles from './AppWrapper.module.scss';
import messages from './messages';

const AppWrapper = ({
  children,
  contentCentered,
  showHeader,
  showSideNav,
  showHeadingRow,
  headingRowRender,
  headingText,
  bgLight,
}) => {
  return (
    <div styleName={cx('app-container', { 'light-bg': bgLight })}>
      {contentCentered && !showHeader && <div className="mb-auto" />}
      {showHeader && <Header mbAuto={contentCentered} />}
      <main>
        <div className="container">
          {showHeadingRow && (
            <div className="row" styleName="heading-row">
              {headingRowRender ? (
                headingRowRender()
              ) : (
                <AppWrapperHeading
                  offsetLeft={showSideNav}
                  headingText={headingText}
                />
              )}
            </div>
          )}
          <div
            className={cx('row', { 'justify-content-center': contentCentered })}
          >
            {showSideNav && (
              <div styleName="side-nav" className="col-md-2">
                <NavLink to="/dashboard">
                  <FormattedMessage
                    {...sharedMessages.dashboardPageHeadingText}
                  />
                </NavLink>
                <NavLink exact to="/coupons">
                  <FormattedMessage {...messages.myCouponsLinkText} />
                </NavLink>
                <NavLink exact to="/coupons/create">
                  <FormattedMessage {...messages.couponBuilderLinkText} />
                </NavLink>
                <NavLink to="/referral-program">
                  <FormattedMessage {...messages.referralProgramLinkText} />
                </NavLink>
                <NavLink to="/account">
                  <FormattedMessage {...messages.myAccountLinkText} />
                </NavLink>
                <NavLink to="/used-coupons">
                  <FormattedMessage {...messages.usedCouponsLinkText} />
                  <NewUsedCouponsBadge />
                </NavLink>
                <NavLink to="/membership-plans">
                  <FormattedMessage
                    {...sharedMessages.membershipPlansPageHeadingText}
                  />
                </NavLink>
              </div>
            )}
            {children}
          </div>
        </div>
      </main>
      <Footer mtAuto />
    </div>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  bgLight: PropTypes.bool,
  contentCentered: PropTypes.bool,
  showHeader: PropTypes.bool,
  showSideNav: PropTypes.bool,
  showHeadingRow: PropTypes.bool,
  headingRowRender: PropTypes.func,
  headingText: PropTypes.string,
};

export default CSSModule(AppWrapper, styles, { allowMultiple: true });
