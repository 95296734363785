import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.Login';

export default defineMessages({
  loginButtonText: {
    id: `${scope}.loginButtonText`,
    defaultMessage: 'Login',
  },
  forgotPasswordLinkText: {
    id: `${scope}.forgotPasswordLinkText`,
    defaultMessage: 'Forgot password?',
  },
  goToSignUpLinkText: {
    id: `${scope}.goToSignUpLinkText`,
    defaultMessage: 'Do not have an account?',
  },
  rememberMeCheckboxText: {
    id: `${scope}.rememberMeCheckboxText`,
    defaultMessage: 'Remember me',
  },
});
