import React from 'react';

import PropTypes from 'prop-types';

import { Button } from 'components';

const PillCheckbox = ({ text, checked, onClick }) => {
  return (
    <Button checkable checked={checked} onClick={onClick}>
      {text}
    </Button>
  );
};

PillCheckbox.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PillCheckbox;
