import { defineMessages } from 'react-intl';

export const scope = 'cb.components.FormImageField';

export default defineMessages({
  previewImgAlt: {
    id: `${scope}.previewImgAlt`,
    defaultMessage: 'preview',
  },
  croppingModalTitle: {
    id: `${scope}.croppingModalTitle`,
    defaultMessage: 'Cropping',
  },
  croppingModalDoneButtonText: {
    id: `${scope}.croppingModalDoneButtonText`,
    defaultMessage: 'Done',
  },
});
