import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Feedback, Modal, Spinner } from 'components';
import { actions } from 'coupon-common/src/modules/referralCodes';

import messages from './messages';

const ReferralCodeGenerator = ({
  intl,
  dispatch,
  referralCodes,
  onConfirm,
}) => {
  const {
    codeGenerator: { opened, fetching, code, error },
  } = referralCodes;

  const handleConfirm = () => {
    dispatch(actions.closeCodeGenerator());
    if (onConfirm) {
      onConfirm();
    }
  };

  const regenerateCode = () => {
    dispatch(actions.generateCode());
  };

  return (
    <Modal
      title={intl.formatMessage(messages.codeGeneratorModalTitle)}
      isOpen={opened}
    >
      <div>
        {!error && (
          <p>
            <FormattedMessage
              {...messages.yourReferralCodeText}
              values={{ code: <b>{code}</b> }}
            />
          </p>
        )}
        <div className="d-flex justify-content-center mt-5">
          <Spinner show={fetching} />
          <Feedback
            fullWidth={false}
            show={error}
            content={intl.formatMessage(messages.codeGenerationErrorText, {
              error,
            })}
            invalid
          />
          {!fetching && !error && (
            <>
              <Button outline onClick={regenerateCode}>
                <FormattedMessage {...messages.regenerateButtonText} />
              </Button>
              <Button onClick={handleConfirm}>
                <FormattedMessage {...messages.confirmButtonText} />
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

ReferralCodeGenerator.propTypes = {
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  referralCodes: PropTypes.object,
  onConfirm: PropTypes.func,
};

const mapStateToProps = state => ({
  referralCodes: state.referralCodes,
});

export default connect(mapStateToProps)(injectIntl(ReferralCodeGenerator));
