import { defineMessages } from 'react-intl';

export const scope = 'cb.components.TotalPointsEarned';

export default defineMessages({
  yourTotalPointsEarnedBlockHeading: {
    id: `${scope}.yourTotalPointsEarnedBlockHeading`,
    defaultMessage: 'Your total points earned',
  },
  pointsEarnRulesText: {
    id: `${scope}.pointsEarnRulesText`,
    // defaultMessage: 'You will earn { earnPoints, plural, one {# point} other {# points}} per year for every member signed up under your code',
    defaultMessage:
      'You will earn { earnPoints, plural, one {# point} other {# points}} per year for every member signed up under a paid membership with your referral code',
  },
  pointsText: {
    id: `${scope}.pointsText`,
    defaultMessage: '{ points, plural, one { point } other { points } }',
  },
});
