import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponCategoryField';

export default defineMessages({
  categoryFieldLabel: {
    id: `${scope}.categoryFieldLabel`,
    defaultMessage: 'Coupon category',
  },
  categoryFieldPlaceholder: {
    id: `${scope}.categoryFieldPlaceholder`,
    defaultMessage: 'Select Coupon Category',
  },
  entityCouponCategories: {
    id: `${scope}.entityCouponCategories`,
    defaultMessage: 'coupon categories',
  },
});
