import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Block, FormCustomSelect, FormField, FormGeoField } from 'components';
import { actions, duck } from 'coupon-common/src/modules/locations';
import {
  composeSyncValidators,
  getNormalizedEntity,
  maxLength,
  required,
} from 'coupon-common/src/utils';

import messages from './messages';

export const geolocationRequired = value =>
  required(value) ? (
    <FormattedMessage {...messages.geolocationRequiredMessage} />
  ) : (
    undefined
  );

export const geoJSONGeometryParser = value => {
  if (value) {
    const { lat, lng } = value;
    return {
      type: 'Point',
      coordinates: [lat, lng],
    };
  }
  return null;
};

export const geoJSONGeometryFormatter = value => {
  if (value) {
    const { coordinates } = value;
    return { lat: coordinates[0], lng: coordinates[1] };
  }
  return null;
};

const LocationFieldGroup = ({
  intl,
  locations,
  readOnly,
  fullWidth,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(actions.getProvinces());
  }, [dispatch]);

  const provinces = duck.provincesEntity.key;
  const cities = duck.citiesEntity.key;

  const {
    entityList: provinceOptions,
    isFetching: isProvinceFetching,
    error: provinceLoadingError,
    isNextPageExist: provinceNextPageExist,
  } = getNormalizedEntity(locations, provinces, intl, messages.entityProvinces);

  const {
    entityList: cityOptions,
    isFetching: isCityFetching,
    error: cityLoadingError,
    isNextPageExist: cityNextPageExist,
  } = getNormalizedEntity(locations, cities, intl, messages.entityCities);

  const onProvinceFetchMore = () => {
    if (provinceNextPageExist) {
      dispatch(actions.getProvincesNextPage());
    }
  };
  const onCitiesFetchMore = () => {
    if (cityNextPageExist) {
      dispatch(actions.getCitiesNextPage());
    }
  };

  const searchForCity = (provinceName, name) => {
    dispatch(actions.getFilteredCities(provinceName, name));
  };
  const getOnCitySearch = province => value => {
    if (province) {
      searchForCity(province.name, value);
    }
  };

  return (
    <Block>
      <div className="row justify-content-center">
        <div className={fullWidth ? 'col-md-12' : 'col-md-7'}>
          <h5>
            <FormattedMessage {...messages.blockHeading} />
          </h5>
          <Field
            name="province"
            label={intl.formatMessage(messages.provinceLabelText)}
            placeholder={intl.formatMessage(messages.provincePlaceholderText)}
            validate={required}
            component={FormCustomSelect}
            fetching={isProvinceFetching}
            options={provinceOptions}
            hasMore={provinceNextPageExist}
            fetchMore={onProvinceFetchMore}
            externalError={provinceLoadingError}
            readOnly={readOnly}
          />
          <FormSpy
            render={({ form, values: { city } }) => (
              <OnChange name="province">
                {value => {
                  if (!value || (city && city.province !== value.id)) {
                    form.change('city', null);
                  }
                  if (value) {
                    dispatch(actions.getCities(value.name));
                  }
                }}
              </OnChange>
            )}
          />
          <FormSpy
            subscription={{ values: true }}
            render={({ values: { province } }) => (
              <Field
                name="city"
                label={intl.formatMessage(messages.cityLabelText)}
                placeholder={intl.formatMessage(messages.cityPlaceholderText)}
                validate={required}
                component={FormCustomSelect}
                fetching={isCityFetching}
                options={cityOptions}
                hasMore={cityNextPageExist}
                fetchMore={onCitiesFetchMore}
                onSearch={getOnCitySearch(province)}
                externalError={cityLoadingError}
                readOnly={!province || readOnly}
              />
            )}
          />
          <Field
            name="street"
            label={intl.formatMessage(messages.streetLabelText)}
            validate={composeSyncValidators(required, maxLength())}
            readOnly={readOnly}
            component={FormField}
          />
          <Field
            name="postal_code"
            label={intl.formatMessage(messages.pinCodeFieldKeyword)}
            validate={composeSyncValidators(required)}
            readOnly={readOnly}
            component={FormField}
          />
          <Field
            name="location"
            keyword={intl.formatMessage(messages.locationFieldKeyword)}
            validate={geolocationRequired}
            parse={geoJSONGeometryParser}
            format={geoJSONGeometryFormatter}
            readOnly={readOnly}
            component={FormGeoField}
          />
        </div>
      </div>
    </Block>
  );
};

LocationFieldGroup.propTypes = {
  intl: PropTypes.object.isRequired,
  locations: PropTypes.object,
  fullWidth: PropTypes.bool,
  dispatch: PropTypes.func,
  readOnly: PropTypes.bool,
};

const mapStateToProps = state => ({
  locations: state.locations,
});

export default connect(mapStateToProps)(injectIntl(LocationFieldGroup));
