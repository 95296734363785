import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import sharedMessages from 'sharedMessages';

import { Block } from 'components';
import { actions } from 'coupon-common/src/modules/memberships/info';

import NotSponsoredBusinessIcon from './icons/NotSponsoredBusinessIcon.svg';
import SponsoredBusinessIcon from './icons/SponsoredBusinessIcon.svg';
import styles from './MembershipInfo.module.scss';
import messages from './messages';

const FETCHING_FIELD_PLACEHOLDER = '---';

export const MembershipInfo = ({ intl, dispatch, data, fetching }) => {
  useEffect(() => {
    dispatch(actions.getMembershipInfo());
  }, [dispatch]);

  const renderFieldValue = fieldValue =>
    fetching ? FETCHING_FIELD_PLACEHOLDER : fieldValue;

  return (
    <Block>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8">
              <h5>{intl.formatMessage(messages.membershipInfoText)}</h5>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end">
                <Link
                  styleName="learn-more-link"
                  className="align-self-end"
                  to="/membership-plans"
                >
                  {intl.formatMessage(sharedMessages.learnMoreLinkText)}
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-borderless table-striped striped-odd">
            <tbody>
              <tr>
                <td className="text-muted">
                  {intl.formatMessage(messages.currentPlanText)}
                </td>
                <td align="right">{renderFieldValue(data.name)}</td>
              </tr>
              <tr>
                <td className="text-muted">
                  {intl.formatMessage(messages.couponCapacityText)}
                </td>
                <td align="right">{renderFieldValue(data.coupon_capacity)}</td>
              </tr>
              <tr>
                <td className="text-muted">
                  {intl.formatMessage(messages.availableCouponsText)}
                </td>
                <td align="right">
                  {renderFieldValue(data.available_coupons)}
                </td>
              </tr>
              <tr>
                <td className="text-muted">
                  {intl.formatMessage(messages.sponsorshipCapacityText)}
                </td>
                <td align="right">
                  {renderFieldValue(data.sponsorship_capacity)}
                </td>
              </tr>
              <tr>
                <td className="text-muted">
                  {intl.formatMessage(messages.availableSponsorshipSlotsText)}
                </td>
                <td align="right">
                  {renderFieldValue(data.available_sponsorships_slots)}
                </td>
              </tr>
              <tr>
                <td className="text-muted">
                  {intl.formatMessage(messages.businessIsSponsoredText)}
                </td>
                <td align="right">
                  {renderFieldValue(
                    <img
                      src={
                        data.make_business_sponsored
                          ? SponsoredBusinessIcon
                          : NotSponsoredBusinessIcon
                      }
                      alt={intl.formatMessage(
                        messages.businessIsSponsoredStatusText,
                      )}
                    />,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Block>
  );
};

MembershipInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  data: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  fetching: state.memberships.info.fetching,
  data: state.memberships.info.data.current_plan,
});

export default connect(mapStateToProps)(
  injectIntl(CSSModule(MembershipInfo, styles)),
);
