import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { FormFieldWrapper } from 'components';

import { ReactComponent as CheckboxCheckedIcon } from './CheckboxCheckedIcon.svg';
import { ReactComponent as CheckboxOutlineIcon } from './CheckboxOutlineIcon.svg';
import styles from './FormCheckbox.module.scss';

const FormCheckbox = ({ label, input, meta }) => {
  const shouldDisplayError = !!(
    (meta.error || meta.submitError) &&
    meta.touched
  );

  return (
    <FormFieldWrapper
      invalid={shouldDisplayError}
      error={meta.error || meta.submitError}
    >
      <div styleName="checkbox">
        <input {...input} />
        {input.checked ? (
          <CheckboxCheckedIcon styleName="checkbox-checked" />
        ) : (
          <CheckboxOutlineIcon styleName="checkbox-outline" />
        )}
        <span>{label}</span>
      </div>
    </FormFieldWrapper>
  );
};

FormCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  input: PropTypes.object,
};

export default CSSModule(FormCheckbox, styles, { allowMultiple: true });
