import { all } from 'redux-saga/effects';

import * as loginModule from './login';
import * as persistModule from './persist';
import * as refreshModule from './refresh';

// For compatibility with old api.
export * from './helpers';

export default function* saga() {
  yield all([loginModule.saga(), refreshModule.saga(), persistModule.saga()]);
}
