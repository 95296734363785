import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, Coupon } from 'components';
import { DEFAULT_INTL_FORMAT_NUMBER_CONFIG } from 'coupon-common';
import { roundNumber, sumValues } from 'coupon-common/src/utils';

import styles from './CouponTemplate.module.scss';
import messages from './messages';

export const CouponTemplate = ({ templateMode, data, businessData, intl }) => {
  const total_discount = useMemo(() => {
    let total_discount = 0;
    if (data.price && +data.price > 0) {
      if (data.type === 'amount_off' && data.amount_off && +data.amount_off > 0)
        total_discount = +data.amount_off;

      if (
        data.type === 'percentage_off' &&
        data.percentage_off &&
        +data.percentage_off > 0
      )
        total_discount = (+data.price * +data.percentage_off) / 100;
    }
    return roundNumber(total_discount);
  }, [data.price, data.type, data.amount_off, data.percentage_off]);

  const totalTaxes = sumValues([
    data.gst,
    data.pst,
    data.qst,
    data.rst,
    data.hst,
  ]);
  const price = roundNumber(Number(data.price) || 0);
  const totalCost = sumValues([totalTaxes, price, -total_discount]);

  const totalTaxesFormatted = intl.formatNumber(
    totalTaxes,
    DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
  );
  const priceFormatted = intl.formatNumber(
    price,
    DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
  );
  const totalDiscountFormatted = intl.formatNumber(
    total_discount,
    DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
  );
  const totalCostFormatted = intl.formatNumber(
    totalCost,
    DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
  );

  return (
    <Block>
      <h5>
        <FormattedMessage {...messages.blockHeadingText} />
      </h5>
      <Coupon
        data={data}
        businessData={businessData}
        templateMode={templateMode}
      />
      {data.show_price_on_coupon && (
        <div styleName="total-price-section">
          <span>
            {`${priceFormatted} - ${totalDiscountFormatted} + ${totalTaxesFormatted} = ${totalCostFormatted}`}
          </span>
        </div>
      )}
    </Block>
  );
};

CouponTemplate.propTypes = {
  data: PropTypes.object,
  businessData: PropTypes.object,
  templateMode: PropTypes.bool,
};

export default injectIntl(CSSModule(CouponTemplate, styles), messages);
