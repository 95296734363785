import React from 'react';

import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Block } from 'components';

import messages from './messages';

export const ReferralsInfo = ({ data }) => {
  const getTable = () => {
    if (data && data.length > 0) {
      return (
        <div className="table-responsive">
          <table className="table table-borderless table-striped">
            <thead className="thead-light">
              <tr>
                <th>
                  <FormattedMessage {...messages.nameTableColumnHeading} />
                </th>
                <th className="text-right">
                  <FormattedMessage
                    {...messages.signedDateTableColumnHeading}
                  />
                </th>
                <th className="text-right">
                  <FormattedMessage
                    {...messages.numbersOfSignupsTableColumnHeading}
                  />
                </th>
                <th className="text-right">
                  <FormattedMessage
                    {...messages.collectedPointsTableColumnHeading}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((referral, index) => (
                <tr key={index}>
                  <td align="left" className="text-muted">
                    {referral.full_name}
                  </td>
                  <td align="right">
                    <FormattedDate
                      day="2-digit"
                      month="2-digit"
                      year="numeric"
                      value={referral.signed_at}
                    />
                  </td>
                  <td align="right">{referral.number_of_signups}</td>
                  <td align="right">
                    <FormattedMessage
                      {...messages.poinText}
                      values={{ points: referral.collected_points }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <p className="text-muted">
        <FormattedMessage {...messages.youHaveNoReferralsText} />
      </p>
    );
  };

  return (
    <Block>
      <div className="row">
        <div className="col-md-12">
          <h5>
            <FormattedMessage {...messages.yourReferralsBlockHeading} />
          </h5>
          {getTable()}
        </div>
      </div>
    </Block>
  );
};

ReferralsInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default ReferralsInfo;
