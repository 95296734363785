import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.ReferralCodeForm';

export default defineMessages({
  formHeading: {
    id: `${scope}.formHeading`,
    defaultMessage: "Enter your marketer's referral code",
  },
  continueButtonText: {
    id: `${scope}.continueButtonText`,
    defaultMessage: 'Continue',
  },
});
