import { defineMessages } from 'react-intl';

export const scope = 'cb.components.AppWrapper';

export default defineMessages({
  referralProgramLinkText: {
    id: `${scope}.referralProgramLinkText`,
    defaultMessage: 'Referral Program',
  },
  myAccountLinkText: {
    id: `${scope}.myAccountLinkText`,
    defaultMessage: 'My Account',
  },
  couponBuilderLinkText: {
    id: `${scope}.couponBuilderLinkText`,
    defaultMessage: 'Coupon Builder',
  },
  myCouponsLinkText: {
    id: `${scope}.myCouponsLinkText`,
    defaultMessage: 'My Coupons',
  },
  usedCouponsLinkText: {
    id: `${scope}.usedCouponsLinkText`,
    defaultMessage: 'Used Coupons',
  },
});
