import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, Chart } from 'components';

import messages from './messages';
import styles from './ReceivedMonetaryUnitsStatistic.module.scss';

const mergeChartDataArrays = (pointsArray, dollrasArray) => {
  return pointsArray.map(pointItem => {
    const relatedDollarValue = dollrasArray.find(
      dollarItem => dollarItem.name === pointItem.name,
    );
    return {
      name: pointItem.name,
      points: pointItem.points,
      dollars: relatedDollarValue && relatedDollarValue.dollars,
    };
  });
};

export const ReceivedMonetaryUnitsStatistic = ({
  intl,
  receivedPointsChartData,
  receivedDollarsChartData,
  amountPointsReceived,
  amountDollarsReceived,
}) => {
  const dollarsBarConfig = {
    dataKey: 'dollars',
    label: intl.formatMessage(messages.chartLegendDollarsText),
    className: 'dollars-bar',
  };
  const pointsBarConfig = {
    dataKey: 'points',
    label: intl.formatMessage(messages.chartLegendPointsText),
    className: 'points-bar',
  };

  const defaultChartBarsConfig = [pointsBarConfig, dollarsBarConfig];

  const [chartData, setChartData] = useState([]);
  const [chartBarsConfig, setChartBarsConfig] = useState(
    defaultChartBarsConfig,
  );

  const [colorizeDollarsButton, setColorizeDollarsButton] = useState(true);
  const [colorizePointsButton, setColorizePointsButton] = useState(true);

  useEffect(() => {
    if (receivedPointsChartData && receivedDollarsChartData) {
      const mergedChartDataArrays = mergeChartDataArrays(
        receivedPointsChartData,
        receivedDollarsChartData,
      );
      setChartData(mergedChartDataArrays);
    }
  }, [receivedDollarsChartData, receivedPointsChartData]);

  const handleDollarsClick = () => {
    if (
      chartBarsConfig
        .map(item => item.dataKey)
        .includes(dollarsBarConfig.dataKey)
    ) {
      if (chartBarsConfig.length > 1) {
        setChartBarsConfig([pointsBarConfig]);
        setColorizeDollarsButton(false);
      }
    } else {
      setChartBarsConfig(defaultChartBarsConfig);
      setColorizeDollarsButton(true);
    }
  };

  const handlePointsClick = () => {
    if (
      chartBarsConfig
        .map(item => item.dataKey)
        .includes(pointsBarConfig.dataKey)
    ) {
      if (chartBarsConfig.length > 1) {
        setChartBarsConfig([dollarsBarConfig]);
        setColorizePointsButton(false);
      }
    } else {
      setChartBarsConfig(defaultChartBarsConfig);
      setColorizePointsButton(true);
    }
  };

  return (
    <Block>
      <div className="row">
        <div className="col-md-12">
          <FormattedMessage
            tagName="h5"
            {...messages.amountReceivedBlockHeading}
          />

          <div className="d-flex flex-wrap">
            <div
              onClick={handlePointsClick}
              className="d-flex flex-column flex-basis-50"
            >
              <div className="d-flex flex-column" styleName="clickable-text">
                <b
                  styleName={cx({
                    'points-colored-text': colorizePointsButton,
                  })}
                  className="accent"
                >
                  {amountPointsReceived}
                </b>
                <span className="text-muted text-uppercase">
                  <FormattedMessage
                    {...messages.pointsText}
                    values={{ points: amountPointsReceived }}
                  />
                </span>
              </div>
            </div>
            <div
              onClick={handleDollarsClick}
              className="d-flex flex-column align-items-end flex-basis-50"
            >
              <div
                className="d-flex flex-column align-items-end"
                styleName="clickable-text"
              >
                <b
                  styleName={cx({
                    'dollars-colored-text': colorizeDollarsButton,
                  })}
                  className="accent"
                >
                  {amountDollarsReceived}
                </b>
                <span className="text-muted text-uppercase">
                  <FormattedMessage
                    {...messages.dollarsText}
                    values={{ dollars: amountDollarsReceived }}
                  />
                </span>
              </div>
            </div>
          </div>
          {chartData.length > 0 && chartBarsConfig && (
            <Chart data={chartData} barsConfig={chartBarsConfig} />
          )}
        </div>
      </div>
    </Block>
  );
};

export default injectIntl(
  CSSModule(ReceivedMonetaryUnitsStatistic, styles, {
    allowMultiple: true,
  }),
);
