import './Dashboard.module.scss';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { animated, useTrail } from 'react-spring';
import sharedMessages from 'sharedMessages';

import {
  AppWrapper,
  AppWrapperHeading,
  Block,
  Chart,
  Feedback,
  ReceivedMonetaryUnitsStatistic,
  Spinner,
} from 'components';
import { AuthProtected } from 'containers';
import { DEFAULT_ANIMATION_OPACITY_VALUES } from 'coupon-common';
import { actions } from 'coupon-common/src/modules/dashboards';
import { required } from 'coupon-common/src/utils';
import {actions as followerActions } from 'coupon-common/src/modules/followers/duck';

import messages from './messages';

const DEFAULT_PERIOD_OPTION = {
  value: 'TOTAL',
  label: <FormattedMessage {...messages.totalPeriodText} />,
};

const PERIOD_OPTIONS = [
  DEFAULT_PERIOD_OPTION,
  {
    value: 'LAST_YEAR',
    label: <FormattedMessage {...messages.lastYearPeriodText} />,
  },
  {
    value: 'LAST_THIRTY_DAYS',
    label: <FormattedMessage {...messages.lastThirtyDaysPeriodText} />,
  },
  {
    value: 'LAST_SEVEN_DAYS',
    label: <FormattedMessage {...messages.lastSevenDaysPeriodText} />,
  },
];

export const Dashboard = ({
  intl,
  data,
  fetching,
  fetched,
  error,
  dispatch,
  followerState
}) => {
  useEffect(() => {
    dispatch(actions.getData(DEFAULT_PERIOD_OPTION.value));
  }, [dispatch]);

  const usageInfoChartBarsConfig = [
    {
      dataKey: 'amount_off',
      label: intl.formatMessage(messages.amountOffText),
      className: 'amount-off-bar',
    },
    {
      dataKey: 'points_back',
      label: intl.formatMessage(messages.pointsBackText),
      className: 'points-back-bar',
    },
  ];

  useEffect(() => {
       dispatch(followerActions.getBusinessFollowers());
    }, [dispatch]);
  

  const onSelectChange = (input, value, form) => {
    input.onChange(value);
    form.submit();
  };

  const onSubmit = values => {
    dispatch(actions.getData(values.range.value));
  };

  const followerCount = followerState.data.followers_count;

  const renderStatisticIntervalForm = () => {
    return (
      <Form
        onSubmit={onSubmit}
        render={({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="range"
              validate={option => required(option.value)}
              defaultValue={DEFAULT_PERIOD_OPTION}
            >
              {({ input, meta }) => (
                <>
                  <Select
                    {...input}
                    options={PERIOD_OPTIONS}
                    value={input.value}
                    getOptionLabel={option => option.label}
                    onChange={value => onSelectChange(input, value, form)}
                    isDisabled={fetching}
                  />
                  <Feedback
                    show={meta.error && !meta.pristine}
                    content={meta.error}
                    invalid
                  />
                </>
              )}
            </Field>
          </form>
        )}
      />
    );
  };

  const renderSingleValueBlock = (headingText, value, labelText) => {
    return (
      <Block>
        <div className="row">
          <div className="col-md-12 d-flex flex-wrap justify-content-between align-items-center">
            <h5 className="mb-0">{headingText}</h5>
            <div className="d-flex flex-column align-items-end">
              <b className="accent">{value}</b>
              <span className="text-muted text-uppercase">{labelText}</span>
            </div>
          </div>
        </div>
      </Block>
    );
  };

  const elementsForAnimationMap = {
    0: (
      <Block>
        <div className="row">
          <div className="col-md-12">
            <FormattedMessage
              tagName="h5"
              {...messages.couponTypeUsedBlockHeading}
            />
            <div className="col-md-12 d-flex flex-wrap">
              <div className="d-flex flex-column flex-basis-50">
                <b className="accent">{data.amount_off_coupons_count}</b>
                <span className="text-muted text-uppercase">
                  <FormattedMessage {...messages.amountOffText} />
                </span>
              </div>
              <div className="d-flex flex-column flex-basis-50">
                <b className="accent">{data.points_back_coupons_count}</b>
                <span className="text-muted text-uppercase">
                  <FormattedMessage {...messages.pointsBackText} />
                </span>
              </div>
            </div>
            {fetched && (
              <Chart
                data={data.coupon_usage_data}
                barsConfig={usageInfoChartBarsConfig}
              />
            )}
          </div>
        </div>
      </Block>
    ),
    1: fetched && (
      <ReceivedMonetaryUnitsStatistic
        receivedPointsChartData={data.amount_points_received_chart}
        receivedDollarsChartData={data.amount_dollars_received_chart}
        amountPointsReceived={data.points_from_customers}
        amountDollarsReceived={data.dollars_from_customers}
      />
    ),
    2: (
      <>
        {renderSingleValueBlock(
          intl.formatMessage(messages.totalPointsGivenBackBlockHeading),
          data.points_given_back,
          intl.formatMessage(messages.pointsText, {
            points: data.points_given_back,
          }),
        )}
        {renderSingleValueBlock(
          intl.formatMessage(messages.totalDollarsDiscountedBlockHeading),
          data.dollars_discounted,
          intl.formatMessage(messages.dollarsText, {
            dollars: data.dollars_discounted,
          }),
        )}
      </>
    ),
    3: (
      <>
        {renderSingleValueBlock(
          intl.formatMessage(messages.totalFollowersBlockHeading),
          followerCount,
          intl.formatMessage(messages.followersText, {
            followers: followerCount,
          }),
        )}
      </>
    ),
  };
  

  /* For future - move to custom hook: useTrail + it's useEffect */
  const { VISIBLE, INVISIBLE } = DEFAULT_ANIMATION_OPACITY_VALUES;
  const initialOpacity = fetched ? VISIBLE : INVISIBLE;

  const [trail, set] = useTrail(
    Object.keys(elementsForAnimationMap).length,
    () => ({ opacity: initialOpacity, position: 'absolute' }),
  );

  useEffect(() => {
    if (fetched) {
      // Trigger animation if fetched was changed
      set({ opacity: VISIBLE, position: 'initial' });
    }
  }, [fetched, set, VISIBLE]);

  const renderBlocks = () => {
    return (
      <div className="row">
        {trail.map((props, index) => (
          <animated.div key={index} style={props} className="col-md-6">
            {elementsForAnimationMap[index]}
          </animated.div>
        ))}
      </div>
    );
  };

  return (
    <AppWrapper
      showHeader
      showSideNav
      showHeadingRow
      headingRowRender={() => (
        <>
          <AppWrapperHeading
            offsetLeft
            headingText={intl.formatMessage(
              sharedMessages.dashboardPageHeadingText,
            )}
          />
          <div className="col-md-8">
            <div className="app-wrapper-heading-row row h-100 align-items-center">
              <div className="mr-4 statistic-interval-form-container">
                {renderStatisticIntervalForm()}
              </div>
              <Spinner show={fetching} />
              <Feedback
                invalid
                fullWidth={false}
                show={Boolean(!fetching && error)}
                content={error}
              />
            </div>
          </div>
        </>
      )}
    >
      <AuthProtected />
      <div className="col-md-10">{renderBlocks()}</div>
    </AppWrapper>
  );
};

Dashboard.propTypes = {
  data: PropTypes.object,
  fetching: PropTypes.bool,
  fetched: PropTypes.bool,
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  followerState: PropTypes.object,
};

const mapStateToProps = state => ({
  ...state.dashboards,
  followerState: state.followers,
});

export default connect(mapStateToProps)(injectIntl(Dashboard));
