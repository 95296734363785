import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BaseLogo from './BaseLogo.svg';
import HeaderLogo from './HeaderLogo.svg';
import messages from './messages';

const Logo = ({ intl, forHeader }) => {
  if (forHeader) {
    return <img src={HeaderLogo} alt={intl.formatMessage(messages.logoAlt)} />;
  }
  return <img src={BaseLogo} alt={intl.formatMessage(messages.logoAlt)} />;
};

Logo.propTypes = {
  intl: PropTypes.object.isRequired,
  forHeader: PropTypes.bool,
};

export default injectIntl(Logo);
