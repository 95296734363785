import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.BusinessCategoriesField';

export default defineMessages({
  blockHeading: {
    id: `${scope}.blockHeading`,
    defaultMessage: 'Business Categories',
  },
  instruction: {
    id: `${scope}.instruction`,
    defaultMessage:
      'Please choose up to 3 categories that relate to your business',
  },
  atLeastOneMessage: {
    id: `${scope}.atLeastOneMessage`,
    defaultMessage: 'Please select at least one category.',
  },
  entityBusinessCategories: {
    id: `${scope}.entityBusinessCategories`,
    defaultMessage: 'business categories',
  },
});
