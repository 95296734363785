import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const themeRootId = 'theme-root';

const ThemeProvider = ({ selected, children }) => {
  return (
    <div id={themeRootId} className={`theme-${selected}`}>
      {children}
    </div>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  selected: PropTypes.string,
};

const mapStateToProps = state => ({
  selected: state.theme.selected,
});

export default connect(mapStateToProps)(ThemeProvider);
