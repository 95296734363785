import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import sharedMessages from 'sharedMessages';

import {
  AppWrapper,
  AppWrapperHeading,
  Button,
  Feedback,
  Spinner,
} from 'components';
import {
  AuthProtected,
  BusinessCategoriesField,
  BusinessInfoFieldGroup,
  BusinessLogosFieldGroup,
  LocationFieldGroup,
  MembershipInfo,
} from 'containers';
import { actions } from 'coupon-common/src/modules/businessInfo';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import messages from './messages';

const MyBusinessAccount = ({ intl, dispatch, fetching, data }) => {
  useEffect(() => {
    dispatch(actions.getInfo());
  }, [dispatch]);

  const [editMode, setEditMode] = useState(false);

  const editModeOn = () => setEditMode(true);
  const editModeOff = () => setEditMode(false);

  const readOnly = !editMode;

  const cancelEditing = form => {
    editModeOff();

    if (form) {
      form.reset();
    }
  };

  const onSubmit = async values => {
    const submitErrors = await dispatchAsAsync(
      actions.updateInfo(values),
      dispatch,
    );

    if (!submitErrors) {
      editModeOff();
    }

    return submitErrors;
  };

  const renderControlButtons = (form, handleSubmit) => {
    if (fetching) {
      return null;
    }
    if (editMode) {
      return (
        <>
          <Button outline onClick={() => cancelEditing(form)}>
            <FormattedMessage {...messages.formCancelButtonText} />
          </Button>
          <Button onClick={handleSubmit}>
            <FormattedMessage {...messages.formSaveChangesButtonText} />
          </Button>
        </>
      );
    }
    return (
      <Button onClick={editModeOn}>
        <FormattedMessage {...messages.formEditButtonText} />
      </Button>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ form, submitError, handleSubmit }) => (
        <AppWrapper
          showHeader
          showSideNav
          showHeadingRow
          headingRowRender={() => (
            <>
              <AppWrapperHeading
                offsetLeft
                headingText={intl.formatMessage(messages.pageHeading)}
              />
              {/*
                  For future:
                  possibly we could extract it to separate component,
                  something like AppWrapperPageFeedback
              */}
              <div className="col-6">
                <div className="app-wrapper-heading-row row mr-2 h-100 justify-content-end align-items-center">
                  <Spinner show={fetching} justify="end" />
                  <Feedback
                    invalid
                    className="mr-3"
                    fullWidth={false}
                    textCenter
                    show={!fetching && submitError}
                    content={submitError}
                  />
                  {renderControlButtons(form, handleSubmit)}
                </div>
              </div>
            </>
          )}
        >
          <AuthProtected />
          <Prompt
            when={editMode}
            message={intl.formatMessage(sharedMessages.leavePageHintText)}
          />
          <div className="col-md-5">
            <BusinessInfoFieldGroup
              readOnly={readOnly}
              forDetailView
              fullWidth
            />
            <LocationFieldGroup readOnly={readOnly} fullWidth />
          </div>
          <div className="col-md-5">
            <MembershipInfo />
            <BusinessLogosFieldGroup
              readOnly={readOnly}
              forDetailView
              fullWidth
            />
            <BusinessCategoriesField readOnly={readOnly} fullWidth />
          </div>
        </AppWrapper>
      )}
    />
  );
};

MyBusinessAccount.propTypes = {
  intl: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  data: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  fetching: state.businessInfo.fetching,
  data: state.businessInfo.data,
});

export default connect(mapStateToProps)(injectIntl(MyBusinessAccount));
