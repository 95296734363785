import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import sharedMessages from 'sharedMessages';

import { AppWrapper, AppWrapperHeading, Spinner } from 'components';

import messages from './messages';

export const GroupProtected = ({
  intl,
  children,
  hasAccess,
  hasAccessDataFetching,
}) => {
  if (hasAccessDataFetching) {
    return (
      <AppWrapper
        showHeader
        showSideNav
        showHeadingRow
        headingRowRender={() => (
          <>
            <AppWrapperHeading
              offsetLeft
              headingText={intl.formatMessage(
                messages.loadingAccessRightsPageHeading,
              )}
            />
            <div className="col">
              <div className="app-wrapper-heading-row row h-100 align-items-center justify-content-end">
                <Spinner show />
              </div>
            </div>
          </>
        )}
      />
    );
  }
  if (!hasAccess) {
    return (
      <AppWrapper
        showHeader
        showSideNav
        showHeadingRow
        headingText={intl.formatMessage(
          sharedMessages.dashboardPageHeadingText,
        )}
      >
        <div className="col-md-10">
          <FormattedMessage
            tagName="p"
            {...messages.needToUpgradePlanText}
            values={{
              learnMoreLink: (
                <Link to="/membership-plans">
                  <FormattedMessage {...sharedMessages.learnMoreLinkText} />
                </Link>
              ),
            }}
          />
        </div>
      </AppWrapper>
    );
  }
  return children;
};

GroupProtected.propTypes = {
  intl: PropTypes.object,
  children: PropTypes.element,
  hasAccess: PropTypes.bool,
  hasAccessDataFetching: PropTypes.bool,
};

export default injectIntl(GroupProtected);
