import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import {
  auth,
  businessCategories,
  businessInfo,
  businessReferralProgram,
  couponBuilder,
  coupons,
  dashboards,
  language,
  locations,
  memberships,
  passwords,
  pointsBalance,
  referralCodes,
  signup,
  taxRates,
  theme,
  usedCoupons,
  couponImages,
  sendNotification,
  followers
} from './modules';

export const reducerMap = {
  auth: auth.reducer,
  signup: signup.reducer,
  referralCodes: referralCodes.reducer,
  locations: locations.reducer,
  businessCategories: businessCategories.reducer,
  /* Business user referral program info */
  businessReferralProgram: businessReferralProgram.reducer,
  couponBuilder: couponBuilder.reducer,
  /* Business user account info */
  businessInfo: businessInfo.reducer,
  theme: theme.reducer,
  language: language.reducer,
  passwords: passwords.reducer,
  pointsBalance: pointsBalance.reducer,
  dashboards: dashboards.reducer,
  coupons: coupons.reducer,
  memberships: memberships.reducer,
  usedCoupons: usedCoupons.reducer,
  taxRates: taxRates.reducer,
  couponImages: couponImages.reducer,
  sendNotification: sendNotification.reducer,
  followers: followers.reducer,
};

/**
 * Merges the received reducer with the router state
 */
export function reducerWithRouter(history) {
  return combineReducers({
    ...reducerMap,
    router: connectRouter(history),
  });
}

export default combineReducers(reducerMap);
