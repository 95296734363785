import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
  isModalOpen: false,
};

const { Types, Creators } = createActions(
  {
    acceptCoupon: ['usedCouponId', 'formData', 'resolve'],
    acceptCouponRequest: [],
    acceptCouponSuccess: ['data'],
    acceptCouponFailure: ['error'],

    rejectCoupon: ['usedCouponId', 'resolve'],
    rejectCouponRequest: [],
    rejectCouponSuccess: ['data'],
    rejectCouponFailure: ['error'],

    openModal: [],
    closeModal: [],
  },
  { prefix: 'cb/usedCoupons/couponsActivity/' },
);

export { Types as types, Creators as actions };

export const request = state => ({
  ...state,
  fetching: true,
});

export const success = (state, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    isModalOpen: state.isModalOpen,
    data,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    fetched: true,
    error,
  };
};

export const processModalState = isModalOpen => () => {
  return {
    ...INITIAL_STATE,
    isModalOpen,
  };
};

export const HANDLERS = {
  [Types.ACCEPT_COUPON_REQUEST]: request,
  [Types.ACCEPT_COUPON_SUCCESS]: success,
  [Types.ACCEPT_COUPON_FAILURE]: failure,

  [Types.REJECT_COUPON_REQUEST]: request,
  [Types.REJECT_COUPON_SUCCESS]: success,
  [Types.REJECT_COUPON_FAILURE]: failure,

  [Types.OPEN_MODAL]: processModalState(true),
  [Types.CLOSE_MODAL]: processModalState(false),
};

export default createReducer(INITIAL_STATE, HANDLERS);
