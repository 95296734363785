import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import sharedMessages from 'sharedMessages';

import {
  AppWrapper,
  AppWrapperHeading,
  Block,
  Button,
  CouponPreview,
  CouponTemplate,
  Feedback,
  Spinner,
} from 'components';
import {
  AuthProtected,
  CouponFieldGroup,
  CouponImagesFieldGroup,
} from 'containers';
import { COUPON_EXPIRY_TYPES, COUPON_TYPES } from 'coupon-common';
import { actions as businessActions } from 'coupon-common/src/modules/businessInfo';
import { actions, duck } from 'coupon-common/src/modules/couponBuilder';
import { actions as couponImageActions } from 'coupon-common/src/modules/couponImages';
import { actions as taxRatesActions } from 'coupon-common/src/modules/taxRates';
import { dispatchAsAsync, roundNumber } from 'coupon-common/src/utils';
import { actions as notificationActions } from 'coupon-common/src/modules/sendNotification/duck';
import {actions as followerActions } from 'coupon-common/src/modules/followers/duck';

import { COUPON_STATES } from 'coupon-common';

import messages from './messages';

let initialCouponValues = {
  payment_by_points: false,
  type: 'amount_off',
  expiry_type: COUPON_EXPIRY_TYPES.AMOUNT_LIMIT,
  show_price_on_coupon: true,
  reserved_hours: '4.00',
  is_exclusive_paid: false,
  is_exclusive_followers: false,
};

export const COUPON_TECHNICAL_KEY = 'newCouponID';

export const CouponBuilder = ({
  intl,
  history,
  dispatch,
  businessData,
  couponBuilderState,
  taxRatesState,
  couponImageState,
  followerState
}) => {
  const [editMode, setEditMode] = useState(false);

  const { id } = useParams();
  const couponId = id || COUPON_TECHNICAL_KEY;

  const couponBuilderData = couponBuilderState[couponId];
  const { fetching, error: fetchingError, data: couponData } =
    couponBuilderData || duck.INITIAL_COUPON_ITEM_STATE;

  useEffect(
    () => () => {
      dispatch(taxRatesActions.reset());
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(businessActions.getInfo(couponId));
    if (couponId === COUPON_TECHNICAL_KEY) {
      setEditMode(false);
    } else {
      setEditMode(true);
      dispatch(actions.getCoupon(couponId));
    }
  }, [dispatch, couponId]);

  useEffect(() => {
    dispatch(
      couponImageActions.loadCouponImagesState(
        businessData.coupon_image_slot,
        editMode ? couponData : {},
      ),
    );

    initialCouponValues = {
      ...initialCouponValues,
      terms_and_conditions: businessData.terms_and_conditions,
    };
  }, [dispatch, businessData, couponData, editMode]);

  useEffect(() => {
    const fetchFollowers = async () => {
      await dispatch(followerActions.getBusinessFollowers());
    };
    fetchFollowers();
  }, [dispatch]);


  const [couponPreviewOpen, setCouponPreviewOpen] = useState(false);

  const openCouponPreviewModal = () => setCouponPreviewOpen(true);
  const closeCouponPreviewModal = () => setCouponPreviewOpen(false);

  const handlePriceBlur = useCallback(
    values => {
      let price = +(values.price || 0);

      if (price <= 0) return;

      if (values.type === 'amount_off') {
        const amount_off = values?.amount_off || 0;
        price -= +amount_off;
      }
      if (values.type === 'percentage_off') {
        const percent = values?.percentage_off || 0;
        price -= roundNumber((price * +percent) / 100);
      }
      dispatch(taxRatesActions.getTaxRates({ price }));
    },
    [dispatch],
  );

  const handleFormSubmit = async data => {
    // Get previous state to determine if coupon is a draft or not. 
    const previousState = data.state;
    
    if (!couponPreviewOpen) {
      openCouponPreviewModal();
      return;
    }

    closeCouponPreviewModal();

    if (!data.show_price_on_coupon) {
      const { price, pst, gst, hst, rst, qst, ...rest } = data;
      data = rest;
    }

    if (!editMode) {
      const updated_image_s3_ids = Object.keys(couponImageState)
        .map(key =>
          couponImageState[key].s3_id > 0 ? couponImageState[key].s3_id : null,
        )
        .filter(val => val);

      data.coupon_images = updated_image_s3_ids;
    }

    const action = editMode
      ? actions.editCoupon(couponId, data)
      : actions.createCoupon(couponId, data);
    const submitErrors = await dispatchAsAsync(action, dispatch);

    if (!submitErrors) {
      const currentState = data.state;
      // Only send notifications on new coupons, not on edits. Send notification if an old draft gets published or a new coupon gets published
      if((!editMode && currentState==COUPON_STATES.PUBLISHED) || (editMode && previousState==COUPON_STATES.DRAFT && currentState == COUPON_STATES.PUBLISHED) ){
        const checkFollowers = new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (followerState.fetched) {
              clearInterval(interval);
              if (followerState.error) {
                reject(followerState.error);
              } else {
                resolve(followerState.data);
              }
            }
          }, 50); // Check every 50ms
        });
       
        //Get the followers here
        try {
          const followers = await checkFollowers;

          let content;

          if (data.type === COUPON_TYPES.AMOUNT_OFF) {
            content = `${data.amount_off} off!`;
          }
          if (data.type === COUPON_TYPES.POINTS_BACK) {
            content = `${data.points_back} point(s) back!`;
          }
          if (data.type === COUPON_TYPES.PERCENTAGE_OFF) {
            content = `${data.percentage_off}% off!`;
          }
          
          // Create notification data for all followers
          const notificationData = {
            tokens: followers.firebase_fcm,
            title: businessData.name + ': ' + content,
            body: data.title + ': ' + data.description,
            image: couponData?.coupon_images?.[0] ? couponData.coupon_images[0] : "",
            data: {id: data.id},
            
          };

                // Dispatch the sendNotification action
          dispatch(notificationActions.sendNotification(notificationData));
      
        } catch (error) {
          console.error('Failed to send notifications:', error);
        }
    }
      history.push('/coupons');
    }

    return submitErrors;
  };

  const validateForm = values => {
    const errors = {};

    if (
      values.expiry_type === COUPON_EXPIRY_TYPES.AMOUNT_LIMIT &&
      +values.user_limit > +values.expiration_quantity
    ) {
      errors.user_limit = intl.formatMessage(
        messages.userLimitGreaterThanTotalQuantity,
      );
    }
    if (values.price && +values.price < +values.amount_off) {
      errors.price = intl.formatMessage(messages.priceLessThanAmountOffText);
    }
    if (values.price && +values.price < +values.points_back) {
      errors.price = intl.formatMessage(messages.priceLessThanPointsBackText);
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={editMode ? couponData : initialCouponValues}
      validate={validateForm}
      render={({ submitError, handleSubmit }) => (
        <AppWrapper
          showHeader
          showSideNav
          showHeadingRow
          headingRowRender={() => (
            <>
              <AppWrapperHeading
                offsetLeft
                headingText={intl.formatMessage(messages.pageHeadingText)}
              />
              <div className="col-md">
                <div className="app-wrapper-heading-row row h-100 justify-content-end align-items-center">
                  <Spinner show={fetching} />
                  <Feedback
                    invalid
                    show={!!(fetchingError && !submitError)}
                    content={fetchingError}
                  />
                </div>
              </div>
            </>
          )}
        >
          <AuthProtected />
          <div className="col-md-5">
            <Block>
              <CouponFieldGroup
                readOnly={fetching || taxRatesState.fetching}
                taxRates={taxRatesState.data}
                onPriceBlur={handlePriceBlur}
                businessData={businessData}
              />
              <Button
                className="m-0 mt-3"
                onClick={handleSubmit}
                fetching={fetching}
                disabled={fetching}
              >
                <FormattedMessage {...messages.previewButtonText} />
              </Button>
              <Feedback
                className="mt-3"
                show={!!submitError}
                content={submitError}
                invalid
              />
            </Block>
          </div>
          <div className="col-md-5">
            <FormSpy
              subscription={{
                values: true,
                form: true,
                dirty: true,
                submitting: true,
              }}
              render={({ values, form, dirty, submitting }) => (
                <>
                  <CouponTemplate
                    templateMode
                    data={values}
                    businessData={businessData}
                  />

                  <CouponPreview
                    data={values}
                    form={form}
                    businessData={businessData}
                    isOpen={couponPreviewOpen}
                    saveCouponHandler={handleSubmit}
                    closePreviewHandler={closeCouponPreviewModal}
                    fetching={fetching}
                  />
                  <Prompt
                    when={dirty && !submitting}
                    message={intl.formatMessage(
                      sharedMessages.leavePageHintText,
                    )}
                  />
                </>
              )}
            />
            <CouponImagesFieldGroup
              couponImagesData={couponImageState}
              editMode={editMode}
              couponData={couponData}
            />
          </div>
        </AppWrapper>
      )}
    />
  );
};

CouponBuilder.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  businessData: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  couponBuilderState: PropTypes.object,
  followerState: PropTypes.object,
};

const mapStateToProps = state => ({
  couponBuilderState: state.couponBuilder,
  taxRatesState: state.taxRates,
  businessData: state.businessInfo.data,
  couponImageState: state.couponImages,
  followerState: state.followers,
});

export default connect(mapStateToProps)(injectIntl(CouponBuilder));
