import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { Field, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button, Feedback, FormField } from 'components';
import {
  composeSyncValidators,
  required,
  stringMatchWithValue,
  validPassword,
} from 'coupon-common/src/utils/validation';

import messages from './messages';
import styles from './ResetPasswordForm.module.scss';

export const ResetPasswordForm = ({
  intl,
  submitting,
  handleSubmit,
  submitError,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="new_password"
        label={intl.formatMessage(messages.newPasswordFieldLabelText)}
        type="password"
        validate={composeSyncValidators(required, validPassword())}
        component={FormField}
      />
      <FormSpy
        subscription={{ values: true }}
        render={({ values: { new_password } }) => (
          <Field
            name="repeat_new_password"
            label={intl.formatMessage(messages.repeatPasswordFieldLabel)}
            type="password"
            validate={composeSyncValidators(
              required,
              stringMatchWithValue(
                new_password,
                messages.passwordNotMatchMessage,
              ),
            )}
            showValid
            component={FormField}
          />
        )}
      />
      <Feedback
        fullWidth
        textCenter
        show={!!submitError}
        content={submitError}
        invalid
      />
      <div styleName="subform-block">
        <div className="d-flex justify-content-end">
          <Link to="/login">
            <FormattedMessage {...messages.backToLoginLinkText} />
          </Link>
        </div>
      </div>
      <Button type="submit" fullWidth large fetching={submitting}>
        <FormattedMessage {...messages.changePasswordButtonText} />
      </Button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  intl: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitError: PropTypes.string,
};

export default injectIntl(CSSModule(ResetPasswordForm, styles));
