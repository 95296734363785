import { defineMessages } from 'react-intl';

export const scope = 'cb.components.AccountMenu';

export default defineMessages({
  logoImageAlt: {
    id: `${scope}.logoImageAlt`,
    defaultMessage: 'Business logo',
  },
});
