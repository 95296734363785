import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

const ContentWrapper = ({ children, className, ...props }) => {
  return (
    <div {...props} className={cx(className, 'offset-md-2 col-md-8 col-sm-12')}>
      {children}
    </div>
  );
};

ContentWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
};

export default ContentWrapper;
