import { defineMessages } from 'react-intl';

export const scope = 'cb.components.CouponPreview';

export default defineMessages({
  blockHeadingText: {
    id: `${scope}.blockHeadingText`,
    defaultMessage: 'Coupon preview',
  },
  gstText: {
    id: `${scope}.gstText`,
    defaultMessage: 'gst',
  },
  pstText: {
    id: `${scope}.pstText`,
    defaultMessage: 'pst',
  },
  qstText: {
    id: `${scope}.qstText`,
    defaultMessage: 'qst',
  },
  rstText: {
    id: `${scope}.rstText`,
    defaultMessage: 'rst',
  },
  hstText: {
    id: `${scope}.hstText`,
    defaultMessage: 'hst',
  },
  pointsText: {
    id: `${scope}.pointsText`,
    defaultMessage: '{points, plural, one {# point} other {# points}}',
  },
  saveButtonText: {
    id: `${scope}.saveButtonText`,
    defaultMessage: 'Save as Draft',
  },
  publishButtonText: {
    id: `${scope}.publishButtonText`,
    defaultMessage: 'Publish',
  },
});
