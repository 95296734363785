import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: { current_plan: {}, has_plan_upgrade_requests: false },
  error: null,
};

const { Types, Creators } = createActions(
  {
    getMembershipInfo: [],
    getMembershipInfoRequest: [],
    getMembershipInfoSuccess: ['data'],
    getMembershipInfoFailure: ['error'],
  },
  { prefix: 'cb/memberships/info/' },
);

export { Types as types, Creators as actions };

export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_MEMBERSHIP_INFO_REQUEST]: request,
  [Types.GET_MEMBERSHIP_INFO_SUCCESS]: success,
  [Types.GET_MEMBERSHIP_INFO_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
