import { defineMessages } from 'react-intl';

export const scope = 'cb.components.Spinner';

export default defineMessages({
  loadingText: {
    id: `${scope}.loadingText`,
    defaultMessage: 'Loading...',
  },
});
