import { OK } from 'http-status-codes';
import { debounce, put, select, takeLatest } from 'redux-saga/effects';

import { SHORT_DEBOUNCE_TIME_MS } from '../../constants';
import { getPropFromNormalized } from '../../utils/redux';
import { extractParamsFromUrl } from '../../utils/saga';

import * as api from './api';
import * as duck from './duck';
import { actions, types } from './duck';

export function* getProvinces() {
  try {
    yield put(actions.getProvincesRequest());

    const response = yield api.getProvinces();

    if (response.status === OK) {
      yield put(actions.getProvincesSuccess(response.data));
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    yield put(actions.getProvincesFailure(error.message));
  }
}

export function* getProvincesNextPage() {
  try {
    const nextPageUrl = yield select(state =>
      getPropFromNormalized(state.locations, duck.provincesEntity.key, 'next'),
    );

    if (!nextPageUrl) {
      return;
    }

    const params = extractParamsFromUrl(nextPageUrl);

    yield put(actions.getProvincesNextPageRequest());

    const response = yield api.getProvinces(params);

    if (response.status === OK) {
      yield put(actions.getProvincesNextPageSuccess(response.data));
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    yield put(actions.getProvincesNextPageFailure(error.message));
  }
}

export function* getCities({ provinceName, name }) {
  try {
    yield put(actions.getCitiesRequest());

    const response = yield api.getCities({ province: provinceName, name });

    if (response.status === OK) {
      yield put(actions.getCitiesSuccess(response.data));
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    yield put(actions.getCitiesFailure(error.message));
  }
}

export function* getCitiesNextPage() {
  try {
    const nextPageUrl = yield select(state =>
      getPropFromNormalized(state.locations, duck.citiesEntity.key, 'next'),
    );

    if (!nextPageUrl) {
      return;
    }

    const params = extractParamsFromUrl(nextPageUrl);

    yield put(actions.getCitiesNextPageRequest());

    const response = yield api.getCities(params);

    if (response.status === OK) {
      yield put(actions.getCitiesNextPageSuccess(response.data));
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    yield put(actions.getCitiesNextPageFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_PROVINCES, getProvinces);
  yield debounce(
    SHORT_DEBOUNCE_TIME_MS,
    types.GET_PROVINCES_NEXT_PAGE,
    getProvincesNextPage,
  );
  yield takeLatest(types.GET_CITIES, getCities);
  yield debounce(
    SHORT_DEBOUNCE_TIME_MS,
    types.GET_CITIES_NEXT_PAGE,
    getCitiesNextPage,
  );
  yield debounce(SHORT_DEBOUNCE_TIME_MS, types.GET_FILTERED_CITIES, getCities);
}
