import React, { useEffect } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { animated, useTrail } from 'react-spring';
import { toast } from 'react-toastify';
import sharedMessages from 'sharedMessages';

import {
  AppWrapper,
  AppWrapperHeading,
  Button,
  Feedback,
  Spinner,
} from 'components';
import { AuthProtected } from 'containers';
import {
  DEFAULT_ANIMATION_OPACITY_VALUES,
  DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
} from 'coupon-common';
import { actions as membershipInfoActions } from 'coupon-common/src/modules/memberships/info';
import { actions as plansActions } from 'coupon-common/src/modules/memberships/plans';
import { actions as plansActivityActions } from 'coupon-common/src/modules/memberships/plansActivity';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import styles from './MembershipPlans.module.scss';
import MembershipPlansImg from './MembershipPlansImg.svg';
import messages from './messages';

export const PLAN_NAME_TO_STYLE_MAP = {
  Free: 'free-plan',
  Standard: 'standard-plan',
  Premium: 'premium-plan',
};

export const MembershipPlans = ({
  intl,
  dispatch,
  data,
  fetching,
  fetched,
  error,
  plansActivityActionsFetching,
  plansActivityActionsError,
  membershipInfoData,
  membershipInfoFetching,
  membershipInfoFetched,
  membershipInfoError,
}) => {
  useEffect(() => {
    dispatch(membershipInfoActions.getMembershipInfo());
    dispatch(plansActions.getMembershipPlans());
  }, [dispatch]);

  const membershipFetching =
    fetching || membershipInfoFetching || plansActivityActionsFetching;

  const membershipError =
    error || membershipInfoError || plansActivityActionsError;

  const { VISIBLE, INVISIBLE } = DEFAULT_ANIMATION_OPACITY_VALUES;

  const [trail, set] = useTrail(data.length, () => ({
    opacity: INVISIBLE,
    position: 'absolute',
  }));

  useEffect(() => {
    if (fetched && membershipInfoFetched) {
      set({ opacity: VISIBLE, position: 'relative' });
    }
  }, [VISIBLE, fetched, membershipInfoFetched, set]);

  const handleChoosePlanClick = async planId => {
    const { success } = await dispatchAsAsync(
      plansActivityActions.upgradeMembershipPlan(planId),
      dispatch,
    );
    if (success) {
      toast(intl.formatMessage(messages.changePlanNotificationText));
      dispatch(membershipInfoActions.getMembershipInfo());
    }
  };

  const renderBenefit = (benefit, benefitMessage, benefitMessageParams) => {
    return (
      !!benefit && (
        <>
          <p styleName="plan-benefits-separator">&bull;</p>
          <span className="text-muted">
            <FormattedMessage
              {...benefitMessage}
              values={benefitMessageParams}
            />
          </span>
        </>
      )
    );
  };

  const renderPlans = () => {
    return trail.map((props, index) => {
      const plan = data[index];
      if (!plan) {
        return undefined;
      }
      const isCurrentPlan =
        membershipInfoData.current_plan &&
        membershipInfoData.current_plan.id === plan.id;
      const planStyleName = PLAN_NAME_TO_STYLE_MAP[plan.name];
      return (
        <animated.div key={index} style={props} className="col-md-4 mb-4">
          <div className="h-100" styleName="membership-plan-block">
            <div
              styleName={cx('membership-plan-title-section', {
                [planStyleName]: !!planStyleName,
              })}
            >
              <h3 className="m-0 font-weight-normal">{plan.name}</h3>
            </div>
            <div styleName="membership-plan-content-section">
              <div className="mb-3">
                <h2 styleName="plan-price" className="m-0 mb-1">
                  {intl.formatNumber(
                    plan.price,
                    DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
                  )}
                </h2>
                <span className="text-muted">
                  <FormattedMessage {...messages.perMonthText} />
                </span>
              </div>
              {renderBenefit(plan.coupon_slots, messages.couponsCountText, {
                coupon_slots: plan.coupon_slots,
              })}
              {renderBenefit(
                plan.sponsorship_slots,
                messages.sponsoredCouponsCountText,
                {
                  sponsorship_slots: plan.sponsorship_slots,
                },
              )}
              {renderBenefit(
                plan.has_access_to_dashboard,
                messages.dashboardWithAnalyticsText,
              )}
              {renderBenefit(
                plan.make_business_sponsored,
                messages.sponsoredBusinessText,
              )}
            </div>
            <div styleName="choose-plan-btn-container">
              <Button
                styleName={cx({
                  'non-clickable-plan-btn': isCurrentPlan,
                  [planStyleName]: !!planStyleName,
                })}
                onClick={() => handleChoosePlanClick(plan.id)}
              >
                {isCurrentPlan
                  ? intl.formatMessage(messages.currentMembershipPlanButtonText)
                  : intl.formatMessage(messages.chooseMembershipPlanButtonText)}
              </Button>
            </div>
          </div>
        </animated.div>
      );
    });
  };

  return (
    <AppWrapper
      showHeader
      showSideNav
      showHeadingRow
      headingRowRender={() => (
        <>
          <AppWrapperHeading
            offsetLeft
            headingText={intl.formatMessage(
              sharedMessages.membershipPlansPageHeadingText,
            )}
          />
          <div className="col-4">
            <div className="row mt-2 mr-2 h-100 justify-content-end align-items-center">
              <Spinner show={membershipFetching} justify="end" />
              <Feedback
                invalid
                fullWidth={false}
                show={!membershipFetching && !!membershipError}
                content={membershipError}
              />
            </div>
          </div>
        </>
      )}
    >
      <AuthProtected />
      <div className="col-md-10">
        <div className="row">
          <div className="col-md-5 mt-3">
            <FormattedMessage
              tagName="h5"
              {...messages.chooseYourMembershipPlanText}
            />
            <p styleName="membership-plans-description">
              {intl.formatMessage(messages.membershipPlansDescriptionText)}
            </p>
          </div>
          <div className="col-md-7 d-flex justify-content-center">
            <img
              src={MembershipPlansImg}
              className="img-fluid"
              alt={intl.formatMessage(messages.membershipPlansImgAlt)}
            />
          </div>
        </div>
        <div styleName="membership-plans-section" className="row">
          {renderPlans()}
          {fetched &&
            membershipInfoFetched &&
            membershipInfoData.has_plan_upgrade_requests && (
              <p className="text-center col-12 mb-5">
                <FormattedMessage
                  {...messages.changePlanRequestProcessingText}
                />
              </p>
            )}
        </div>
      </div>
    </AppWrapper>
  );
};

MembershipPlans.propTypes = {
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  fetching: PropTypes.bool,
  fetched: PropTypes.bool,
  error: PropTypes.string,
  plansActivityActionsFetching: PropTypes.bool,
  plansActivityActionsError: PropTypes.string,
  membershipInfoData: PropTypes.object,
  membershipInfoFetching: PropTypes.bool,
  membershipInfoFetched: PropTypes.bool,
  membershipInfoError: PropTypes.string,
};

const mapStateToProps = state => ({
  fetching: state.memberships.plans.fetching,
  data: state.memberships.plans.data,
  fetched: state.memberships.plans.fetched,
  error: state.memberships.plans.error,

  plansActivityActionsFetching: state.memberships.plansActivity.fetching,
  plansActivityActionsError: state.memberships.plansActivity.error,

  membershipInfoData: state.memberships.info.data,
  membershipInfoFetching: state.memberships.info.fetching,
  membershipInfoFetched: state.memberships.info.fetched,
  membershipInfoError: state.memberships.info.error,
});

export default connect(mapStateToProps)(
  injectIntl(CSSModule(MembershipPlans, styles, { allowMultiple: true })),
);
