import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ProcessCoupon } from 'containers';
import { actions } from 'coupon-common/src/modules/usedCoupons/newUsedCoupons';

const NEW_USED_COUPONS_REQUEST_TIMEOUT_MS = 30000;

export const NewUsedCouponsProcessing = ({
  dispatch,
  authorized,
  data,
  isModalOpen,
  isCouponsActivityModalOpen,
}) => {
  const getNewUsedCoupons = useCallback(() => {
    if (!isCouponsActivityModalOpen && !isModalOpen) {
      dispatch(actions.getNewUsedCoupons());
    }
  }, [dispatch, isCouponsActivityModalOpen, isModalOpen]);

  useEffect(() => {
    getNewUsedCoupons();
    let timer;
    if (authorized) {
      timer = setInterval(() => {
        getNewUsedCoupons();
      }, NEW_USED_COUPONS_REQUEST_TIMEOUT_MS);
    }
    return () => {
      clearInterval(timer);
    };
  }, [authorized, getNewUsedCoupons]);

  return data.new_used_coupon_to_show && !isCouponsActivityModalOpen ? (
    <ProcessCoupon usedCouponData={data.new_used_coupon_to_show} isRootModal />
  ) : null;
};

NewUsedCouponsProcessing.propTypes = {
  dispatch: PropTypes.func,
  authorized: PropTypes.bool,
  data: PropTypes.object,
  isModalOpen: PropTypes.bool,
  isCouponsActivityModalOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  authorized: state.auth.authorized,
  data: state.usedCoupons.newUsedCoupons.data,
  isModalOpen: state.usedCoupons.newUsedCoupons.isModalOpen,
  isCouponsActivityModalOpen: state.usedCoupons.couponsActivity.isModalOpen,
});

export default connect(mapStateToProps)(NewUsedCouponsProcessing);
