import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import styles from './CloseButton.module.scss';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';

const CloseButton = ({ onClick }) => (
  // We use `onMouseDown` listener here
  // to prevent event conflicts in FormCustomSelect
  <div styleName="close-icon-wrapper">
    <CloseIcon onMouseDown={onClick} />
  </div>
);

CloseButton.propTypes = {
  onClick: PropTypes.func,
};

export default CSSModule(CloseButton, styles);
