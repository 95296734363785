import { apiCall } from '../../utils';

export const getProvinces = params =>
  apiCall({
    url: '/locations/provinces/',
    method: 'get',
    params,
  });

export const getCities = params =>
  apiCall({
    url: '/locations/cities/',
    method: 'get',
    params,
  });
