import { defineMessages } from 'react-intl';

export const scope = 'cb.components.CouponPriceDetails';

export default defineMessages({
  gstText: {
    id: `${scope}.gstText`,
    defaultMessage: 'gst {taxValue}',
  },
  pstText: {
    id: `${scope}.pstText`,
    defaultMessage: 'pst {taxValue}',
  },
  qstText: {
    id: `${scope}.qstText`,
    defaultMessage: 'qst {taxValue}',
  },
  rstText: {
    id: `${scope}.rstText`,
    defaultMessage: 'rst {taxValue}',
  },
  hstText: {
    id: `${scope}.hstText`,
    defaultMessage: 'hst {taxValue}',
  },
  amountOffText: {
    id: `${scope}.amountOffText`,
    defaultMessage: 'Amount off {amountOff}',
  },
  pointsText: {
    id: `${scope}.pointsText`,
    defaultMessage: '{points, plural, one {# point} other {# points}}',
  },
  discountText: {
    id: `${scope}.discountText`,
    defaultMessage: 'Discount {discount}',
  },
});
