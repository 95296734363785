import React from 'react';

import { Field, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button, Feedback, FormField } from 'components';
import {
  DEFAULT_MAX_PASSWORD_LENGTH,
  DEFAULT_MIN_PASSWORD_LENGTH,
  KEY_CODES,
} from 'coupon-common';
import {
  composeSyncValidators,
  messages as validatorMessages,
  required,
  stringMatchWithValue,
  validPassword,
} from 'coupon-common/src/utils';

import messages from './messages';

const validPasswordCustomMessage = message =>
  validPassword(undefined, undefined, message);

const ChangePasswordForm = ({
  intl,
  onCancel,
  handleSubmit,
  submitError,
  form,
}) => {
  const handleCancel = e => {
    e.preventDefault();
    onCancel();
  };

  const handleInputKeyDown = e => {
    if (e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault();
      form.submit();
    }
  };

  const incorrectPasswordErrorText = intl.formatMessage(
    messages.incorrectPasswordMessage,
  );

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleInputKeyDown}>
      <Field
        name="old_password"
        label={intl.formatMessage(messages.currentPasswordFieldLabelText)}
        type="password"
        validate={composeSyncValidators(
          required,
          validPasswordCustomMessage(incorrectPasswordErrorText),
        )}
        component={FormField}
      />
      <Field
        name="new_password"
        label={intl.formatMessage(messages.newPasswordFieldLabelText)}
        type="password"
        validate={composeSyncValidators(
          required,
          validPasswordCustomMessage(incorrectPasswordErrorText),
        )}
        component={FormField}
      />
      <FormSpy
        subscription={{ values: true }}
        render={({ values: { new_password } }) => (
          <Field
            name="confirm_new_password"
            label={intl.formatMessage(
              messages.comfirmNewPasswordFieldLabelText,
            )}
            type="password"
            validate={composeSyncValidators(
              required,
              validPasswordCustomMessage(incorrectPasswordErrorText),
              stringMatchWithValue(
                new_password,
                messages.passwordNotMatchMessage,
              ),
            )}
            showValid
            component={FormField}
          />
        )}
      />
      <p className="text-muted">
        <FormattedMessage
          {...validatorMessages.invalidPassowrdMessage}
          values={{
            minLengthVal: DEFAULT_MIN_PASSWORD_LENGTH,
            maxLengthVal: DEFAULT_MAX_PASSWORD_LENGTH,
          }}
        />
        .
      </p>
      <Feedback
        fullWidth
        textCenter
        show={!!submitError}
        content={submitError}
        invalid
      />
      <div className="row justify-content-center mt-5">
        <Button outline onClick={handleCancel}>
          <FormattedMessage {...messages.cancelButtonText} />
        </Button>
        <Button onClick={handleSubmit}>
          <FormattedMessage {...messages.saveButtonText} />
        </Button>
      </div>
    </form>
  );
};

export default injectIntl(ChangePasswordForm);
