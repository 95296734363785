import './AppWrapperHeading.module.scss';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

export const AppWrapperHeading = ({ headingText, offsetLeft }) => (
  <div className={cx('col', { 'offset-md-2': offsetLeft })}>
    <h3>{headingText}</h3>
  </div>
);

AppWrapperHeading.propTypes = {
  headingText: PropTypes.string,
  offsetLeft: PropTypes.bool,
};

export default AppWrapperHeading;
