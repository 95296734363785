import { createActions, createReducer } from 'reduxsauce';

export const getNowDateISO = () => new Date().toISOString();

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  createdAfter: getNowDateISO(),
  isModalOpen: false,
  error: null,
};

const { Types, Creators } = createActions(
  {
    getNewUsedCoupons: [],
    getNewUsedCouponsRequest: [],
    getNewUsedCouponsSuccess: ['data'],
    getNewUsedCouponsFailure: ['error'],

    openModal: [],
    closeModal: [],

    decreaseNewUsedCouponsCount: [],
  },
  { prefix: 'cb/usedCoupons/newUsedCoupons/' },
);

export { Types as types, Creators as actions };

export const request = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const success = (state, action) => {
  const { data } = action;
  return {
    ...state,
    fetching: false,
    fetched: true,
    error: null,
    createdAfter: getNowDateISO(),
    data,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    fetched: false,
    error,
  };
};

export const processModalState = isModalOpen => state => ({
  ...state,
  isModalOpen,
});

export const decreaseNewUsedCouponsCount = state => ({
  ...state,
  data: {
    ...state.data,
    ...(state.data.used_coupons_wait_for_action
      ? {
          used_coupons_wait_for_action:
            state.data.used_coupons_wait_for_action - 1,
        }
      : {}),
  },
});

export const HANDLERS = {
  [Types.GET_NEW_USED_COUPONS_REQUEST]: request,
  [Types.GET_NEW_USED_COUPONS_SUCCESS]: success,
  [Types.GET_NEW_USED_COUPONS_FAILURE]: failure,

  [Types.OPEN_MODAL]: processModalState(true),
  [Types.CLOSE_MODAL]: processModalState(false),

  [Types.DECREASE_NEW_USED_COUPONS_COUNT]: decreaseNewUsedCouponsCount,
};

export default createReducer(INITIAL_STATE, HANDLERS);
