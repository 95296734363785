import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export const AuthorizedRedirect = ({ authorized }) => {
  if (authorized) {
    return <Redirect to="/account" />;
  }
  return null;
};

AuthorizedRedirect.propTypes = {
  authorized: PropTypes.bool,
};

const mapStateToProps = state => ({
  authorized: state.auth.authorized,
});

export default connect(mapStateToProps)(AuthorizedRedirect);
