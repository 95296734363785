import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, Button, Feedback } from 'components';

import messages from './messages';
import ShareReferralCodeImg from './ShareReferralCodeImg.svg';

export const CODE_PLACEHOLDER = '_ _ _ - _ _ _';

export const ShareReferralCode = ({ intl, code }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Block>
      <div className="row">
        <div className="col-md-5">
          <FormattedMessage
            tagName="h5"
            {...messages.shareThisCodeBlockHeading}
          />
          <FormattedMessage tagName="p" {...messages.shareCodeHelpText} />
          <FormattedMessage tagName="p" {...messages.shareLinkHelpText} />
          <CopyToClipboard text={code} onCopy={text => text && setCopied(true)}>
            <Button outline={!code} className="mt-4" fullWidth>
              {code || CODE_PLACEHOLDER}
            </Button>
          </CopyToClipboard>
          <Feedback
            show={copied}
            content={intl.formatMessage(messages.codeWasCopiedFeedbackText)}
            className="mt-4"
            textCenter
          />
        </div>
        <div className="col-md-7 mt-3">
          <img
            src={ShareReferralCodeImg}
            className="img-fluid"
            alt={intl.formatMessage(messages.shareReferralCodeAlt)}
          />
        </div>
      </div>
    </Block>
  );
};

ShareReferralCode.propTypes = {
  intl: PropTypes.object,
  code: PropTypes.string,
};

export default injectIntl(ShareReferralCode);
