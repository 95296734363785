import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponDelete';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'Delete Coupon',
  },
  confirmBtnText: {
    id: `${scope}.confirmBtnText`,
    defaultMessage: 'Confirm',
  },
  cancelBtnText: {
    id: `${scope}.cancelBtnText`,
    defaultMessage: 'Cancel',
  },
  deleteCouponText: {
    id: `${scope}.deleteCouponText`,
    defaultMessage: 'Do you want to delete a <b>{couponTitle}</b> coupon?',
  },
  deleteCouponSuccessText: {
    id: `${scope}.deleteCouponSuccessText`,
    defaultMessage: 'Coupon {couponTitle} deleted',
  },
});
