import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { Spinner } from 'components';

import styles from './TextArea.module.scss';

export const TextArea = ({ loading, readOnly, ...props }) => {
  const styleNames = cx('main-textarea', { 'read-only': readOnly });
  return (
    <div className={cx('d-flex align-items-center')}>
      <textarea styleName={styleNames} readOnly={readOnly} {...props} />
      <Spinner show={loading} />
    </div>
  );
};

TextArea.propTypes = {
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
};
export default CSSModule(TextArea, styles, { allowMultiple: true });
