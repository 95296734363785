import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import styles from './Block.module.scss';

const Block = ({ children, className, fixedMinHeight, ...props }) => {
  return (
    <div
      styleName={cx('block', { 'fixed-min-height': fixedMinHeight })}
      className={cx(className)}
      {...props}
    >
      {children}
    </div>
  );
};

Block.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  fixedMinHeight: PropTypes.bool,
};

export default CSSModule(Block, styles, { allowMultiple: true });
