import { defineMessages } from 'react-intl';

export const scope = 'cb.components.ReceivedMonetaryUnitsStatistic';

export default defineMessages({
  amountReceivedBlockHeading: {
    id: `${scope}.amountReceivedBlockHeading`,
    defaultMessage: 'Amount received from customers',
  },
  pointsText: {
    id: `${scope}.pointsText`,
    defaultMessage: '{ points, plural, one { point } other { points } }',
  },
  dollarsText: {
    id: `${scope}.dollarsText`,
    defaultMessage: '{ dollars, plural, one { dollar } other { dollars } }',
  },
  chartLegendPointsText: {
    id: `${scope}.chartLegendPointsText`,
    defaultMessage: 'Points',
  },
  chartLegendDollarsText: {
    id: `${scope}.chartLegendDollarsText`,
    defaultMessage: 'Dollars',
  },
});
