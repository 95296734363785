import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AppWrapper,
  AppWrapperHeading,
  ContentWrapper,
  OnlyFormPageWrapper,
} from 'components';
import { AuthorizedRedirect, ReferralCodeForm, SignUpForm } from 'containers';

import signUpFormMessages from '../SignUpForm/messages';

export const SignUp = ({ intl, match: { path }, history }) => {
  const goToReferralForm = () => {
    history.push(path + '/referral-code');
  };

  const goToForm = () => {
    history.push(path + '/form');
  };

  const goToMyBusinessAccount = () => {
    history.push('/account');
  };

  return (
    <Switch>
      <Route exact path={path + '/referral-code'}>
        <AppWrapper showHeader={false} bgLight contentCentered>
          <OnlyFormPageWrapper>
            <AuthorizedRedirect />
            <ReferralCodeForm onSubmitSuccess={goToForm} />
          </OnlyFormPageWrapper>
        </AppWrapper>
      </Route>
      <Route exact path={path + '/form'}>
        <AppWrapper
          showHeader
          showHeadingRow
          headingRowRender={() => (
            <AppWrapperHeading
              offsetLeft
              headingText={intl.formatMessage(signUpFormMessages.formHeading)}
            />
          )}
        >
          <ContentWrapper>
            <SignUpForm
              onSubmitSuccess={goToMyBusinessAccount}
              referralFormRedirect={goToReferralForm}
            />
          </ContentWrapper>
        </AppWrapper>
      </Route>
      <Route
        path="*"
        render={() => <Redirect to={path + '/referral-code'} />}
      />
    </Switch>
  );
};

SignUp.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default injectIntl(SignUp);
