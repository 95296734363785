import React, { useMemo, useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  COUPON_EXPIRY_TYPES,
  COUPON_IMAGES_FIELD_NAME_PREFIX,
  COUPON_LIST_DATE_FORMAT_PATTERN,
  COUPON_STATES,
  COUPON_TEMPLATE_DATE_FORMAT_PATTERN,
  COUPON_TYPES,
} from 'coupon-common';
import { parseISOString } from 'coupon-common/src/utils';

import styles from './Coupon.module.scss';
import {
  BlockedIcon,
  // Clock,
  DraftIcon,
  ExpiredIcon,
  PublishedIcon,
  HeartBlackIcon,
  InfinityBlackIcon,
} from './icons';
import ImageSlider from '../ImageSlider/ImageSlider';
import messages from './messages.js';

const DEFAULT_COUPON_EXPIRY_VALUE = 'xxx';

export const Coupon = ({
  intl,
  data,
  businessData: { logo, name },
  templateMode,
  previewMode
}) => {
  const {
    title,
    // payment_by_points,
    type,
    amount_off,
    points_back,
    percentage_off,
    // description,
    // views,
    expiry_type,
    expiration_quantity,
    expiration_date,
    expiry,
    published_at,
    state,
    is_sponsored,
    is_blocked,
    user_limit,
  } = data;
  const [couponImages, setCouponImages] = useState([]);

  const isCouponPublished = state === COUPON_STATES.PUBLISHED && !is_blocked;
  const isPreviewMode = !templateMode && (!state || previewMode);

  useEffect(() => {
    let coupon_images = [];
    Object.keys(data)
      .sort()
      .forEach(key => {
        if (key.includes(COUPON_IMAGES_FIELD_NAME_PREFIX) && data[key]) {
          coupon_images.push(data[key]);
        }
      });

    if (coupon_images.length === 0 && data.hasOwnProperty('image_url')) {
      coupon_images = data['image_url']
        .sort((a, b) => a.sequence - b.sequence)
        .map(image => image.image_url);
    }

    setCouponImages(coupon_images);
  }, [data]);

  const couponStatusIconContent = useMemo(() => {
    let couponStatusIcon;
    let couponStatusTooltipMsg;
    switch (true) {
      case is_blocked:
        couponStatusIcon = BlockedIcon;
        couponStatusTooltipMsg = messages.blockedIconTooltip;
        break;
      case state === COUPON_STATES.PUBLISHED:
        couponStatusIcon = PublishedIcon;
        couponStatusTooltipMsg = messages.publishedIconTooltip;
        break;
      case state === COUPON_STATES.DRAFT:
        couponStatusIcon = DraftIcon;
        couponStatusTooltipMsg = messages.draftIconTooltip;
        break;
      case state === COUPON_STATES.EXPIRED:
        couponStatusIcon = ExpiredIcon;
        couponStatusTooltipMsg = messages.expiredIconTooltip;
        break;
      default:
        couponStatusIcon = null;
        couponStatusTooltipMsg = null;
    }

    return (
      couponStatusTooltipMsg &&
      couponStatusIcon && (
        <div
          styleName="coupon-status-icon"
          className="d-flex justify-content-end align-items-center flex-shrink-1"
        >
          <img
            src={couponStatusIcon}
            alt={intl.formatMessage(messages.couponStateAlt)}
          />
          <span styleName="tooltip-text">
            <FormattedMessage {...couponStatusTooltipMsg} />
          </span>
        </div>
      )
    );
  }, [intl, is_blocked, state]);

  const couponTypeContent = useMemo(() => {
    let content;
    if (type === COUPON_TYPES.AMOUNT_OFF) {
      content = (
        <FormattedMessage {...messages.amountOffText} values={{ amount_off }} />
      );
    }
    if (type === COUPON_TYPES.POINTS_BACK) {
      content = (
        <FormattedMessage
          {...messages.pointsBackText}
          values={{ points_back }}
        />
      );
    }
    if (type === COUPON_TYPES.PERCENTAGE_OFF) {
      content = (
        <FormattedMessage
          {...messages.percentageOffText}
          values={{ percentage_off }}
        />
      );
    }
    return content; //&& <h3 className="m-0 mr-4">{content}</h3>;
  }, [type, amount_off, points_back, percentage_off]);

  const couponExpiryContent = useMemo(() => {
    if (expiry_type === COUPON_EXPIRY_TYPES.AMOUNT_LIMIT) {
      const messageForExpiryTypeValue = templateMode
        ? messages.amountLimitText
        : messages.amountText;
      const templateExpirationQuantity =
        expiration_quantity || DEFAULT_COUPON_EXPIRY_VALUE;
      let expirationQuantity =
        expiry && !templateMode ? expiry : templateExpirationQuantity;
      expirationQuantity =
        isPreviewMode && templateExpirationQuantity
          ? templateExpirationQuantity
          : expirationQuantity;
      return (
        <>
          <span className="pl-0 " styleName="usage-info-section-text">
            <FormattedMessage {...messageForExpiryTypeValue} />
          </span>
          <span styleName="usage-info-section-value">{expirationQuantity}</span>
        </>
      );
    }
    if (expiry_type === COUPON_EXPIRY_TYPES.DATE) {
      const templateExpirationValue = expiration_date
        ? intl.formatDate(
            parseISOString(expiration_date),
            COUPON_TEMPLATE_DATE_FORMAT_PATTERN,
          )
        : DEFAULT_COUPON_EXPIRY_VALUE;
      let expirationValue =
        expiry && !templateMode
          ? intl.formatDate(
              parseISOString(expiry),
              COUPON_TEMPLATE_DATE_FORMAT_PATTERN,
            )
          : templateExpirationValue;
      expirationValue =
        isPreviewMode && expiration_date
          ? templateExpirationValue
          : expirationValue;
      return (
        <>
          <span className="pl-0" styleName="usage-info-section-text">
            <FormattedMessage {...messages.expiryTypeUntilText} />
            &nbsp;
            {expirationValue}
          </span>
          {/* <img alt={intl.formatMessage(messages.clockImgAltText)} src={Clock} /> */}
        </>
      );
    }
    return null;
  }, [
    expiry_type,
    templateMode,
    expiration_quantity,
    expiration_date,
    intl,
    expiry,
  ]);

  const couponDateContent = useMemo(() => {
    if (
      state === COUPON_STATES.EXPIRED &&
      expiry_type === COUPON_EXPIRY_TYPES.DATE &&
      expiry
    ) {
      const formattedExpirationDate = intl.formatDate(
        expiry,
        COUPON_LIST_DATE_FORMAT_PATTERN,
      );
      const expirationDateLabel = intl.formatMessage(
        messages.expirationDateLabel,
      );
      return `${expirationDateLabel} ${formattedExpirationDate}`;
    }
    if (state === COUPON_STATES.EXPIRED) {
      return intl.formatMessage(messages.couponExpiredText);
    }
    if (published_at) {
      const formattedPublishedAt = intl.formatDate(
        published_at,
        COUPON_LIST_DATE_FORMAT_PATTERN,
      );
      const publidhedAtLabel = intl.formatMessage(messages.publishedDateLabel);
      return `${publidhedAtLabel} ${formattedPublishedAt}`;
    }
    if (state === COUPON_STATES.DRAFT) {
      return intl.formatMessage(messages.draftCouponLabel);
    }
    return intl.formatMessage(messages.publishedDateDefaultText);
  }, [expiry, expiry_type, intl, published_at, state]);

  return (
    <div
      styleName={cx('coupon-template-block', {
        'published-coupon-block': isCouponPublished || isPreviewMode,
      })}
    >
      <div
        className="d-flex flex-column justify-content-between"
        styleName={'coupon-details-block'}
      >
        {couponImages.length > 0 && (
          <div styleName="image-slider-container">
            <ImageSlider
              images={couponImages}
              showNavs={true}
              showBullets={true}
            />
          </div>
        )}

        <div className="d-flex justify-content-between" style={{ zIndex: 1 }}>
          <div
            styleName={' icon-button-block top-left-icon-button'}
            className="d-flex justify-content-center align-items-center"
          >
            {user_limit && user_limit > 0 && <span>{`x${user_limit}`}</span>}
            {!user_limit && <img src={InfinityBlackIcon} alt="Infinite" />}
          </div>
          <div
            styleName={cx(
              { 'sponsored-block sponser-text-block': is_sponsored },
              { 'icon-button-block': !is_sponsored },
            )}
            className="d-flex justify-content-center align-items-center"
          >
            {is_sponsored ? (
              <FormattedMessage tagName="span" {...messages.sponsoredText} />
            ) : (
              <img src={HeartBlackIcon} alt="like button" />
            )}
          </div>
        </div>

        <div styleName="coupon-template-content" style={{ zIndex: 1 }}>
          <h2 styleName="title-section">
            {templateMode && !title ? (
              <FormattedMessage {...messages.couponTitleDefaultText} />
            ) : (
              title
            )}
          </h2>
          {/* <div
          styleName="coupon-type-section"
          className="d-flex justify-content-between flex-wrap"
        >
          {couponTypeContent}
          <div className="d-flex align-content-center flex-wrap">
            {payment_by_points && (
              <span styleName="payment-type-section">
                <FormattedMessage {...messages.paymentByPointsText} />
              </span>
            )}
          </div>
        </div> */}
          {/* <div styleName={cx('description-section', { bordered: templateMode })}>
          <span>
            {templateMode && !description ? (
              <FormattedMessage {...messages.couponDescriptionDefaultText} />
            ) : (
              description
            )}
          </span>
        </div> */}
          <div className="d-flex justify-content-between flex-wrap">
            <div
              styleName="usage-info-section"
              className="d-flex align-items-center flex-wrap"
            >
              {/* <span styleName="usage-info-section-value">{views || 0}</span> */}
              <span styleName="usage-info-section-text">
                {couponTypeContent}
                {/* <FormattedMessage {...messages.viewsText} /> */}
              </span>
            </div>
            <div
              styleName="usage-info-section"
              className="d-flex align-items-center flex-wrap"
            >
              {couponExpiryContent}
            </div>
          </div>
        </div>
      </div>
      <div styleName="business-info-section" className="d-flex">
        <div className="d-flex justify-content-left w-100">
          <img
            styleName="business-logo"
            src={logo}
            alt={intl.formatMessage(messages.businessLogoAlt)}
          />
          <div className="d-flex flex-column">
            <span className="ml-3" styleName="company-name">
              {name}
            </span>
            <span className="ml-3" styleName="published-date">
              {couponDateContent}
            </span>
          </div>
        </div>
        {!templateMode && state && couponStatusIconContent}
      </div>
    </div>
  );
};

Coupon.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
  // data: PropTypes.shape({
  //   title: PropTypes.string,
  //   payment_by_points: PropTypes.bool,
  //   type: PropTypes.string,
  //   typeValue: PropTypes.string,
  //   description: PropTypes.string,
  //   views: PropTypes.number,
  //   expiry_type: PropTypes.string,
  //   expiration_quantity: PropTypes.number,
  //   expiration_date: PropTypes.string,
  //   published_at: PropTypes.string,
  //   state: PropTypes.string,
  //   is_sponsored: PropTypes.bool,
  //   points_back: PropTypes.number,
  //   is_blocked: PropTypes.bool,
  //   user_limit: PropTypes.number,
  // }).isRequired,
  businessData: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  templateMode: PropTypes.bool,
};

export default injectIntl(CSSModule(Coupon, styles, { allowMultiple: true }));
