import React, { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import Skeleton from 'react-loading-skeleton';
import useResizeAware from 'react-resize-aware';
import { animated, useSpring } from 'react-spring';

import { FormFieldWrapper, Input } from 'components';
import { EMPTY_INPUT_VAlUE, KEY_CODES } from 'coupon-common';

import styles from './FormCustomSelect.module.scss';

const NO_VAlUE = null;
const SKELETON_ROW_HEIGHT = 20;
const DEFAULT_PAGE_SIZE = 100;
const OPEN_ANIMATION_DURATION_MS = 150;

const FormCustomSelect = ({
  label,
  placeholder,
  input: { value, onChange, onBlur, onFocus },
  meta,
  readOnly,
  options,
  fetching,
  hasMore,
  fetchMore,
  pageSize,
  onSearch,
  externalError,
  getOptionValue,
  getOptionLabel,
  getKeyFromOption,
}) => {
  const [inputValue, setInputValue] = useState(EMPTY_INPUT_VAlUE);

  const changeInputValue = value => {
    if (inputValue !== value) {
      if (onSearch) {
        onSearch(value);
      }
      setInputValue(value);
    }
  };

  const onInputValueChange = ({ target: { value } }) => {
    if (!value) {
      onChange(NO_VAlUE);
    }
    changeInputValue(value);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    // We should not open menu in readonly mode;
    if (!readOnly) {
      setMenuOpen(true);
    }
  };

  const closeMenu = () => {
    changeInputValue(EMPTY_INPUT_VAlUE);
    dropSelection();
    scrollToTop();
    setMenuOpen(false);
  };

  const onInputFocus = () => {
    if (!readOnly) {
      onFocus();
    }
    openMenu();
  };

  const onInputBlur = () => {
    if (!readOnly) {
      onBlur();
    }
    closeMenu();
  };

  const changeValue = option => {
    onChange(option);
  };

  // Animation triggering on menu opened/closed state.
  const [resizeListener, sizes] = useResizeAware();
  const heightProps = useSpring({
    config: { duration: OPEN_ANIMATION_DURATION_MS },
    height: (menuOpen && sizes.height) || 0,
  });

  // Scroll to element with listBottomElRef handler
  // If this element appeaing on viewport
  // and component is not in fetched state - it will run fetchMore
  const observer = useRef();
  const listBottomElRef = useCallback(
    node => {
      if (fetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && fetchMore) {
          fetchMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetchMore, fetching],
  );

  // Automatic scroll following to selected element
  // It uses center following position to support
  // fetchMore on scroll functionality.
  const listContainer = useRef();
  const selectedElement = useRef();
  const selectedElementRef = useCallback(node => {
    if (node) {
      const listScrollTop = listContainer.current.scrollTop;
      const listHeight = listContainer.current.offsetHeight;
      const listCenter = listHeight / 2;

      const nodeOffsetTop = node.offsetTop;
      const nodeOffsetHeight = node.offsetHeight;

      const nodeBehindCenter = nodeOffsetTop < listScrollTop + listCenter;
      const nodeOutsideCenter =
        nodeOffsetTop + nodeOffsetHeight > listScrollTop + listCenter;

      const scrollShouldFollow = nodeBehindCenter || nodeOutsideCenter;

      if (scrollShouldFollow) {
        listContainer.current.scrollTo(
          0,
          nodeOffsetTop - listCenter + nodeOffsetHeight,
        );
      }
    }

    selectedElement.current = node;
  }, []);

  const getRefForOption = index => {
    return isIndexSelected(index) ? selectedElementRef : undefined;
  };

  const scrollToTop = () => {
    if (listContainer.current) {
      listContainer.current.scrollTo(0, 0);
    }
  };

  const [bufferedOptions, setBufferedOptions] = useState(options);

  // Search for options coincidence of the option label and inputValue
  const getFiltered = useCallback(
    receivedOptions =>
      receivedOptions.filter(
        option =>
          ~getOptionLabel(option)
            .toUpperCase()
            .indexOf(inputValue.toUpperCase()),
      ),
    [getOptionLabel, inputValue],
  );

  useEffect(() => {
    setBufferedOptions(!!onSearch ? options : getFiltered(options));
  }, [getFiltered, onSearch, options, setBufferedOptions]);

  // Selection from keyboard
  const [selectedOptionIndex, setSelectedOptionIndex] = useState();

  const isIndexSelected = useCallback(index => index === selectedOptionIndex, [
    selectedOptionIndex,
  ]);

  // If nothing was selected - select 0 index,
  // or increment selectedOptionIndex.
  const selectNextOption = () => {
    if (typeof selectedOptionIndex === 'undefined') {
      setSelectedOptionIndex(0);
    } else if (selectedOptionIndex < bufferedOptions.length - 1) {
      setSelectedOptionIndex(selectedOptionIndex + 1);
    }
  };

  const selectPrevOption = () => {
    if (selectedOptionIndex > 0) {
      setSelectedOptionIndex(selectedOptionIndex - 1);
    }
  };

  const dropSelection = () => setSelectedOptionIndex();

  // Listening events on Input with open menu
  const onInputKeyDown = e => {
    if (!menuOpen) {
      return;
    }

    if (e.keyCode === KEY_CODES.ARROW_DOWN) {
      selectNextOption();
    } else if (e.keyCode === KEY_CODES.ARROW_UP) {
      selectPrevOption();
    } else if (e.keyCode === KEY_CODES.ENTER) {
      changeValue(bufferedOptions[selectedOptionIndex]);
      e.target.blur();
    }
  };

  const isOptionActive = option => {
    return getOptionValue(option) === getOptionValue(value);
  };

  // Display selected option label when:
  // - menu closed
  // - menu opened and inputValue state is initial
  //
  // Display inputValue state when:
  // - menu closed and option does not selected
  // - menu opened and option does not selected
  // - menu opened, option is selected, inputValue state is NOT initial.
  const getInputValue = () => {
    const optionLabel = getOptionLabel(value);

    return optionLabel && (!menuOpen || !inputValue) ? optionLabel : inputValue;
  };

  // Next page rows stub for better UX,
  // also used as scroll to bottom trigger to request next page.
  const renderNextPageSkeleton = () =>
    hasMore &&
    menuOpen &&
    !externalError && (
      <div className="ml-3 mr-3" ref={listBottomElRef}>
        <Skeleton count={pageSize} height={SKELETON_ROW_HEIGHT} />
      </div>
    );

  const shouldDisplayError = !!(
    externalError ||
    ((meta.error || meta.submitError) && meta.touched)
  );
  const error = externalError || meta.error || meta.submitError;

  const onOptionListClick = e => {
    const optionIndex = e.target.dataset.index;
    if (optionIndex) {
      changeValue(bufferedOptions[optionIndex]);
    }
  };

  return (
    <FormFieldWrapper invalid={shouldDisplayError} label={label} error={error}>
      <div styleName="select-container">
        <Input
          placeholder={placeholder}
          onChange={onInputValueChange}
          onFocus={onInputFocus}
          onKeyDown={onInputKeyDown}
          onBlur={onInputBlur}
          loading={fetching}
          readOnly={readOnly}
          invalid={shouldDisplayError}
          closedSelect={!menuOpen}
          clearable={menuOpen && value}
          value={getInputValue()}
        />
        {menuOpen && (
          <animated.div style={heightProps} styleName="dropdown-container">
            <div
              styleName="options-list"
              ref={listContainer}
              onMouseDown={onOptionListClick}
            >
              {resizeListener}
              {bufferedOptions.map((option, index) => (
                <div
                  data-index={index}
                  key={getKeyFromOption(option)}
                  ref={getRefForOption(index)}
                  styleName={cx('option', {
                    selected: isIndexSelected(index),
                    active: isOptionActive(option),
                  })}
                >
                  {getOptionLabel(option)}
                </div>
              ))}
              {renderNextPageSkeleton()}
            </div>
          </animated.div>
        )}
      </div>
    </FormFieldWrapper>
  );
};

const getId = option => option.id;

FormCustomSelect.defaultProps = {
  getOptionValue: getId,
  getOptionLabel: option => option.name,
  getKeyFromOption: getId,
  pageSize: DEFAULT_PAGE_SIZE,
};

FormCustomSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  hasMore: PropTypes.bool,
  fetching: PropTypes.bool,
  fetchMore: PropTypes.func,
  pageSize: PropTypes.number,
  onSearch: PropTypes.func,
  externalError: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getKeyFromOption: PropTypes.func,
};

export default CSSModule(FormCustomSelect, styles, { allowMultiple: true });
