import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button, Coupon, CouponPriceDetails, Modal } from 'components';
import { COUPON_STATES } from 'coupon-common';

import styles from './CouponPreview.module.scss';
import messages from './messages';

export const CouponPreview = ({
  intl,
  data,
  businessData,
  isOpen,
  closePreviewHandler,
  saveCouponHandler,
  form,
}) => {
  const handleSaveButtonClick = async (saveAsPublished = false) => {
    const initialState = data.state;
    // cange form data for save coupon with published state if published button clicked
    form.change(
      'state',
      saveAsPublished ? COUPON_STATES.PUBLISHED : COUPON_STATES.DRAFT,
    );
    const submitErrors = await saveCouponHandler();
    if (submitErrors) {
      form.change('state', initialState);
    }
  };

  return (
    <Modal
      title={intl.formatMessage(messages.blockHeadingText)}
      isOpen={isOpen}
      onRequestClose={closePreviewHandler}
    >
      <div styleName="coupon-preview-modal-container">
        <Coupon data={data} businessData={businessData} previewMode={true}/>
        <div styleName="coupon-price-details" className="text-right mt-3">
          {data.show_price_on_coupon && (
            <>
              <CouponPriceDetails data={data} /> <hr className="mb-0" />
            </>
          )}

          <Button onClick={() => handleSaveButtonClick()} outline>
            <FormattedMessage {...messages.saveButtonText} />
          </Button>
          <Button
            onClick={() => {
              handleSaveButtonClick(true);
            }}
            className="mr-0"
          >
            <FormattedMessage {...messages.publishButtonText} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CouponPreview.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.shape({
    payment_by_points: PropTypes.bool,
    price: PropTypes.string,
    gst: PropTypes.string,
    pst: PropTypes.string,
    qst: PropTypes.string,
    rst: PropTypes.string,
    hst: PropTypes.string,
    is_exclusive_paid: PropTypes.bool,
    is_exclusive_followers: PropTypes.bool,
  }).isRequired,
  businessData: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  closePreviewHandler: PropTypes.func,
  saveCouponHandler: PropTypes.func,
  form: PropTypes.object,
};

export default injectIntl(CSSModule(CouponPreview, styles), messages);
