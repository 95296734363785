import React from 'react';

import { Link } from 'react-router-dom';

const host = window.location.host;

const POLICY_LINK_HREF = '/privacy-policy';
const POLICY_LINK_TEXT = host + POLICY_LINK_HREF;

const BUSINESS_TERMS_LINK_HREF = '/terms-of-service/business';
const BUSINESS_TERMS_LINK_TEXT = host + BUSINESS_TERMS_LINK_HREF;

const CUSTOMER_TERMS_LINK_HREF = '/terms-of-service/customer';
const CUSTOMER_TERMS_LINK_TEXT = host + CUSTOMER_TERMS_LINK_HREF;

export const formatterContextValues = {
  heading: msg => (
    <p className="mt-4 mb-4">
      <b>{msg}</b>
    </p>
  ),
  centeredHeading: msg => (
    <p className="mt-4 mb-4 text-center">
      <b>{msg}</b>
    </p>
  ),
  headingSpaced: msg => <span className="mr-4">{msg}</span>,
  bold: msg => <b>{msg}</b>,
  italic: msg => <i>{msg}</i>,
  paragraph: msg => <p className="text-justify">{msg}</p>,
  list: content => <ol type="a">{content}</ol>,
  /* Wrapping with `DocumentWrapper` required for value below. */
  listAlt: content => (
    <ol type="a" className="parentheses">
      {content}
    </ol>
  ),
  item: msg => <li>{msg}</li>,
  privacyPolicyLink: <Link to={POLICY_LINK_HREF}>{POLICY_LINK_TEXT}</Link>,
  businessTermsLink: (
    <Link to={BUSINESS_TERMS_LINK_HREF}>{BUSINESS_TERMS_LINK_TEXT}</Link>
  ),
  customerTermsLink: (
    <Link to={CUSTOMER_TERMS_LINK_HREF}>{CUSTOMER_TERMS_LINK_TEXT}</Link>
  ),
};
