import { defineMessages } from 'react-intl';

export const scope = 'cb.components.CouponRepublish';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'Republish Coupon',
  },
  expiryTypeAmountLimitLabel: {
    id: `${scope}.expiryTypeAmountLimitLabel`,
    defaultMessage: 'Amount limit',
  },
  expiryTypeDateLabel: {
    id: `${scope}.expiryTypeDateLabel`,
    defaultMessage: 'Date',
  },
  expirationQuantityFieldLabel: {
    id: `${scope}.expirationQuantityFieldLabel`,
    defaultMessage: 'Specify quantity of coupons',
  },
  expirationDateFieldLabel: {
    id: `${scope}.expirationDateFieldLabel`,
    defaultMessage: 'Specify expiration date',
  },
  republishButtonText: {
    id: `${scope}.republishButtonText`,
    defaultMessage: 'Republish',
  },
  couponTypeFieldsGroupLabel: {
    id: `${scope}.couponTypeFieldsGroupLabel`,
    defaultMessage: 'Specify coupon type',
  },
  couponRepublishSuccessText: {
    id: `${scope}.couponRepublishSuccessText`,
    defaultMessage: 'Coupon {title} republished',
  },
});
