import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.BusinessLogosFieldGroup';

export default defineMessages({
  blockHeading: {
    id: `${scope}.blockHeading`,
    defaultMessage: 'Business Logos',
  },
  businessLogoImageFieldText: {
    id: `${scope}.businessLogoImageFieldText`,
    defaultMessage: 'Business logo',
  },
  businessHedingImageFieldText: {
    id: `${scope}.businessHedingImageFieldText`,
    defaultMessage: 'Upload image that show your business at first sight',
  },
  businessHedingImageFieldDescriptionText: {
    id: `${scope}.businessHedingImageFieldDescriptionText`,
    defaultMessage: 'Cover image that shows your business at first sight',
  },
});
