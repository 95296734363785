import { MOTH_YEAR_CARD_SPLITTER, SPACE_REGEX } from '../constants';

export const formatToISOString = date => {
  if (Date.parse(date)) {
    if (date.getFullYear() > 2100) date.setFullYear(new Date().getFullYear());
    return date.toISOString();
  }
  return null;
};

export const parseISOString = string => {
  const parsedDate = Date.parse(string);
  return parsedDate && new Date(parsedDate);
};

export const getFullYearPrefix = () =>
  `${new Date().getFullYear()}`.slice(0, 2);

export const parseCardMonthYear = monthYear => {
  const [expMonth, expYear] = monthYear
    .replace(SPACE_REGEX, '')
    .split(MOTH_YEAR_CARD_SPLITTER);
  const fullYear = getFullYearPrefix() + expYear;

  return [expMonth, fullYear];
};

export const trimSpaces = string => string.trim();
