import { defineMessages } from 'react-intl';

const scope = 'cb.containers.MyCoupons';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'My coupons',
  },
  couponPreviewLabel: {
    id: `${scope}.couponPreviewLabel`,
    defaultMessage: 'Coupon preview',
  },
  categoryLabel: {
    id: `${scope}.categoryLabel`,
    defaultMessage: 'Category:',
  },
  publishCouponButtonLabel: {
    id: `${scope}.publishCouponButtonLabel`,
    defaultMessage: 'Publish',
  },
  editCouponButtonLabel: {
    id: `${scope}.editCouponButtonLabel`,
    defaultMessage: 'Edit',
  },
  deleteCouponButtonLabel: {
    id: `${scope}.deleteCouponButtonLabel`,
    defaultMessage: 'Delete',
  },
  sponsorCouponButtonLabel: {
    id: `${scope}.sponsorCouponButtonLabel`,
    defaultMessage: 'Sponsor',
  },
  republishCouponButtonLabel: {
    id: `${scope}.republishCouponButtonLabel`,
    defaultMessage: 'Republish',
  },
  publishCouponSuccessText: {
    id: `${scope}.publishCouponSuccessText`,
    defaultMessage: 'Coupon {couponTitle} published',
  },
  couponsEntity: {
    id: `${scope}.couponsEntity`,
    defaultMessage: 'coupons',
  },
  viewsText: {
    id: `${scope}.viewsText`,
    defaultMessage: 'Views',
  },
  likesText: {
    id: `${scope}.likesText`,
    defaultMessage: 'Likes',
  },
});
