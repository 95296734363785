import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import {
  DealerAgreement,
  GroupProtected,
  Landing,
  PrivacyPolicy,
  ScrollToTop,
  TermsOfService,
} from 'components';
import {
  CouponBuilder,
  Dashboard,
  ForgotPassword,
  LanguageProvider,
  Login,
  MembershipPlans,
  MyBusinessAccount,
  MyCoupons,
  NewUsedCouponsProcessing,
  ReferralProgram,
  SignUp,
  ThemeProvider,
  UsedCoupons,
} from 'containers';

export const App = ({
  messages,
  hasDashboardAccess,
  hasDashboardAccessFetching,
}) => {
  return (
    <LanguageProvider messages={messages}>
      <ThemeProvider>
        <NewUsedCouponsProcessing />
        <ToastContainer hideProgressBar transition={Slide} />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/account" component={MyBusinessAccount} />
          <Route
            exact
            path="/dashboard"
            render={() => (
              <GroupProtected
                hasAccess={hasDashboardAccess}
                hasAccessDataFetching={hasDashboardAccessFetching}
              >
                <Dashboard />
              </GroupProtected>
            )}
          />
          <Route exact path="/referral-program" component={ReferralProgram} />
          <Route exact path="/membership-plans" component={MembershipPlans} />
          <Route exact path="/coupons/create" component={CouponBuilder} />
          <Route exact path="/coupons" component={MyCoupons} />
          <Route exact path="/used-coupons" component={UsedCoupons} />
          <Route path="/coupons/:id" component={CouponBuilder} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/dealer-agreement" component={DealerAgreement} />
          <Route path="/terms-of-service/:variant" component={TermsOfService} />
          <Route path="*" render={() => <h2>Not found</h2>} />
        </Switch>
      </ThemeProvider>
    </LanguageProvider>
  );
};

App.propTypes = {
  messages: PropTypes.object,
  hasDashboardAccess: PropTypes.bool,
  hasDashboardAccessFetching: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasDashboardAccess:
    state.businessReferralProgram.data.has_access_to_dashboard,
  hasDashboardAccessFetching: state.businessReferralProgram.fetching,
});

export default connect(mapStateToProps)(App);
