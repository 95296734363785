import './FormGeoField.module.scss';

import React, { useState } from 'react';

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button, Feedback, Modal, Spinner } from 'components';

import messages from './messages';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
/*
 Localization will be hardcoded here until full internationalization
 support with definition of supported languages.
 Note that this code includes only the main language
 tag without any secondary tag indicating country or region.
 */
const DEFAULT_MAP_LOCALE = 'en';
const DEFAULT_MAP_ZOOM = 8;
/* It's  Ottawa */
const DEFAULT_CENTER_CORDINATES = {
  lat: 45.37538374672719,
  lng: -75.70168708178008,
};

const FormGeoField = ({
  intl,
  keyword,
  input: { value, onChange },
  meta,
  readOnly,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    language: DEFAULT_MAP_LOCALE,
  });
  const [openPickerModal, setOpenPickerModal] = useState(false);
  const [point, setPoint] = useState(null);
  const [center, setCenter] = useState(DEFAULT_CENTER_CORDINATES);

  const renderButton = () => {
    const buttonText = value
      ? intl.formatMessage(messages.changeLocationButtonText, { keyword })
      : intl.formatMessage(messages.selectLocationButtonText, { keyword });
    const buttonHandler = e => {
      e.preventDefault();
      if (value) {
        setPoint(value);
        setCenter(value);
      }
      setOpenPickerModal(true);
    };

    if (readOnly) {
      return null;
    }

    return (
      <div className="d-flex justify-content-center mt-3">
        <Button outline onClick={buttonHandler}>
          {buttonText}
        </Button>
      </div>
    );
  };

  const renderPreviewMap = () => {
    if (value) {
      return (
        <div className="mt-4">
          <GoogleMap
            mapContainerClassName="map-container"
            clickableIcons={false}
            zoom={DEFAULT_MAP_ZOOM}
            center={value}
            options={{
              disableDoubleClickZoom: true,
              disableDefaultUI: true,
            }}
          >
            <Marker position={value} />
          </GoogleMap>
        </div>
      );
    }
    return null;
  };

  const handlePointSet = ({ latLng }) => {
    setPoint({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };

  const handleSave = () => {
    onChange(point);
    handleClose();
  };

  const handleClose = () => {
    setOpenPickerModal(false);
  };

  const shouldDisplayError = !!(
    (meta.error || meta.submitError) &&
    meta.touched
  );

  if (isLoaded) {
    return (
      <>
        {renderPreviewMap()}
        {renderButton()}
        <Feedback
          textCenter
          show={shouldDisplayError}
          content={meta.error || meta.submitError}
          invalid
        />
        <Modal
          title={intl.formatMessage(messages.geoSelectorModalTitle)}
          isOpen={openPickerModal}
          onRequestClose={handleClose}
        >
          <GoogleMap
            mapContainerClassName="map-container for-modal"
            clickableIcons={false}
            zoom={DEFAULT_MAP_ZOOM}
            center={center}
            onClick={handlePointSet}
            options={{
              fullscreenControl: false,
              draggableCursor: 'pointer',
            }}
          >
            {point && <Marker position={point} />}
          </GoogleMap>
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-end mt-4">
            <Button onClick={handleClose} outline>
              <FormattedMessage {...messages.cancelButtonText} />
            </Button>
            <Button disabled={!point} onClick={handleSave}>
              <FormattedMessage
                {...messages.saveButtonText}
                values={{ keyword }}
              />
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  if (loadError) {
    return (
      <Feedback
        show
        invalid
        content={intl.formatMessage(messages.googleMapsConnectionErrorMessage)}
      />
    );
  }

  return <Spinner show />;
};

FormGeoField.propTypes = {
  intl: PropTypes.object,
  keyword: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default injectIntl(FormGeoField);
