import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.MyBusinessAccount';

export default defineMessages({
  formEditButtonText: {
    id: `${scope}.formEditButtonText`,
    defaultMessage: 'Edit',
  },
  formSaveChangesButtonText: {
    id: `${scope}.formSaveChangesButtonText`,
    defaultMessage: 'Save changes',
  },
  formCancelButtonText: {
    id: `${scope}.formCancelButtonText`,
    defaultMessage: 'Cancel',
  },
  pageHeading: {
    id: `${scope}.pageHeading`,
    defaultMessage: 'My Business Account',
  },
});
