import { FORM_ERROR } from 'final-form';
import { OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import { formatApiErrors, safeResolveAsync } from '../../../utils';
import * as tokensModule from '../tokens';
import { login } from './api';
import { actions, types } from './duck';

export function* loginSaga({ email, password, resolve }) {
  try {
    yield put(actions.loginRequest({ email, password }));

    const response = yield login(email, password);

    if (response.status === OK) {
      yield put(tokensModule.actions.setTokens(response.data));
      yield put(actions.loginSuccess(response.data));
      yield safeResolveAsync(resolve);
    } else {
      if (response.data.detail) {
        throw Error(response.data.detail);
      }
      const formatedErrors = formatApiErrors(response.data);
      yield put(actions.loginFailure(formatedErrors));
      yield safeResolveAsync(resolve, formatedErrors);
    }
  } catch (error) {
    yield put(actions.loginFailure(error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export function* logoutSaga() {
  yield put(tokensModule.actions.clearTokens());
}

export default function* saga() {
  yield takeLatest(types.LOGIN, loginSaga);
  yield takeLatest(types.LOGOUT, logoutSaga);
}
