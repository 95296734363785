import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage } from 'react-intl';

import { Block } from 'components';
import { ReactComponent as PersonIcon } from 'components/AccountMenu/PersonIcon.svg';

import styles from './MarketerInfo.module.scss';
import messages from './messages';

export const MARKETER_ROLES = {
  BUSINESS: 'business',
  CUSTOMER: 'customer',
  ADMIN: 'admin',
};

const marketerInfoFields = data => {
  switch (data.role) {
    case MARKETER_ROLES.BUSINESS:
      return {
        roleText: messages.businessMarketerRoleText,
        marketerName: data.contact_name,
      };
    case MARKETER_ROLES.CUSTOMER:
      return {
        roleText: messages.customerMarketerRoleText,
        marketerName: `${data.first_name} ${data.last_name}`,
      };
    case MARKETER_ROLES.ADMIN:
      return {
        roleText: messages.adminMarketerRoleText,
        marketerName: data.contact_name,
      };
    default:
      return {};
  }
};

export const MarketerInfo = ({ data }) => {
  const { roleText, marketerName } = marketerInfoFields(data);

  return (
    <Block fixedMinHeight>
      <div className="row">
        <div className="col-md-12">
          <h5 className="mb-0">
            <FormattedMessage {...messages.yourMarketerBlockHeading} />
          </h5>
          <div
            styleName="marketer-info-container"
            className="d-flex flex-wrap align-items-center"
          >
            <PersonIcon
              className="mr-4 mb-2 mb-sm-0"
              styleName="marketer-icon"
            />
            <div className="d-flex flex-column">
              {roleText && (
                <span styleName="marketer-role">
                  <FormattedMessage {...roleText} />
                </span>
              )}
              {marketerName && <b>{marketerName}</b>}
              {data.email && <a href={`mailto:${data.email}`}>{data.email}</a>}
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};

MarketerInfo.defaultProps = {
  data: {},
};

MarketerInfo.propTypes = {
  data: PropTypes.object,
};

export default CSSModule(MarketerInfo, styles);
