import { OK } from 'http-status-codes';
import { takeLatest } from 'redux-saga/effects';
import { fork, put } from 'redux-saga/effects';

import { actions, types } from './duck';
import * as api from './api';

export function* getReferralInfoSaga() {
  try {
    yield put(actions.getReferralInfoRequest());

    const response = yield api.getReferralInfo();

    if (response.status === OK) {
      yield put(actions.getReferralInfoSuccess(response.data));
    } else {
      throw new Error(response.data.detail);
    }
  } catch (error) {
    yield put(actions.getReferralInfoFailure(error.message));
  }
}

export default function* saga() {
  yield fork(getReferralInfoSaga);
  yield takeLatest(types.GET_REFERRAL_INFO, getReferralInfoSaga);
}
