import { defineMessages } from 'react-intl';

export const scope = 'cb.components.CouponPriceDetails';

export default defineMessages({
  blockHeadingText: {
    id: `${scope}.blockHeadingText`,
    defaultMessage: 'Accept or Reject coupon',
  },
  acceptButtonText: {
    id: `${scope}.acceptButtonText`,
    defaultMessage: 'Accept',
  },
  rejectButtonText: {
    id: `${scope}.rejectButtonText`,
    defaultMessage: 'Reject',
  },
  payByPointsText: {
    id: `${scope}.payByPointsText`,
    defaultMessage: 'Points',
  },
  payByDollarsText: {
    id: `${scope}.payByDollarsText`,
    defaultMessage: 'Dollars',
  },
  customersWaitingRequestText: {
    id: `${scope}.customersWaitingRequestText`,
    defaultMessage:
      '<b>{customerName}</b> wants to use the coupon and pay by <b>{paymentType}</b>. Do you accept?',
  },
  customersProcessedRequestText: {
    id: `${scope}.customersProcessedRequestText`,
    defaultMessage:
      '<b>{customerName}</b> used the coupon and paid by <b>{paymentType}</b>.',
  },
  customersRejectedRequestText: {
    id: `${scope}.customersRejectedRequestText`,
    defaultMessage:
      "<b>{customerName}'s</b> request to use the coupon and pay by <b>{paymentType}</b> was rejected.",
  },
  couponRejectedNotificationText: {
    id: `${scope}.couponRejectedNotificationText`,
    defaultMessage: 'Coupon <b>{couponTitle}</b> rejected.',
  },
  couponAcceptedNotificationText: {
    id: `${scope}.couponAcceptedNotificationText`,
    defaultMessage: 'Coupon <b>{couponTitle}</b> accepted.',
  },
  priceLessThanDiscountText: {
    id: `${scope}.priceLessThanDiscountText`,
    defaultMessage: 'Cannot be less than discount',
  },
  priceCannotBeEmptyText: {
    id: `${scope}.priceCannotBeEmptyText`,
    defaultMessage: 'Cannot be empty',
  },
});
