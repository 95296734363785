import { OK } from 'http-status-codes';
import { debounce, put } from 'redux-saga/effects';

import { ACTION_WAIT_DEBOUNCE_TIME_MS } from '../../constants';
import { takeAuth, takeLogout } from '../auth/saga';

import * as api from './api';
import { actions, types } from './duck';

export function* getPointsBalance() {
  try {
    yield put(actions.getPointsBalanceRequest());

    const response = yield api.getPointsBalance();

    if (response.status === OK) {
      yield put(actions.getPointsBalanceSuccess(response.data));
    } else {
      yield put(actions.getPointsBalanceFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getPointsBalanceFailure(error.message));
  }
}

export function* initialize() {
  yield put(actions.getPointsBalance());
}

export function* clear() {
  yield put(actions.reset());
}

export default function* saga() {
  yield debounce(
    ACTION_WAIT_DEBOUNCE_TIME_MS,
    types.GET_POINTS_BALANCE,
    getPointsBalance,
  );
  yield takeAuth(initialize);
  yield takeLogout(clear);
}
