import { defineMessages } from 'react-intl';

export const scope = 'cb.sharedMessages';

export default defineMessages({
  leavePageHintText: {
    id: `${scope}.leavePageHintText`,
    defaultMessage:
      'Are you sure you want to leave the page? Your changes will not be saved',
  },
  membershipPlansPageHeadingText: {
    id: `${scope}.membershipPlansPageHeadingText`,
    defaultMessage: 'Membership Plans',
  },
  dashboardPageHeadingText: {
    id: `${scope}.dashboardPageHeadingText`,
    defaultMessage: 'Dashboard',
  },
  learnMoreLinkText: {
    id: `${scope}.learnMoreLinkText`,
    defaultMessage: 'Learn more',
  },
});
