import { OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import * as api from './api';
import { actions, types } from './duck';

export function* getMembershipPlans() {
  try {
    yield put(actions.getMembershipPlansRequest());

    const response = yield api.getMembershipPlans();

    if (response.status === OK) {
      yield put(actions.getMembershipPlansSuccess(response.data));
    } else {
      yield put(actions.getMembershipPlansFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getMembershipPlansFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_MEMBERSHIP_PLANS, getMembershipPlans);
}
