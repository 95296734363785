import {
  formatToImageArgs,
  formatToJsonArgs,
  formatToPkArgs,
} from '../../utils/api';
import { authApiCall } from '../auth/saga';

export const getInfo = () =>
  authApiCall({
    url: '/users/business-info/',
    method: 'get',
  });

/**
 * Account form data field names that should be
 * formated specifically before sending.
 */
export const FORMATTING_FIELDS = {
  LOCATION: 'location',
  LOGO: 'logo',
  COVER_IMAGE: 'cover_image',
  PROVINCE: 'province',
  CITY: 'city',
  CATEGORIES: 'categories',
};

export const formatToAccountFormData = values => {
  const data = new FormData();
  const {
    LOCATION,
    LOGO,
    COVER_IMAGE,
    PROVINCE,
    CITY,
    CATEGORIES,
  } = FORMATTING_FIELDS;

  for (let key of Object.keys(values)) {
    switch (key) {
      case LOCATION:
        data.append(...formatToJsonArgs(key, values[key]));
        break;
      case LOGO:
      case COVER_IMAGE:
        data.append(...formatToImageArgs(key, values[key]));
        break;
      case PROVINCE:
        /**
         * This needs to skip province,
         * because it's don't nessesary on request
         */
        break;
      case CITY:
        data.append(...formatToPkArgs(key, values[key]));
        break;
      case CATEGORIES:
        for (let category of values[key]) {
          data.append(...formatToPkArgs(key, category));
        }
        break;
      default:
        data.append(key, values[key]);
    }
  }

  return data;
};

export const updateInfo = updatedValues => {
  const data = formatToAccountFormData(updatedValues);

  return authApiCall({
    url: '/users/update-business-info/',
    method: 'patch',
    data,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      Accept: 'application/json',
    },
  });
};
