import React from 'react';

import { normalize, schema } from 'normalizr';
import PropTypes from 'prop-types';

import { Feedback, PillCheckbox, Spinner } from 'components';

const FormPillSelector = ({
  input,
  meta,
  loadingError,
  isLoading,
  options,
  readOnly,
  maxSelected,
}) => {
  const { value, onChange } = input;

  const items = new schema.Entity('items');
  /* We expect @array in value. */
  const normalized = normalize(value || [], [items]).entities;
  const valueIds = normalized.hasOwnProperty(items.key)
    ? Object.keys(normalized[items.key]).map(key => parseInt(key))
    : [];

  const onClick = option => event => {
    event.preventDefault();
    if (readOnly) {
      return;
    }

    if (valueIds.includes(option.id)) {
      onChange(value.filter(valueOption => valueOption.id !== option.id));
    } else if (!maxSelected || value.length < maxSelected) {
      onChange([option, ...value]);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner show />
      ) : (
        options.map(option => (
          <PillCheckbox
            key={option.id}
            text={option.name}
            checked={!!valueIds && valueIds.includes(option.id)}
            onClick={onClick(option)}
            type="checkbox"
          />
        ))
      )}
      <Feedback
        show={
          !!loadingError || ((meta.error || meta.submitError) && meta.touched)
        }
        content={loadingError || meta.error || meta.submitError}
        invalid
      />
    </>
  );
};

FormPillSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  maxSelected: PropTypes.number,
  loadingError: PropTypes.string,
  isLoading: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default FormPillSelector;
