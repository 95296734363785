import { OK } from 'http-status-codes';
import { debounce, put } from 'redux-saga/effects';

import { ACTION_WAIT_DEBOUNCE_TIME_MS } from '../../constants';
import { takeAuth } from '../auth/saga';

import * as api from './api';
import { actions, types } from './duck';

const formatBusinessReferralProgramData = data => ({
  ...data,
  referrals: data.referrals.map(referral => {
    const { email, ...rest } = referral;
    return rest;
  }),
});

export function* getBusinessReferralProgram() {
  try {
    yield put(actions.getBusinessReferralProgramRequest());

    const response = yield api.getBusinessReferralProgram();

    if (response.status === OK) {
      const formattedData = formatBusinessReferralProgramData(response.data);
      yield put(actions.getBusinessReferralProgramSuccess(formattedData));
    } else {
      yield put(
        actions.getBusinessReferralProgramFailure(response.data.detail),
      );
    }
  } catch (error) {
    yield put(actions.getBusinessReferralProgramFailure(error.message));
  }
}

export function* initialize() {
  yield put(actions.getBusinessReferralProgram());
}

export default function* saga() {
  yield debounce(
    ACTION_WAIT_DEBOUNCE_TIME_MS,
    types.GET_BUSINESS_REFERRAL_PROGRAM,
    getBusinessReferralProgram,
  );
  yield takeAuth(initialize);
  // TODO: make resetable and clear on logout.
  // Idea: made ressetable reducers to listen LOGOUT action.
}
